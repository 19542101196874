import React from "react";
import PropTypes from "prop-types";
import classNames from "merge-class-names";

import "./product_table_header.less";

import MultiselectDropdown from "../../../../stingray/frontend/components/ui/multiselect_dropdown";

import FilterIcon from "../../static/icons/components/filter";
import SortIcon from "../../static/icons/components/sort";

import { toNaturalWord } from "../../utility/string";
import { uniq } from "../../utility/helpers";


const renderSortIcon = (header, sortBy) => (
  <SortIcon className={classNames(["sort_icon", header === sortBy && "active"])} />
);

export default function ProductTableHeader({
  activeColumn,
  currentColumn,
  header,
  isItemChecked,
  isSortable,
  onFilterSelect,
  onSortClick,
  sortBy
}) {
  const clearAllColumnFilters = () => {
    onFilterSelect([], header);
  };

  const getFormattedColumn = () => {
    const nonEmptyUniqColumn = uniq(currentColumn).filter(Boolean).sort();
    return nonEmptyUniqColumn.map(field => ({
      label: toNaturalWord(field),
      value: field,
      checked: isItemChecked(field, header)
    }));
  };

  const selectAllColumnFilters = () => {
    const columnValues = getFormattedColumn().map(col => col.value);
    onFilterSelect(columnValues, header);
  };

  const onFormChange = (event) => {
    const form = event.currentTarget;
    const formData = new FormData(form);
    const values = Array.from(formData.values());
    onFilterSelect(values, header);
  };

  return (
    <div className="ProductTableHeader">
      <span className="ProductTableHeader__name">{header}</span>
      <div className="ProductTableHeader__control_buttons">
        <form onChange={onFormChange}>
          <MultiselectDropdown
            disabled={false}
            hideArrow
            items={getFormattedColumn()}
            label={<FilterIcon className={classNames(["filter_icon", activeColumn && "active"])} />}
            name="input_type"
            title={`Filter table by: ${header}`}
            footer={(
              <>
                <button
                  type="button"
                  onClick={selectAllColumnFilters}
                >
                  Select All
                </button>
                <button
                  type="button"
                  onClick={clearAllColumnFilters}
                >
                  Clear All
                </button>
              </>
            )}
          />
        </form>
        {isSortable && (
          <button
            className="sort_table_button"
            type="button"
            title={`Sort table by: ${header}`}
            onClick={onSortClick}
          >
            {renderSortIcon(header, sortBy)}
          </button>
        )}
      </div>
    </div>
  );
}

ProductTableHeader.propTypes = {
  activeColumn: PropTypes.bool,
  currentColumn: PropTypes.array.isRequired,
  header: PropTypes.string.isRequired,
  isItemChecked: PropTypes.func.isRequired,
  isSortable: PropTypes.bool.isRequired,
  onFilterSelect: PropTypes.func.isRequired,
  onSortClick: PropTypes.func.isRequired,
  sortBy: PropTypes.string
};
