import React from "react";
import classNames from "merge-class-names";

import "./catalog.less";

import HeaderWithFilters from "../../containers/catalog/header_with_filters";
import ProductCards from "../../containers/catalog/product_cards";
import Search from "./search";

import { isLocation } from "../../utility/prop-types";
import { useDebouncedEffect } from "../../utility/hooks";
import { GAPageView } from "../../utility/google_analytics";

export default function Catalog({ location }) {
  useDebouncedEffect(() => {
    // wait a second and send a page view (so we don't capture every keydown as a pageview)
    // location is required to send a pageview on each path update
    location && GAPageView("Data Catalog");
  }, [location], 800);

  return (
    <main
      className={classNames(
        "Catalog",
        `Catalog--${(location.pathname === "/products" || location.pathname === "/products/")
          ? "full"
          : "shared"}`
      )}
    >
      <div className="wrapper">
        <h1>Data Catalog</h1>
        <Search />
        <HeaderWithFilters />
        <ProductCards />
      </div>
    </main>
  );
}

Catalog.propTypes = {
  location: isLocation.isRequired
};
