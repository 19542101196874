import React, { useEffect } from "react";
import PropTypes from "prop-types";

import Section from "./cms/cms_section/cms_section";
import Head from "./head";
import Header from "./header/header";
import Footer from "../containers/footer/footer";

import {
  buttonClickGAAction,
  GAPageView,
  GA_CATEGORY,
  GAEvent
} from "../utility/google_analytics";

import { GALink } from "./ga_link";

import "./error_404.less";


export default function Error404({ location: { pathname } }) {
  const action = "view 404";
  const config = {
    action,
    category: GA_CATEGORY.ERROR,
    labelTitle: `404 - (${pathname})`
  };

  useEffect(() => {
    if (pathname) {
      config && GAEvent(config);
      GAPageView("Page Not Found");
    }
  }, [config, pathname]);

  return (
    <>
      <Header />
      <Section col className="Error404">
        <Head title="Page Not Found" />
        <div className="Error404__code">404</div>
        <h1 className="Error404__title">This page could not be found</h1>
        <p className="Error404__content">
          We’re sorry. The page you are looking for is not available.
        </p>
        <GALink
          className="Error404__cta button"
          to="/"
          config={{
            ...config,
            action: buttonClickGAAction(action)
          }}
        >
          Go to Homepage
        </GALink>
      </Section>
      <Footer />
    </>
  );
}

Error404.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired
  }).isRequired
};
