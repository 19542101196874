import PropTypes from "prop-types";

export const isHistory = PropTypes.shape({
  push: PropTypes.func.isRequired,
  replace: PropTypes.func.isRequired
});

export const isLocation = PropTypes.shape({
  hash: PropTypes.string,
  pathname: PropTypes.string.isRequired,
  search: PropTypes.string.isRequired
});

export const isRole = PropTypes.shape({
  description: PropTypes.string.isRequired,
  id: PropTypes.number.isRequired
});

export const isTrialPeriod = PropTypes.shape({
  end_date: PropTypes.string.isRequired,
  expired: PropTypes.bool.isRequired
});

export const isGAConfig = PropTypes.shape({
  action: PropTypes.string,
  category: PropTypes.string,
  labelInfo: PropTypes.string,
  labelTitle: PropTypes.string
});
