import { connect } from "react-redux";

// Actions
import { queryBlackfin } from "../../actions/blackfin";
import { fetchTypes } from "../../actions/types";

// Selectors
import { getProduct } from "../../selectors/products";

// Components
import DownloadDictionary from "../../components/product_details/download_dictionary";

export default connect(
  (store, ownProps) => {
    const { productId } = ownProps;

    return {
      product: getProduct(store, productId),
      user: store.session.data && store.session.data.user
    };
  },
  {
    fetchTypes,
    queryBlackfin
  }
)(DownloadDictionary);
