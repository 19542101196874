import React, { createRef, Component } from "react";
import PropTypes from "prop-types";
import ReCAPTCHA from "react-google-recaptcha";

import "./index.less";

import InstructionsSent from "../instructions_sent";
import { Form, Submit } from "../ui/form";
import ErrorBar from "../ui/error_bar";

import { isLocalhost } from "../../utility/env";

export default class ResendInstructions extends Component {
  static propTypes = {
    errorMessage: PropTypes.string,
    getCaptchaInfo: PropTypes.func.isRequired,
    headerLabel: PropTypes.string,
    instructionsSentMessage: PropTypes.string,
    sendResendRequest: PropTypes.func.isRequired,
    submitLabel: PropTypes.string
  };

  state = {
    errorMessage: null,
    email: null,
    instructionsSent: false,
    isCaptchaSolved: false,
    shouldUseCAPTCHA: null
  };

  get submitDisabled() {
    const { isCaptchaSolved, shouldUseCAPTCHA } = this.state;

    if (shouldUseCAPTCHA === null) {
      // Still fetching the data - form may be incomplete
      return true;
    }

    if (shouldUseCAPTCHA) {
      return !isCaptchaSolved;
    }

    return false;
  }

  componentDidMount() {
    this.updateResendInstructionsPage();
  }

  reCAPTCHA = createRef();

  onSubmit = (form) => {
    const formData = {};
    new FormData(form).forEach((value, key) => {
      formData[key] = value;
    });

    return this.submitForm(formData);
  };

  onCAPTCHAChange = (value) => {
    this.setState({ isCaptchaSolved: Boolean(value) });
  }

  updateResendInstructionsPage() {
    const { getCaptchaInfo } = this.props;

    getCaptchaInfo().then(({ next_submit_requires_captcha }) => {
      this.setState({
        shouldUseCAPTCHA: isLocalhost() ? false : next_submit_requires_captcha
      });
    });
  }

  submitForm = (requestParams) => {
    const { email } = requestParams;
    const { sendResendRequest } = this.props;

    this.setState({ errorMessage: null });

    const captchaResponse = requestParams["g-recaptcha-response"];

    return sendResendRequest(email, captchaResponse)
      .then(() => {
        this.setState({
          instructionsSent: true,
          email
        });
      })
      .catch((error) => {
        const errorMessage = (
          error instanceof Error ? error.message : error
          || this.props.errorMessage
        );
        this.setState({ errorMessage });

        if (this.reCAPTCHA && this.reCAPTCHA.current) {
          this.reCAPTCHA.current.reset();
        }

        this.updateResendInstructionsPage();
      });
  };

  render() {
    const { submitDisabled } = this;
    const {
      errorMessage, instructionsSent, email, shouldUseCAPTCHA
    } = this.state;
    const { instructionsSentMessage, headerLabel, submitLabel } = this.props;

    const resendInstructionsContent = (() => {
      if (instructionsSent) {
        return (
          <InstructionsSent>
            <p className="InstructionsSent__message">
              {instructionsSentMessage}
              <strong>{` ${email}`}</strong>.
            </p>
          </InstructionsSent>
        );
      }
      return (
        <>
          <h1 className="ResendInstructions__headerLabel">{headerLabel}</h1>
          <Form
            leaveConfirmation={false}
            onSubmit={this.onSubmit}
          >
            {errorMessage && (<ErrorBar>{errorMessage}</ErrorBar>)}
            <label htmlFor="user_email">Email</label>
            <input
              autoFocus="autoFocus"
              type="email"
              name="email"
              id="user_email"
              placeholder="Enter your account email address"
              required
            />
            {shouldUseCAPTCHA ? (
            <>
              <label htmlFor="captcha">Security Verification</label>
              <div className="ResendInstructions__captcha">
                <ReCAPTCHA
                  ref={this.reCAPTCHA}
                  lang="en"
                  onChange={this.onCAPTCHAChange}
                  sitekey="6Lcfo1oaAAAAAD8k7crktxEmPR0nOGq75UoX-kP4"
                />
              </div>
            </>
            ) : null}
            <Submit
              className="button cyan"
              disabled={submitDisabled}
            >
              {submitLabel}
            </Submit>
          </Form>
        </>
      );
    })();

    return (
      <div className="ResendInstructions">
        {resendInstructionsContent}
      </div>
    );
  }
}
