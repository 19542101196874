import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import classNames from "merge-class-names";

import { isLocation } from "../../utility/prop-types";

export const isItem = PropTypes.shape({
  className: PropTypes.string,
  href: PropTypes.string,
  items: PropTypes.arrayOf(PropTypes.shape({
    href: PropTypes.string,
    label: PropTypes.string,
    target: PropTypes.string
  })),
  label: PropTypes.string
});

function getIsActive(href, location, items) {
  let isActive = false;
  const [/* (root) */, firstPath] = location.pathname.split("/");

  if (items) {
    items.map(({ href }) => {
      const targetLocation = new URL(href, window.location.origin);
      const [/* (root) */, targetFirstPath] = targetLocation.pathname.split("/");

      const sameOrigin = window.location.origin === targetLocation.origin;
      const sameFirstPath = firstPath === targetFirstPath;

      if (sameOrigin && sameFirstPath) {
        isActive = true;
      }
    });
  } else {
    const targetLocation = new URL(href, window.location.origin);
    const [/* (root) */, targetFirstPath] = targetLocation.pathname.split("/");

    const sameOrigin = window.location.origin === targetLocation.origin;
    const sameFirstPath = firstPath === targetFirstPath;

    if (sameOrigin && sameFirstPath) {
      isActive = true;
    }
  }

  return isActive;
}

export default function MenuItem({
  item: {
    className,
    href,
    items,
    label,
    target,
    ...restItemProps
  }, location
}) {
  const [isOpen, setIsOpen] = useState(false);
  const wrapper = useRef(null);
  const hasItems = Boolean(items && items.length);
  const isActive = getIsActive(href, location, items);

  const closeMenuItem = () => setIsOpen(false);
  const toggleMenuItem = () => setIsOpen(!isOpen);

  useEffect(() => {
    if (!isOpen) {
      return;
    }

    const onOutsideAction = (event) => {
      if (wrapper && wrapper.current && !wrapper.current.contains(event.target)) {
        closeMenuItem();
      }
    };

    document.addEventListener("mouseover", onOutsideAction);
    document.addEventListener("focusin", onOutsideAction);

    () => {
      document.removeEventListener("mouseover", onOutsideAction);
      document.removeEventListener("focusin", onOutsideAction);
    };
  }, [isOpen]);


  return (
    <li
      className={classNames(
        "Menu__list__item",
        isOpen && "Menu__list__item--isOpen",
        className,
        label && label.replace(/ /g, "_").toLowerCase()
      )}
      onClick={hasItems ? toggleMenuItem : null}
      ref={wrapper}
      aria-haspopup={hasItems || null}
      aria-expanded={hasItems ? isOpen : null}
      role="menuitem"
    >
      <a
        {...restItemProps}
        className={classNames(
          isActive && "active",
          items && "header"
        )}
        href={href}
        target={target || "_self"}
      >
        {label}
        {items && (
          <>
            {" "}
            <span className="Menu__list__item__arrow">
              {isOpen ? "▲" : "▼"}
            </span>
          </>
        )}
      </a>
      {items && (
        <ul className="Menu__list">
          {items.map(item => (
            <MenuItem
              key={item.label}
              location={location}
              item={item}
            />
          ))}
        </ul>
      )}
    </li>
  );
}

MenuItem.propTypes = {
  item: isItem.isRequired,
  location: isLocation.isRequired
};
