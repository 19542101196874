import React from "react";
import { Route } from "react-router-dom";

import ProductDataSource from "../containers/catalog/product_data_source";
import Header from "./header/header";
import ProductDetails from "../containers/product_details/product_details";
import Head from "./head";

import "./data_sources.less";

export default function DataSources() {
  return (
    <>
      <Head
        title="Data Catalog"
        description="Explore Demyst's highly secure and compliant library of over 500 data products. We vet each source so that you can confidently utilize external data."
      />
      <Route
        path="/data-sources/:dataSourceName/:id?"
        exact
        component={Header}
      />
      <div className="DataSources">
        <Route
          path="/data-sources/:dataSourceName"
          exact
          component={ProductDataSource}
        />
        <Route
          path="/data-sources/:dataSourceName/:id"
          exact
          component={ProductDataSource}
        />
        <Route
          path="/data-sources/:dataSourceName/:id"
          exact
          component={ProductDetails}
        />
      </div>
    </>
  );
}
