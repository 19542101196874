import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

import { isLocation } from "../../utility/prop-types";

import "./header_with_filters.less";

export default function SimpleHeaderWithNoFilters({ location, numOfResults }) {
  const amount = numOfResults?.toString();
  return (
    <div className="HeaderWithFilters">
      <div className="HeaderWithFilters__header">
        <ul className="HeaderWithFilters__tabs" role="tablist">
          <li role="tab" aria-selected="true">
            Search Results
            {amount
              ? ` (${amount})`
              : ""}
          </li>
          <li role="tab" aria-selected={false}>
            <Link
              to={{
                ...location,
                pathname: "/products",
                search: ""
              }}
            >
              All Products
            </Link>
          </li>
        </ul>
      </div>
    </div>
  );
}

SimpleHeaderWithNoFilters.propTypes = {
  location: isLocation.isRequired,
  numOfResults: PropTypes.number
};
