import React from "react";
import PropTypes from "prop-types";

import "./error_bar.less";

const ErrorBar = ({ children }) => (
  <div className="ErrorBar">
    {children}
  </div>
);

ErrorBar.propTypes = {
  children: PropTypes.node
};

export default ErrorBar;
