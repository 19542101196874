import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

// Actions
import { fetchSession } from "../actions/session";
import { fetchPublicSessionHash } from "../actions/public_session_hash";

// Selectors
import { getCurrentUser, isDemystUser } from "../selectors/session";
import { getSessionHash } from "../selectors/public_session_hash";

// Components
import Root from "../components/root";

export default withRouter(connect(
  (store) => {
    const user = getCurrentUser(store);
    return {
      isDemystUser: isDemystUser(store),
      isFetched: Boolean(store.session.fetchedAt),
      isLoggedIn: Boolean(user),
      sessionHash: getSessionHash(store),
      userId: user?.id?.toString()
    };
  },
  {
    fetchPublicSessionHash,
    fetchSession
  }
)(Root));
