import React, { useMemo } from "react";
import PropTypes from "prop-types";

function list(files) {
  const fileItems = [];
  files.forEach((file) => {
    fileItems.push(
      <li key={`${file.name}-${file.type}`}>
        {`'${file.name}' of size '${file.size}' and type '${file.type}'`}
      </li>
    );
  });
  return fileItems;
}

export default function UploadedFileList({ files }) {
  const fileList = useMemo(() => list(files), [files]);

  if (files?.length === 0) {
    return null;
  }

  return (
    <ul>
      {fileList}
    </ul>
  );
}

UploadedFileList.propTypes = {
  files: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string.isRequired,
    size: PropTypes.number.isRequired,
    type: PropTypes.string.isRequired
  }))
};
