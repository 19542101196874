import makeSimpleReducer from "./_make_simple_reducer";

import {
  GET_TYPES_REQUEST,
  GET_TYPES_SUCCESS,
  GET_TYPES_ERROR
} from "../actions/types";

export default makeSimpleReducer({
  actions: [
    GET_TYPES_REQUEST,
    GET_TYPES_SUCCESS,
    GET_TYPES_ERROR
  ]
});
