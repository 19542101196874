const isNewValue = (previousValue, value) => {
  if (typeof value === "undefined") {
    return false;
  }

  if (typeof previousValue !== typeof value) {
    return true;
  }

  if (typeof previousValue === "object") {
    return JSON.stringify(previousValue) !== JSON.stringify(value);
  }

  return previousValue !== value;
};

const hasNewValue = (previousEntity, entity) => Object.entries(entity)
  .some(([key, value]) => isNewValue(previousEntity[key], value));

export const updateEntity = (state, entity) => {
  const previousEntity = state.entities[entity.id];
  if (previousEntity) {
    const entityHasNewValue = hasNewValue(previousEntity, entity);

    if (entityHasNewValue) {
      return {
        ...previousEntity,
        ...entity
      };
    }

    return previousEntity;
  }

  return entity;
};

export const updateEntities = (state, entitiesList) => {
  const result = { ...state.entities };
  entitiesList.forEach((entity) => {
    result[entity.id] = updateEntity(state, entity);
  });
  return result;
};

export const updateResults = (state, params, statePiece) => {
  const stringifiedParams = JSON.stringify(params);

  const extendedStatePiece = {
    ...state.results[stringifiedParams],
    ...statePiece
  };

  return {
    ...state.results,
    [stringifiedParams]: extendedStatePiece
  };
};

export const extractReadableError = error => ({
  code: error.code,
  message: error.message
});
