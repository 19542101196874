import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

import { isUser } from "../../utility/prop-types";

import logo from "../../static/demyst_logo_short.svg";

import "./header_logo.less";

export default function HeaderLogo({ isFetching, user }) {
  if (isFetching) {
    return null;
  }

  return (
    <div className="HeaderLogo">
      <a href="https://demyst.com">
        <img src={logo} alt="Demyst" />
      </a>
    </div>
  );
}

HeaderLogo.propTypes = {
  isFetching: PropTypes.bool,
  user: isUser,
};
