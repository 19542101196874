import React from "react";

export default function Download({ color = "#FFF", ...restProps }) {
  return (
    <svg width="10" height="12" viewBox="0 0 10 12" {...restProps}>
      <path
        fill={color}
        fillRule="nonzero"
        d="M10 4.235H7.143V0H2.857v4.235H0l5 4.941 5-4.94zM0 10.588V12h10v-1.412H0z"
      />
    </svg>
  );
}
