import React from "react";
import PropTypes from "prop-types";
import { parse } from "query-string";

import "./product_cards.less";

import InfiniteScroll from "./infinite_scroll";
import ProductCard from "../../containers/catalog/product_card";
import ProductCardPlaceholder from "../../placeholders/product_card";

import { isLocation, isProduct } from "../../utility/prop-types";
import { useDebouncedEffect } from "../../utility/hooks";

export default function ProductCards({
  fetchProducts,
  fetchProductsSearch,
  location,
  products
}) {
  useDebouncedEffect(() => {
    if (products) {
      return;
    }

    const { search } = parse(location.search);

    if (search) {
      fetchProductsSearch({ search });
    } else {
      fetchProducts();
    }
  }, [location], 400);

  return (
    <div className="ProductCards">
      <ul>
        {products
          ? (
            <InfiniteScroll>
              {products.map(product => (
                <li key={product.id}>
                  <ProductCard product={product} />
                </li>
              ))}
            </InfiniteScroll>
          )
          : (
            Array.from(new Array(5)).map((element, index) => (
              <li key={index}>
                <ProductCardPlaceholder index={index} />
              </li>
            ))
          )
        }
      </ul>
    </div>
  );
}

ProductCards.propTypes = {
  fetchProducts: PropTypes.func.isRequired,
  fetchProductsSearch: PropTypes.func.isRequired,
  location: isLocation.isRequired,
  products: PropTypes.arrayOf(isProduct)
};
