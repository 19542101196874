import {
  GET_PRODUCTS_REQUEST,
  GET_PRODUCTS_SUCCESS,
  GET_PRODUCTS_ERROR,
  GET_PRODUCT_REQUEST,
  GET_PRODUCT_SUCCESS,
  GET_PRODUCT_ERROR
} from "../actions/products";

import { updateEntities, updateResults } from "../utility/reducers";

const defaultState = {
  entities: {},
  results: {}
};

const createEntityMap = entitiesList => entitiesList.map(entity => entity.id);

export default (state = defaultState, action) => {
  switch (action.type) {
    case GET_PRODUCTS_REQUEST: {
      const { params = {} } = action;

      const stringifiedParams = JSON.stringify(params);

      const statePiece = {
        ...state[stringifiedParams],
        isFetching: true
      };

      return {
        ...state,
        results: updateResults(state, params, statePiece)
      };
    }
    case GET_PRODUCTS_SUCCESS: {
      const { data, params = {} } = action;

      const statePiece = {
        isFetching: false,
        fetchedAt: new Date(),
        error: null,
        data: createEntityMap(data)
      };

      return {
        ...state,
        entities: updateEntities(state, data),
        results: updateResults(state, params, statePiece)
      };
    }
    case GET_PRODUCTS_ERROR: {
      const { error, params = {} } = action;

      const statePiece = {
        isFetching: false,
        error: { message: error.message }
      };

      return {
        ...state,
        results: updateResults(state, params, statePiece)
      };
    }

    case GET_PRODUCT_REQUEST: {
      return state;
    }

    case GET_PRODUCT_SUCCESS: {
      const { data } = action;

      return {
        ...state,
        entities: updateEntities(state, [data])
      };
    }

    case GET_PRODUCT_ERROR: {
      return state;
    }

    default: return state;
  }
};
