import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import "./user_avatar.less";

import { getInitials } from "../../utility/string";

export function UserAvatar({ user, size }) {
  const { avatar, name } = user;
  const initials = getInitials(name);

  let fontSize = size / 2.8;
  if (initials && initials.length > 3) {
    fontSize /= initials.length / 3;
  }

  return (
    <div
      className="UserAvatar"
      style={{
        width: `${size}px`,
        height: `${size}px`,
        fontSize: `${fontSize}px`,
        lineHeight: `${size}px`
      }}
      title={name}
    >
      {/* TODO */}
      {avatar ? (
        <img
          src={`https://placekitten.com/${size}/${size}`}
          alt={initials}
        />
      ) : initials}
    </div>
  );
}

UserAvatar.propTypes = {
  size: PropTypes.number.isRequired,
  user: PropTypes.shape({
    email: PropTypes.string,
    name: PropTypes.string
  }).isRequired
};

UserAvatar.defaultProps = {
  size: 32
};

export default connect(
  (store, ownProps) => ({
    user: ownProps.user || store.session.data.user
  })
)(UserAvatar);
