import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

// Actions
import { logOut } from "../../actions/session";

// Selectors
import { getCurrentUser, isDemystUser } from "../../selectors/session";

// Components
import Menu from "../../components/header/menu";

export default withRouter(connect(
  store => ({
    isDemystUser: isDemystUser(store),
    user: getCurrentUser(store)
  }),
  {
    logOut
  }
)(Menu));
