import React from "react";

import "./index.less";

export default function InstructionsSent({ children }) {
  return (
    <div className="InstructionsSent">
      <h1>Instructions sent!</h1>
      {children}
      <p>
        You will receive this email in a few minutes. Be sure to check your spam folder too.
      </p>
    </div>
  );
}
