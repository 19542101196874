import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

// Actions

// Selectors

// Components
import AuthSection from "../components/auth_section";

export default withRouter(connect(
  store => ({
    isFetched: Boolean(store.session.fetchedAt),
    user: store.session.data && store.session.data.user
  }),
)(AuthSection));
