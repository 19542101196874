import makeSimpleReducer from "./_make_simple_reducer";

import {
  GET_TAGS_REQUEST,
  GET_TAGS_SUCCESS,
  GET_TAGS_ERROR
} from "../actions/tags";

export default makeSimpleReducer({
  actions: [
    GET_TAGS_REQUEST,
    GET_TAGS_SUCCESS,
    GET_TAGS_ERROR
  ]
});
