import { get } from "../helpers/network";

export const GET_ENV_VARIABLES_REQUEST = "GET_ENV_VARIABLES_REQUEST";
export const GET_ENV_VARIABLES_SUCCESS = "GET_ENV_VARIABLES_SUCCESS";
export const GET_ENV_VARIABLES_ERROR = "GET_ENV_VARIABLES_ERROR";

export const fetchEnvVariables = () => async (dispatch, getState) => {
  const store = getState();

  if (store.env_variables.isFetching || store.env_variables.data) {
    // Request for (somewhat) static data - no need for multiple requests
    return store.env_variables.data;
  }

  dispatch({ type: GET_ENV_VARIABLES_REQUEST });

  try {
    const env_variables = await get("/get_env_variables");
    dispatch({
      type: GET_ENV_VARIABLES_SUCCESS,
      data: env_variables
    });
    return env_variables;
  } catch (error) {
    dispatch({
      type: GET_ENV_VARIABLES_ERROR,
      error
    });
    throw error;
  }
};
