import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

// Actions
import { fetchUseCaseModels } from "../../actions/use_cases";

// Selectors
import {
  getUseCaseModels,
  isFetchingUseCaseModels
} from "../../selectors/use_cases";
import { isDemystUserNotOnRDP } from "../../selectors/session";

// Components
import AttachedFiles from "../../components/use_case/attached_files";

// Helpers
import { getIdFromParams } from "../../utility/helpers";

export default withRouter(
  connect(
    (store, ownProps) => {
      const useCaseId = getIdFromParams(ownProps.match.params);

      if (!useCaseId) {
        return {};
      }

      return {
        files: getUseCaseModels(store, useCaseId),
        isDemystUserNotOnRDP: isDemystUserNotOnRDP(store),
        isFetching: isFetchingUseCaseModels(store),
        useCaseId
      };
    },
    {
      fetchUseCaseModels
    }
  )(AttachedFiles)
);
