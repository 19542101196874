import makeSimpleReducer from "./_make_simple_reducer";

import {
  GET_ENV_VARIABLES_REQUEST,
  GET_ENV_VARIABLES_SUCCESS,
  GET_ENV_VARIABLES_ERROR
} from "../actions/env_variables";

export default makeSimpleReducer({
  actions: [
    GET_ENV_VARIABLES_REQUEST,
    GET_ENV_VARIABLES_SUCCESS,
    GET_ENV_VARIABLES_ERROR
  ]
});
