import { get } from "../helpers/network";

export const GET_TYPES_REQUEST = "GET_TYPES_REQUEST";
export const GET_TYPES_SUCCESS = "GET_TYPES_SUCCESS";
export const GET_TYPES_ERROR = "GET_TYPES_ERROR";

export const GET_PUBLIC_TYPES_REQUEST = "GET_PUBLIC_TYPES_REQUEST";
export const GET_PUBLIC_TYPES_SUCCESS = "GET_PUBLIC_TYPES_SUCCESS";
export const GET_PUBLIC_TYPES_ERROR = "GET_PUBLIC_TYPES_ERROR";

export const fetchTypes = () => async (dispatch, getState) => {
  const store = getState();

  if (store.types.isFetching || store.types.data) {
    // Request for (somewhat) static data - no need for multiple requests
    return store.types.data;
  }

  dispatch({ type: GET_TYPES_REQUEST });

  try {
    const types = await get("/types");
    dispatch({
      type: GET_TYPES_SUCCESS,
      data: types
    });
    return types;
  } catch (error) {
    dispatch({
      type: GET_TYPES_ERROR,
      error
    });
    throw error;
  }
};

export const fetchPublicTypes = () => async (dispatch, getState) => {
  const store = getState();

  if (store.public_types.isFetching || store.public_types.data) {
    // Request for (somewhat) static data - no need for multiple requests
    return store.public_types.data;
  }

  dispatch({ type: GET_PUBLIC_TYPES_REQUEST });

  try {
    const types = await get("/public-api/types");
    dispatch({
      type: GET_PUBLIC_TYPES_SUCCESS,
      data: types
    });
    return types;
  } catch (error) {
    dispatch({
      type: GET_PUBLIC_TYPES_ERROR,
      error
    });
    throw error;
  }
};
