import { stringify } from "query-string";

import { get } from "../helpers/network";

export const GET_FILE_TRANSFERS_REQUEST = "GET_FILE_TRANSFERS_REQUEST";
export const GET_FILE_TRANSFERS_SUCCESS = "GET_FILE_TRANSFERS_SUCCESS";
export const GET_FILE_TRANSFERS_ERROR = "GET_FILE_TRANSFERS_ERROR";
export const GET_FILE_TRANSFER_REQUEST = "GET_FILE_TRANSFER_REQUEST";
export const GET_FILE_TRANSFER_SUCCESS = "GET_FILE_TRANSFER_SUCCESS";
export const GET_FILE_TRANSFER_ERROR = "GET_FILE_TRANSFER_ERROR";

export const fetchFileTransfers = () => async (dispatch, getState) => {
  const store = getState();

  if (store.file_transfers.isFetching || store.file_transfers.data) {
    // Request for (somewhat) static data - no need for multiple requests
    return store.file_transfers.data;
  }

  dispatch({ type: GET_FILE_TRANSFERS_REQUEST });

  try {
    const files = await get("/my_files");
    dispatch({
      type: GET_FILE_TRANSFERS_SUCCESS,
      data: files
    });
    return files;
  } catch (error) {
    dispatch({
      type: GET_FILE_TRANSFERS_ERROR,
      error
    });
    throw error;
  }
};

const addFileTransfer = (file, store) => {
  const fileTransfersData = store.file_transfers.data;

  if (fileTransfersData) {
    const copy = [...fileTransfersData];
    const index = copy.findIndex(el => el.id === file.id);
    if (index > -1) {
      // replace entity with current file
      copy[index] = file;
      return copy;
    } else {
      // prepend to array
      // files get added in reverse created order in the controller
      return [file, ...copy];
    }
  }
  return [file];
};

export const fetchFileTransfer = id => async (dispatch, getState) => {
  const store = getState();

  if (store.file_transfers.isFetching || store.file_transfers.data) {
    // Request for (somewhat) static data - no need for multiple requests
    return store.file_transfers.data;
  }

  dispatch({ type: GET_FILE_TRANSFER_REQUEST });

  try {
    const file = await get(`/my_file?id=${id}`);
    const data = addFileTransfer(file, store);

    dispatch({
      type: GET_FILE_TRANSFER_SUCCESS,
      data
    });
    return file;
  } catch (error) {
    dispatch({
      type: GET_FILE_TRANSFER_ERROR,
      error
    });
    throw error;
  }
};

export const registerFileTransferWithManta = params => async (dispatch, getState) => {
  const store = getState();

  dispatch({ type: GET_FILE_TRANSFER_REQUEST });

  try {
    const file = await get(
      `/upload/new_file_uploaded?${stringify(params, {
        arrayFormat: "bracket"
      })}`
    );
    const data = addFileTransfer(file, store);

    dispatch({
      type: GET_FILE_TRANSFER_SUCCESS,
      data
    });
    return file;
  } catch (error) {
    dispatch({
      type: GET_FILE_TRANSFER_ERROR,
      error
    });
    throw error;
  }
};
