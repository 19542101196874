import React from "react";
import PropTypes from "prop-types";

import Container from "../container";

import "./form_element.less";

const FormElement = ({
  children,
  hint,
  label,
  name,
  showValue,
  value
}) => {
  if (!name) {
    throw new Error(`FormElement (${label}) expects 'name' to be given.`);
  }

  return (
    <div className="FormElement">
      <Container>
        <div className="Box__item__label FormElement__label">
          <label htmlFor={name}>{label}</label>
          {(showValue && value || hint) ? <p>{showValue && value || hint}</p> : null}
        </div>
        <div className="FormElement__input">
          {children}
        </div>
      </Container>
    </div>
  );
};

FormElement.propTypes = {
  children: PropTypes.node.isRequired,
  hint: PropTypes.node,
  label: PropTypes.node.isRequired,
  name: PropTypes.string.isRequired,
  showValue: PropTypes.bool,
  value: PropTypes.any
};

export default FormElement;
