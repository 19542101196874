import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

// Actions

// Selectors

// Components
import Users from "../components/users";

export default withRouter(connect(
  store => ({
    user: store.session.data && store.session.data.user
  }),
)(Users));
