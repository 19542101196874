import React from "react";
import PropTypes from "prop-types";
import {
  Link,
  Route,
  Switch,
  Redirect
} from "react-router-dom";
import classNames from "merge-class-names";

import { useCMSAPICollection } from "../../../utility/hooks";

import Section from "../cms_section/cms_section";
import Header from "../../header/header";
import Footer from "../../../containers/footer/footer";
import APIDocsContent from "./api_docs_content";
import ScrollToTop from "../../scrollToTop";

import "./api_docs.less";

export default function APIDocs({
  location: { pathname },
  match: { path, url }
}) {
  const pageData = useCMSAPICollection(["docs_navigation"]);

  if (!pageData) {
    return null;
  }

  const {
    data: {
      docs_navigation: [nav]
    }
  } = pageData;

  const { menu_items } = nav;

  const slugToUrl = slug => slug.replace(/_/g, "-");

  return (
    <div>
      <Header />
      <Section className="APIDocs">
        <aside className="APIDocs__nav">
          {menu_items.map(({ title, child_links }) => (
            <div key={title}>
              <h3>{title}</h3>
              <ul>
                {child_links.map(({ link_label, link_slug }) => (
                  <li key={link_slug}>
                    <Link
                      className={classNames(
                        pathname.includes(slugToUrl(link_slug)) && "active"
                      )}
                      to={`${url}/${slugToUrl(link_slug)}`}
                    >
                      {link_label}
                    </Link>
                  </li>
                ))}
              </ul>
            </div>
          ))}
        </aside>
        <main className="APIDocs__body">
          <Switch>
            <Route path={`${path}/:slug`} component={APIDocsContent} />
            <Redirect
              exact
              from={path}
              to={`${path}/${slugToUrl(
                menu_items[0].child_links[0].link_slug
              )}`}
            />
          </Switch>
          <Route path={`${path}/:slug`} component={ScrollToTop} />
        </main>
      </Section>
      <Footer />
    </div>
  );
}

APIDocs.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired
  }).isRequired,
  match: PropTypes.shape({
    path: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired
  }).isRequired
};
