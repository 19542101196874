import React, { useEffect } from "react";
import PropTypes from "prop-types";

import AttachedFileButton from "./attached_file_button";
import DataProductButton from "./data_product_button";
import NoteEditor from "../../containers/use_case/note_editor";
import StatusTag from "./status_tag";
import TimelineEventPlaceholder from "../../placeholders/timeline/timeline_event";
import Timeline, { TimelineEvent } from "../timeline/timeline";

import { humanizedDate } from "../../utility/date";

import "./use_case_timeline.less";

// Only Notes have an etype
const isNote = etype => etype && etype === "note";

const getChildren = (event, isDemystUserNotOnRDP) => {
  const {
    etype,
    name,
    referent: entity,
    referent_type: type
  } = event;

  if (isNote(etype)) {
    // Notes are S3Object's as well. So, perform this check first
    return (
      <>
        <span>{name}</span>
        {entity && (
          <AttachedFileButton
            file={entity}
            type={etype}
            disabled={isDemystUserNotOnRDP}
          />
        )}
      </>
    );
  }

  switch (type) {
    case "TableProvider":
      return <DataProductButton product={entity} />;
    case "S3Object":
    case "AegeanBatchRun":
    case "AegeanBatchExport":
    case "AegeanBatchInput":
      return (
        <AttachedFileButton
          file={entity}
          type={type}
          disabled={isDemystUserNotOnRDP}
        />
      );
    case "EdaasTag":
      return <StatusTag title={entity.name} />;
    case "User":
      return "";
    default:
      return "";
  }
};

const isLocal = () => window.location.hostname.includes("local");

const formatEvent = (event, isDemystUserNotOnRDP) => {
  const {
    created_at,
    description,
    etype,
    id,
    internal,
    name,
    referent_type,
    user
  } = event;
  const newDate = new Date(created_at);
  const getClassName = () => {
    let className = isNote(etype)
      ? "TimelineEvent--Note"
      : `TimelineEvent--${referent_type}`;
    if (internal) {
      className += "-internal";
    }
    return className;
  };

  return {
    className: getClassName(),
    id: `TimelineEvent-${id || created_at}`,
    date: humanizedDate(newDate),
    time: newDate.toLocaleTimeString("en-US", {
      hour: "numeric",
      minute: "numeric",
      timeZoneName: "short"
    }),
    title: isNote(etype) ? user?.name || "User" : name,
    showAsChat: isNote(etype),
    subtitle: description,
    children: getChildren(event, isDemystUserNotOnRDP)
  };
};

export default function UseCaseTimeline({
  fetchUseCaseEvents,
  isDemystUserNotOnRDP,
  isFetching,
  events,
  useCaseId
}) {
  useEffect(() => {
    useCaseId && fetchUseCaseEvents(useCaseId);
  }, [fetchUseCaseEvents, useCaseId]);

  const content = () => {
    if (isFetching || !events) {
      return Array.from(new Array(5)).map((el, i) => (
        <TimelineEventPlaceholder key={i} />
      ));
    }
    return (
      <>
        {events.map((event, i) => (
          <TimelineEvent
            key={i}
            {...formatEvent(event, isDemystUserNotOnRDP)}
          />
        ))}
      </>
    );
  };

  return (
    <div className="UseCaseTimeline">
      <div className="UseCaseTimeline__content">
        <div className="UseCaseTimeline__header">
          <h3 className="UseCaseTimeline__title">Timeline</h3>
          { isLocal && <div> You are local, make sure you have Cato on to upload / download files </div> }
          {
            isDemystUserNotOnRDP && (
              <small className="UseCaseTimeline__warning">
                <span className="UseCaseTimeline__warning__icon">&#9888;</span> You are not connected to RDP, therefore your
                ability to upload or download content is unavailable
              </small>
            )
          }
        </div>
        <NoteEditor />
        <Timeline>{content()}</Timeline>
      </div>
    </div>
  );
}

UseCaseTimeline.propTypes = {
  events: PropTypes.arrayOf(
    PropTypes.shape({
      created_at: PropTypes.string.isRequired,
      description: PropTypes.string,
      edaas_use_case_id: PropTypes.number.isRequired,
      etype: PropTypes.oneOf([null, "note"]),
      id: PropTypes.number.isRequired,
      internal: PropTypes.bool.isRequired,
      name: PropTypes.string, // Note: if etype == "note", it will not have a name
      referent: PropTypes.object, // AegeanBatchRun, AegeanBatchInput, etc. Note: if etype == "note", it will not have a referent
      referent_id: PropTypes.number, // Note: if etype == "note", it will not have a referent_id
      referent_type: PropTypes.string, // Note: if etype == "note", it will not have a referent_type
      updated_at: PropTypes.string.isRequired,
      user_id: PropTypes.number.isRequired
    }).isRequired
  ),
  fetchUseCaseEvents: PropTypes.func.isRequired,
  isDemystUserNotOnRDP: PropTypes.bool.isRequired,
  isFetching: PropTypes.bool,
  useCaseId: PropTypes.number
};
