import React from "react";
import PropTypes from "prop-types";

import MultiselectFilter from "../../../containers/catalog/filters/multiselect_filter";

import { getSortByPropSorter } from "../../../utility/helpers";
import { isProduct } from "../../../utility/prop-types";
import { useMountEffect } from "../../../utility/hooks";
import { toNaturalSentence } from "../../../utility/string";

export default function DataProviderFilter({
  products,
  fetchProducts
}) {
  useMountEffect(() => {
    fetchProducts();
  });

  const filteredAttrProviders = products && products.map(provider => ({
    name: toNaturalSentence(provider.name),
    value: provider.id.toString()
  }));

  const data = filteredAttrProviders && filteredAttrProviders.sort(getSortByPropSorter("name", true, name => name.toUpperCase()));

  const urlKey = "provider_ids";

  return (
    <MultiselectFilter
      items={data}
      label="Filter by Product"
      name={urlKey}
      urlKey={urlKey}
    />
  );
}

DataProviderFilter.propTypes = {
  fetchProducts: PropTypes.func.isRequired,
  products: PropTypes.arrayOf(isProduct)
};
