export const getCurrentUser = store => store.session?.data?.user;

export const isDemystUser = store => Boolean(store.session?.data?.organization?.is_demyst_org);

export const isDemystAdmin = (store) => {
  const user = store.session?.data?.user;
  return Boolean(isDemystUser(store) && user?.role?.description === "Admin");
};

export const isDemystUserNotOnRDP = (store) => {
  const user = getCurrentUser(store);
  // for local development you can upload and download from s3 as long as you are connected to Cato
  const isLocal = window.location.hostname.includes("local");
  return isDemystUser(store) && !Boolean(user?.is_on_rdp) && !isLocal;
};
