import {
  GET_USE_CASE_MODELS_REQUEST,
  GET_USE_CASE_MODELS_SUCCESS,
  GET_USE_CASE_MODELS_ERROR
} from "../actions/use_cases";

const defaultState = {
  isFetching: false,
  fetchedAt: null,
  error: null,
  data: null
};

export default (state = defaultState, action) => {
  const { params: { id } = {} } = action;

  switch (action.type) {
    case GET_USE_CASE_MODELS_REQUEST: {
      return {
        ...state,
        isFetching: true
      };
    }
    case GET_USE_CASE_MODELS_SUCCESS: {
      const { data } = action;

      return {
        ...state,
        isFetching: false,
        fetchedAt: new Date(),
        error: null,
        data: {
          ...state.data,
          [id]: data
        }
      };
    }
    case GET_USE_CASE_MODELS_ERROR: {
      const { error } = action;

      return {
        ...state,
        isFetching: false,
        error
      };
    }
    default:
      return state;
  }
};
