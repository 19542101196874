import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

// Actions
import { fetchUseCaseEvents } from "../../actions/use_cases";

// Selectors
import {
  getUseCaseEvents,
  isFetchingUseCaseEvents
} from "../../selectors/use_cases";
import { isDemystUserNotOnRDP } from "../../selectors/session";

// Components
import UseCaseTimeline from "../../components/use_case/use_case_timeline";

// Helpers
import { getIdFromParams } from "../../utility/helpers";

export default withRouter(connect(
  (store, ownProps) => {
    const useCaseId = getIdFromParams(ownProps.match.params);

    if (!useCaseId) {
      return {};
    }

    return {
      events: getUseCaseEvents(store, useCaseId),
      isDemystUserNotOnRDP: isDemystUserNotOnRDP(store),
      isFetching: isFetchingUseCaseEvents(store),
      useCaseId
    };
  },
  {
    fetchUseCaseEvents
  }
)(UseCaseTimeline));
