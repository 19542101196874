import React from "react";
import PropTypes from "prop-types";

import MultiselectFilter from "../../../containers/catalog/filters/multiselect_filter";

import { getSortByPropSorter } from "../../../utility/helpers";
import { isDataCategory } from "../../../utility/prop-types";
import { useMountEffect } from "../../../utility/hooks";

export default function DataCategoryFilter({
  dataCategories,
  fetchDataCategories
}) {
  useMountEffect(() => {
    fetchDataCategories();
  });

  const filteredAttrProviders = dataCategories && dataCategories.map(({ name }) => ({
    name,
    value: name
  }));

  const data = filteredAttrProviders && filteredAttrProviders.sort(getSortByPropSorter("name", true, name => name.toUpperCase()));

  const urlKey = "data_categories";

  return (
    <MultiselectFilter
      items={data}
      label="Filter by Entity"
      name={urlKey}
      urlKey={urlKey}
    />
  );
}

DataCategoryFilter.propTypes = {
  dataCategories: PropTypes.arrayOf(isDataCategory),
  fetchDataCategories: PropTypes.func.isRequired
};
