import React, { createRef, Component } from "react";
import PropTypes from "prop-types";

import "./product_details.less";

import DataTypes from "./data_types";
import Description from "./description";
import DescriptionPlaceholder from "../../placeholders/product_details/description";
import GetData from "./get_data";
import Head from "../head";
import ProductDetailsHeader from "../../containers/product_details/product_details_header";
import ProductDetailsHeaderPlaceholder from "../../placeholders/product_details/product_details_header";
/* Release 2 development
import Solutions from "./solutions";
*/

import { isLocation, isProduct, isHistory } from "../../utility/prop-types";
import { getProductAliasOrName, getProductUrlId } from "../../utility/helpers";
import { KEYBOARD_KEYS } from "../../utility/constants";
import ProductDetailsAnonymous from "./product_details_anonymous";

export default class ProductDetails extends Component {
  static propTypes = {
    fetchProduct: PropTypes.func.isRequired,
    history: isHistory.isRequired,
    isDemystUser: PropTypes.bool.isRequired,
    isLoggedIn: PropTypes.bool.isRequired,
    location: isLocation.isRequired,
    product: isProduct,
    productId: PropTypes.number.isRequired
  };

  componentDidMount() {
    this.fetchProduct();

    document.addEventListener("keydown", this.handleKeyDown);
  }

  componentDidUpdate(prevProps) {
    const { product, productId } = this.props;

    if (product !== prevProps.product) {
      this.ensureUrl();
    }

    if (productId !== prevProps.productId) {
      if (this.wrapper && this.wrapper.current) {
        this.wrapper.current.scrollTop = 0;
      }
      this.fetchProduct();
    }
  }

  componentWillUnmount() {
    document.removeEventListener("keydown", this.handleKeyDown);
  }

  wrapper = createRef();

  isPathnameComingFromDataSources() {
    const { location } = this.props;

    return /\/data-sources/i.test(location.pathname);
  }

  dataSourcePath() {
    const { product } = this.props;

    if (!product) {
      return "";
    }

    const {
      data_source: { name: dataSourceName }
    } = product;

    return `/data-sources/${dataSourceName.toLowerCase()}`;
  }

  ensureUrl() {
    const { history, product, location } = this.props;

    if (!product) {
      return;
    }

    let expectedPathname = `/products/${getProductUrlId(product)}`;

    if (this.isPathnameComingFromDataSources()) {
      // Allow to override pathname when coming from pages other than the catalog
      // Ex url: /data-sources/McK/1
      expectedPathname = `${this.dataSourcePath()}/${getProductUrlId(product)}`;
    }

    if (location.pathname !== expectedPathname) {
      history.replace({
        ...location,
        pathname: expectedPathname
      });
    }
  }

  fetchProduct() {
    const { fetchProduct, productId } = this.props;

    fetchProduct(productId);
  }

  handleKeyDown = (event) => {
    const { history, product, location } = this.props;

    let pathname = "/products";

    if (product && this.isPathnameComingFromDataSources()) {
      pathname = this.dataSourcePath();
    }

    if (event.key === KEYBOARD_KEYS.ESCAPE) {
      history.push({
        ...location,
        pathname
      });
    }
  }

  renderHeader() {
    const {
      product,
      productId
    } = this.props;

    if (!product) {
      return (
        <ProductDetailsHeaderPlaceholder />
      );
    }

    return (
      <ProductDetailsHeader
        onCloseLink={
          this.isPathnameComingFromDataSources() && this.dataSourcePath()
        }
        product={product}
        productId={productId}
      />
    );
  }

  renderProductContent() {
    const { isDemystUser, product } = this.props;

    if (!product) {
      return (
        <>
          <DescriptionPlaceholder />
        </>
      );
    }

    return (
      <>
        <Description product={product} />
        <GetData isDemystUser={isDemystUser} product={product} />
        <DataTypes product={product} />
        {/* Release 2 development
        <Solutions />
        */}
      </>
    );
  }

  renderLoggedInContent() {
    return (
      <>
        {this.renderHeader()}
        <div className="ProductDetails__content">
          {this.renderProductContent()}
        </div>
      </>
    );
  }

  renderAnonymousContent() {
    const { history, location, product } = this.props;

    return (
      <ProductDetailsAnonymous
        onCloseLink={
          this.isPathnameComingFromDataSources() && this.dataSourcePath()
        }
        history={history}
        location={location}
        product={product}
      />
    );
  }

  renderContent() {
    const { isLoggedIn } = this.props;
    if (isLoggedIn) {
      return this.renderLoggedInContent();
    }
    return this.renderAnonymousContent();
  }

  renderMeta() {
    const { product } = this.props;

    if (!product) {
      return null;
    }

    const productNaturalName = getProductAliasOrName(product);
    const canonicalUrl = `https://demyst.com/products/${getProductUrlId(product)}`;
    const title = `${productNaturalName} | Data Catalog`;

    return (
      <Head canonicalUrl={canonicalUrl} description={product?.description} title={title} />
    );
  }

  render() {
    return (
      <aside
        className="ProductDetails"
        ref={this.wrapper}
      >
        {this.renderContent()}
        {this.renderMeta()}
      </aside>
    );
  }
}
