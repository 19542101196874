import { connect } from "react-redux";

// Actions
import { fetchSampleData } from "../../actions/sample_data";

// Selectors
import { getProduct } from "../../selectors/products";

// Components
import DownloadSampleData from "../../components/product_details/download_sample_data";

export default connect(
  (store, ownProps) => {
    const { productId } = ownProps;
    const sampleData = store.sample_data[productId];

    return {
      isFetching: sampleData && sampleData.isFetching,
      product: getProduct(store, productId),
      sampleData: sampleData && sampleData.data,
      user: store.session.data && store.session.data.user
    };
  },
  {
    fetchSampleData
  }
)(DownloadSampleData);
