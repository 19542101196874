import { createSelector } from "reselect";

const getEntities = store => store.attributes.entities;
const getResults = store => store.attributes.results;

export const getPaginationInfo = (store) => {
  const {
    current_page,
    per_page,
    total_entries,
    total_pages
  } = store.attributes;

  return {
    current_page,
    per_page,
    total_entries,
    total_pages
  };
};

export const getAttributes = createSelector(
  getEntities,
  getResults,
  (store, params) => JSON.stringify(params),
  (entities, results, stringifiedParams) => (
    results[stringifiedParams]
    && results[stringifiedParams].data
    && results[stringifiedParams].data.map(attributeId => entities[attributeId])
  )
);

export const getAttributeCount = createSelector(
  getAttributes,
  attributes => attributes?.length || 0
);
