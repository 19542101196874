import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";

import Cell from "./cell";
import Search from "../search";

import { getSearchQuery, matchValue } from "../../../utility/table";

export class SimpleTable extends PureComponent {
  static propTypes = {
    /**
     * Data shape supported:
     *
     * {
     *   Label: "Value",
     *   "Another label": ["Another value 1", "Another value 2"]
     * }
     */
    data: PropTypes.shape({}),
    location: PropTypes.shape({
      search: PropTypes.string
    }).isRequired,
    search: PropTypes.string, // If given, will be used instead of search query from URL (see docs for withSearch).
    searchPlaceholder: PropTypes.string.isRequired,
    /**
     * Given `true`: Will look for "search" param in the URL and use it as a query for row filtering and highlighting.
     * Given string: Will look for a given param in the URL and use it as a query for row filtering and highlighting.
     * Given falsy value (default): Search will be disabled in this table.
     * Note: Search will not work for non-stringifiable values, like React nodes.
     */
    withSearch: PropTypes.oneOfType([
      PropTypes.bool,
      PropTypes.string
    ])
  };

  static defaultProps = {
    searchPlaceholder: "Search…"
  };

  get search() {
    const { location, withSearch, search } = this.props;

    return getSearchQuery({
      location,
      withSearch,
      search
    });
  }

  renderCell = (value, cellIndex) => (
    <Cell
      key={cellIndex}
      search={this.search}
      value={value}
    />
  );

  renderRow = ([label, value], rowIndex) => {
    const { search } = this;

    if (search) {
      const hasMatches = matchValue(value, search);

      // Don't display a row if there's no matching value inside
      if (!hasMatches) {
        return null;
      }
    }

    return (
      <tr key={rowIndex}>
        <th scope="row">
          {label}:
        </th>
        {this.renderCell(value, 1)}
      </tr>
    );
  };

  renderRows = () => {
    const { data } = this.props;
    const rows = Object.entries(data);

    if (!rows.length) {
      return null;
    }

    const renderedRows = rows
      .map(this.renderRow)
      .filter(Boolean);

    return renderedRows;
  };

  render() {
    const { data, searchPlaceholder, withSearch } = this.props;

    if (!data || !Object.keys(data).length) {
      return null;
    }

    const rows = this.renderRows();

    return (
      <>
        {withSearch && (
          <Search placeholder={searchPlaceholder} />
        )}
        {rows && (
          <table>
            <tbody>
              {rows}
            </tbody>
          </table>
        )}
      </>
    );
  }
}

export default withRouter(SimpleTable);
