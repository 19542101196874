import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

import "./auth_section.less";

import { GA_LABEL_TITLE } from "../utility/google_analytics";

import { GALink } from "./ga_link";

import { getRootPathName } from "../utility/helpers";
import { isLocation, isUser } from "../utility/prop-types";

export default function AuthSection({
  children,
  isFetched,
  location,
  title,
  user,
}) {
  if (!isFetched) {
    // Until session data is not fetched, don't render any page and don't redirect the user anywhere
    return null;
  }

  const isLoggedIn = Boolean(user);

  if (!isLoggedIn) {
    return (
      <div className="AuthSection">
        <GALink
          className="button cyan"
          to={{
            pathname: "/request-data",
            state: {
              locationAfterLogin: location.pathname,
            },
          }}
          config={{
            labelTitle: GA_LABEL_TITLE.REQUEST_DATA,
            labelInfo: `(from ${getRootPathName(location.pathname)} page - ${
              title || "auth section"
            })`,
          }}
        >
          Request Data
        </GALink>{" "}
        or{" "}
        <Link
          to={{
            pathname: "/users/sign_in",
            state: {
              locationAfterLogin: location.pathname,
            },
          }}
        >
          Log in
        </Link>{" "}
        to access
      </div>
    );
  }

  return children;
}

AuthSection.propTypes = {
  children: PropTypes.node,
  isFetched: PropTypes.bool.isRequired,
  location: isLocation.isRequired,
  title: PropTypes.string,
  user: isUser,
};
