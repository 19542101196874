import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

// Components
import Header from "../../components/catalog/product_header";

// Helpers
import { generateProductPlaceholder } from "../../utility/placeholders";

const product = generateProductPlaceholder();

export default withRouter(connect(
  () => ({
    isPlaceholder: true,
    product,
    productId: product.id
  })
)(Header));
