import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

// Actions
import { fetchDataSourceByName } from "../../actions/data_sources";

// Selectors
import { getDataSourceByName } from "../../selectors/data_sources";

// Components
import ProductDataSource from "../../components/catalog/product_data_source";

export default withRouter(
  connect(
    (store, ownProps) => {
      const {
        match: { params: { dataSourceName } = { dataSourceName: "" } }
      } = ownProps;

      if (!dataSourceName) {
        return {};
      }

      return {
        dataSource: getDataSourceByName(store, dataSourceName),
        dataSourceName
      };
    },
    {
      fetchDataSourceByName
    }
  )(ProductDataSource)
);
