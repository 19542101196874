import React from "react";
import PropTypes from "prop-types";

import Dropdown from "../../../../stingray/frontend/components/ui/dropdown";

import { FILTER_TYPES, SORT_DIRECTIONS, SORT_PARAMS } from "../../utility/constants";
import { isHistory, isLocation, isPagination } from "../../utility/prop-types";
import { addToQuery } from "../../../../stingray/frontend/utility/url";

import "./header_with_sort.less";

const formatNumber = num => Number(num).toLocaleString();

const getLocation = (location, sortBy, sortDirection) => {
  const { SORT, SORT_DIRECTION } = FILTER_TYPES;
  let newQuery = addToQuery(location.search, SORT, sortBy);
  newQuery = addToQuery(newQuery, SORT_DIRECTION, sortDirection);

  return {
    ...location,
    search: newQuery
  };
};

const replaceFilters = (history, location, sortBy, sortDirection) => {
  history.replace(getLocation(location, sortBy, sortDirection));
};

const { ASC, DESC } = SORT_DIRECTIONS;
const {
  FILL_RATE,
  MATCH_RATE,
  NAME,
  PRICE
} = SORT_PARAMS;

export default function HeaderWithSort({
  attributeCount,
  history,
  location,
  paginationInfo
}) {
  const getReplaceFilters = (sortBy, sortDirection) => () => replaceFilters(history, location, sortBy, sortDirection);
  const totalEntries = paginationInfo?.total_entries || 0;

  return (
    <div className="HeaderWithSort">
      <div className="HeaderWithSort__meta">
        Displaying <span>{formatNumber(attributeCount)}</span> of {formatNumber(totalEntries)} results
      </div>
      <Dropdown
        label="Sort by"
        items={[
          <button
            type="button"
            onClick={getReplaceFilters(NAME, ASC)}
          >
            Ascending (A-Z)
          </button>,
          <button
            type="button"
            onClick={getReplaceFilters(NAME, DESC)}
          >
            Descending (Z-A)
          </button>,
          <button
            type="button"
            onClick={getReplaceFilters(PRICE, DESC)}
          >
            Price: Highest
          </button>,
          <button
            type="button"
            onClick={getReplaceFilters(PRICE, ASC)}
          >
            Price: Lowest
          </button>,
          <button
            type="button"
            onClick={getReplaceFilters(MATCH_RATE, DESC)}
          >
            Match Rate: Highest
          </button>,
          <button
            type="button"
            onClick={getReplaceFilters(MATCH_RATE, ASC)}
          >
            Match Rate: Lowest
          </button>,
          <button
            type="button"
            onClick={getReplaceFilters(FILL_RATE, DESC)}
          >
            Fill Rate: Highest
          </button>,
          <button
            type="button"
            onClick={getReplaceFilters(FILL_RATE, ASC)}
          >
            Fill Rate: Lowest
          </button>
        ]}
      />
    </div>
  );
}

HeaderWithSort.propTypes = {
  attributeCount: PropTypes.number.isRequired,
  history: isHistory.isRequired,
  location: isLocation.isRequired,
  paginationInfo: isPagination.isRequired
};
