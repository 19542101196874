import React, { useEffect } from "react";
import PropTypes from "prop-types";

import DataServiceJobButton from "./data_service_job_button";
import DataServiceJobButtonPlaceholder from "../../placeholders/use_case/data_service_job_button";
import UseCaseSection from "./use_case_section";

import { isDataServiceJob } from "../../utility/prop-types";

export default function DataServiceJobs({
  fetchUseCaseDataServiceJobs,
  isFetching,
  jobs,
  useCaseId
}) {
  useEffect(() => {
    useCaseId && fetchUseCaseDataServiceJobs(useCaseId);
  }, [fetchUseCaseDataServiceJobs, useCaseId]);

  if ((!isFetching && !jobs) || jobs?.length === 0) {
    return null;
  }

  const content = () => {
    if (isFetching) {
      return Array.from(new Array(5)).map((el, i) => (
        <DataServiceJobButtonPlaceholder key={i} />
      ));
    }
    return (
      <>
        {jobs.map((job, i) => (
          <DataServiceJobButton key={i} job={job} />
        ))}
      </>
    );
  };

  return (
    <UseCaseSection title="Reports">
      <div className="DataServiceJobs">{content()}</div>
    </UseCaseSection>
  );
}

DataServiceJobs.propTypes = {
  fetchUseCaseDataServiceJobs: PropTypes.func.isRequired,
  isFetching: PropTypes.bool.isRequired,
  jobs: PropTypes.arrayOf(isDataServiceJob),
  useCaseId: PropTypes.number
};
