import React from "react";
import PropTypes from "prop-types";

import "./status_tag.less";

export default function StatusTag({ title }) {
  return (
    <div className="StatusTag">{title}</div>
  );
}

StatusTag.propTypes = {
  title: PropTypes.string.isRequired
};
