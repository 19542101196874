import { withRouter } from "react-router-dom";

// Actions

// Selectors

// Components
import ProductDetailsHeader from "../../components/product_details/product_details_header";

export default withRouter(ProductDetailsHeader);
