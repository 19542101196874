import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import classNames from "merge-class-names";
import { parse } from "query-string";

import "./product_card.less";

import Placeholder from "../../placeholders/placeholder";
import Pill from "./pill";
import ProviderLogo from "../ui/provider_logo";

import { toLocaleDateString } from "../../utility/date";
import { isLocation, isProduct } from "../../utility/prop-types";
import { getProductAliasOrName, getProductUrlId } from "../../utility/helpers";

import { highlightPattern, toFuzzyRegExp } from "../../../../stingray/frontend/utility/search";

const isDefined = variable => typeof variable !== "undefined" && variable !== null;

export default function ProductCard({
  activeProductId,
  customLink,
  isPlaceholder,
  location,
  product
}) {
  const isActive = product.id === activeProductId;
  const isInactive = activeProductId && activeProductId != product.id;
  const { search } = parse(location?.search);

  const getHighlightedText = text => search ? highlightPattern(text, toFuzzyRegExp(search)) : text;

  const card = (
    <div
      className={
        classNames("ProductCard",
          isActive && "ProductCard--active",
          isInactive && "ProductCard--inactive")}
    >
      <Placeholder isEnabled={isPlaceholder} theme="light">
        <div className="ProductCard__header">
          {product.logo && (
            <div className="ProductCard__logo">
              <ProviderLogo product={product} />
            </div>
          )}
          <div className="ProductCard__header__content">
            <h2>{getHighlightedText(getProductAliasOrName(product))}</h2>
            {product.data_source && (
              <p>{getHighlightedText(product.data_source.name)}</p>
            )}
          </div>
        </div>
        <div className="ProductCard__content">
          <p className="ProductCard__content__description">
            {getHighlightedText(product.description)}
          </p>
        </div>
        <div className="ProductCard__footer">
          {product.data_category && (
            <Pill label="Category">
              {getHighlightedText(product.data_category.name)}
            </Pill>
          )}
          {product.created_at && (
            <Pill label="Released">
              {toLocaleDateString(product.created_at)}
            </Pill>
          )}
          {isDefined(product.match_rate) && (
            <Pill label="Match Rate">{product.match_rate}%</Pill>
          )}
          {isDefined(product.error_rate) && (
            <Pill label="Error Rate">{product.error_rate}%</Pill>
          )}
        </div>
      </Placeholder>
    </div>
  );

  if (isPlaceholder) {
    return card;
  }

  return (
    <Link
      to={{
        ...location,
        pathname: customLink || `/products/${getProductUrlId(product)}`
      }}
    >
      {card}
    </Link>
  );
}

ProductCard.propTypes = {
  activeProductId: PropTypes.number,
  customLink: PropTypes.string,
  isPlaceholder: PropTypes.bool,
  location: isLocation,
  product: isProduct.isRequired
};
