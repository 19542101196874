@import (reference) "../../styles/colors.less";

.ErrorBar {
  background-color: @red;
  margin: 1em -36px 2em;
  padding: 20px;
  color: white;
  text-align: center;
  font-weight: bold;
}
