import React from "react";
import PropTypes from "prop-types";

import Header from "../../header/header";
import Footer from "../../../containers/footer/footer";
import Section from "../cms_section/cms_section";
import SafeContent from "../../safe_content";

import "./static_page.less";

export default function StaticPage({ body, children, title }) {
  return (
    <div>
      <Header />
      <div className="StaticPage">
        <Section className="StaticPage__hero">
          <h1 className="StaticPage__hero__title">{title}</h1>
        </Section>
        <Section className="StaticPage__body">
          <SafeContent>{body}</SafeContent>
        </Section>
        {children}
      </div>
      <Footer />
    </div>
  );
}

StaticPage.propTypes = {
  body: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  title: PropTypes.string.isRequired
};
