import { createSelector } from "reselect";

const getEntities = store => store.data_sources.entities;
const getResults = store => store.data_sources.results;

export const getDataSource = createSelector(
  getEntities,
  (store, dataSourceId) => dataSourceId,
  (entities, dataSourceId) => entities[dataSourceId]
);

export const getDataSourceByName = (store, name) => {
  const dataSources = Object.values(getEntities(store));
  return dataSources.find(dataSource => dataSource.name.toLowerCase() === name.toLowerCase());
};

export const getDataSources = createSelector(
  getEntities,
  getResults,
  (store, params) => JSON.stringify(params),
  (entities, results, stringifiedParams) =>
    results[stringifiedParams]?.data?.map(dataSourceId => entities[dataSourceId])
);
