import React, { Component } from "react";
import PropTypes from "prop-types";

import "./download_dictionary.less";

import AuthSection from "../../containers/auth_section";

import DownloadIcon from "../../../../stingray/frontend/assets/svgs/download";

import { getProductColumns, getProductsExamplesPayload } from "../../utility/data_dictionary";
import { createCSV, createBlob, getBlobUrl } from "../../utility/csv";
import { isProduct, isUser } from "../../utility/prop-types";
import { downloadFile } from "../../utility/helpers";

export default class DownloadDictionary extends Component {
  static propTypes = {
    fetchTypes: PropTypes.func.isRequired,
    product: isProduct,
    productId: PropTypes.number.isRequired, // eslint-disable-line react/no-unused-prop-types
    queryBlackfin: PropTypes.func.isRequired,
    user: isUser
  };

  get canDownloadDataDictionary() {
    const { product, user } = this.props;

    return Boolean(
      user && user.permissions && user.permissions.can_download_data_dictionary
      && product
      && product.latest_version
      && product.latest_version.schema
      && product.latest_version.schema[0]
      && product.latest_version.schema[0].output
    );
  }

  getExamples = async () => {
    const { fetchTypes, product, queryBlackfin } = this.props;

    try {
      const types = await fetchTypes();
      const payload = getProductsExamplesPayload([product], types);

      const response = await queryBlackfin(payload);

      return response.output;
    } catch (error) {
      return {};
    }
  }

  getDataDictionary = async () => {
    const { product } = this.props;

    const examples = await this.getExamples();
    return getProductColumns(product, examples);
  };

  getCSVUrl = async () => {
    const dataDictionary = await this.getDataDictionary();
    const CSV = createCSV(dataDictionary);
    const CSVBlob = createBlob(CSV);
    const CSVUrl = getBlobUrl(CSVBlob);
    return CSVUrl;
  };

  downloadDictionary = async () => {
    const { product } = this.props;
    const CSVUrl = await this.getCSVUrl();

    if (!CSVUrl) {
      return;
    }

    downloadFile(CSVUrl, `${product.name}_data_dictionary.csv`);
  };

  render() {
    return (
      <AuthSection title="data dictionary">
        <button
          type="button"
          className="DownloadDictionaryBtn link-button-inline"
          onClick={this.downloadDictionary}
          disabled={!this.canDownloadDataDictionary}
        >
          <DownloadIcon color="#00acc1" />
          <span>Download Dictionary</span>
        </button>
      </AuthSection>
    );
  }
}
