import store from "../store";
import { SESSION_EXPIRED } from "../actions/session";
import { isFile } from "../utility/helpers";

const CREDS = { credentials: "include" };

const MAIN_HEADERS = {
  Accept: "application/json",
  "Content-Type": "application/json",
  "Cache-Control": "no-cache",
};

/**
 * This line adds origin, but only if the path is relative. Look at examples!
 *
 * > new URL("/page", "http://example.com").href
 * < "http://example.com/page"
 * > new URL("http://demyst.com", "http://example.com").href
 * < "http://demyst.com/"
 */

export const isDev = () => process.env.NODE_ENV === "development";

export const getEnvUrl = () => {
  const requestOrigin = window.location.hostname;
  let finalDestination = null;

  switch (requestOrigin) {
    case "platform-sbox.demyst.com":
      finalDestination = "https://console-dev.demystdata.com";
      break;
    case "platform-stg.demyst.com":
      finalDestination = "https://console-stg.demystdata.com";
      break;
    case "platform.demyst.com":
      finalDestination = "https://console.demystdata.com";
      break;
    default:
      finalDestination = "https://manta.local.mt.d.demystdata.com:8443";
  }
  return finalDestination;
};

export const prepareUrl = (url) => new URL(url, getEnvUrl());

const prepareOptions = (options) => {
  const preparedOptions = {
    ...CREDS,
    headers: {
      ...MAIN_HEADERS,
    },
    ...options,
  };

  if (preparedOptions.body) {
    if (!isFile(preparedOptions.body)) {
      // Only stringify if body is not a file.
      // If it is a file, stringifying it, ends up returning an empty {}
      preparedOptions.body = JSON.stringify(preparedOptions.body);
    }
  }

  return preparedOptions;
};

const request = async (url, options, includeResponseHeaders) => {
  const urlForRequest = prepareUrl(url);
  const optionsForRequest = prepareOptions(options);
  const response = await fetch(urlForRequest, optionsForRequest);
  const body = await (async () => {
    // 204 No Content
    if (response.status === 204) {
      return null;
    }

    // We have an edge case where 200 acts as 204
    let clonedRes = null;
    const resClone = response.clone();
    try {
      clonedRes = await response.json();
    } catch (error) {
      clonedRes = await resClone.text();
    }
    return clonedRes;
  })();

  function throwErrorWithCode(error) {
    const err = new Error(error);
    err.code = response.status;
    console.log("new error: ", err, err.code, response.status);
    throw err;
  }

  if (body) {
    if (body.error) {
      throwErrorWithCode(body.error);
    }
    if (body.errors) {
      // There are times were we get an object of errors instead of a single one.
      // { email: ["has already been taken", "is not unique"], name: ["is ugly", "is too long"] }
      // => 'email: has already been taken, email: is not unique, name: is ugly, name: is too long'
      const newError = Object.entries(body.errors)
        .reduce((memo, err) => {
          const [key, values] = err;
          const errorMessages = values.reduce(
            (acc, msg) => [...acc, `${key}: ${msg}`],
            []
          );
          return [...memo, ...errorMessages];
        }, [])
        .join(", ");

      throwErrorWithCode(newError);
    }
  }
  if (body && body.error) {
    throwErrorWithCode(body.error);
  }

  if (!response.ok) {
    // 401 Unauthorized
    console.log("RESPONSE IS NOT OK!", response.status);
    if (response.status === 401) {
      store.dispatch({
        type: SESSION_EXPIRED,
      });
    }
    throwErrorWithCode(body);
  }

  if (includeResponseHeaders) {
    const parsedHeaders = [...response.headers].reduce(
      (acc, [key, val]) => ({
        ...acc,
        [key]: val,
      }),
      {}
    );

    return {
      headers: parsedHeaders,
      data: body,
    };
  }

  return body;
};

export const get = (url, options, includeResponseHeaders) =>
  request(
    url,
    {
      ...options,
      method: "GET",
    },
    includeResponseHeaders
  );

export const post = (url, options, includeResponseHeaders) =>
  request(
    url,
    {
      ...options,
      method: "POST",
    },
    includeResponseHeaders
  );

export const put = (url, options, includeResponseHeaders) =>
  request(
    url,
    {
      ...options,
      method: "PUT",
    },
    includeResponseHeaders
  );

export const del = (url, options, includeResponseHeaders) =>
  request(
    url,
    {
      ...options,
      method: "DELETE",
    },
    includeResponseHeaders
  );
