import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

import ProductDetailsSection from "./product_details_section";

import { getProductAliasOrName, getProductUrlId } from "../../utility/helpers";
import { isProduct } from "../../utility/prop-types";

import AuthSection from "../../containers/auth_section";
import DataDictionaryIcon from "../../static/icons/data-dictionary.svg";
import DownloadDictionary from "../../containers/product_details/download_dictionary";
import DownloadSampleData from "../../containers/product_details/download_sample_data";
import PythonApiIcon from "../../static/icons/python-api.svg";
import RunSingleLookUp from "../../containers/product_details/run_single_look_up";
import SampleDataIcon from "../../static/icons/sample-data.svg";
import SingleLookupIcon from "../../static/icons/single-lookup.svg";
import ViewSampleData from "../../containers/product_details/view_sample_data";

export default function GetData({ isDemystUser, product }) {
  return (
    <ProductDetailsSection
      id="get-data"
      header="Get Data"
      display="rows"
      articles={[
        {
          img: <img src={DataDictionaryIcon} alt="Data Dictionary" />,
          header: "Data Dictionary",
          text: (
            <p>
              Understand data products better with detailed descriptions,
              examples, and benchmarking analytics by DemystData included where
              available.
            </p>
          ),
          actions: (
            <AuthSection title="data dictionary">
              <Link
                className="button mdb"
                to={`/products/${getProductUrlId(product)}/data_dictionary`}
              >
                View Data Dictionary
              </Link>
              <DownloadDictionary productId={product.id} />
            </AuthSection>
          ),
        },
        {
          img: <img src={SampleDataIcon} alt="Sample Data" />,
          header: "Sample Data",
          text: (
            <p>
              Review and download real sample data to get an accurate
              understanding of the values you can expect from{" "}
              {getProductAliasOrName(product)}.
            </p>
          ),
          actions: (
            <>
              <ViewSampleData className="button mdb" productId={product.id} />
              <DownloadSampleData productId={product.id} />
            </>
          ),
        },
        isDemystUser && {
          img: <img src={SingleLookupIcon} alt="Single Look Up" />,
          header: "Single Lookup",
          text: (
            <p>
              Get a sense of how actual data is returned by this provider. Enter
              a single entry of first-party data (i.e. a name or address) and
              review the data returned by this provider for that entry.
            </p>
          ),
          actions: <RunSingleLookUp productId={product.id} />,
        },
        {
          img: <img src={PythonApiIcon} alt="Access via API Docs" />,
          header: "Access via API Docs",
          text: (
            <p>
              Match your data quickly and easily with one of the most popular
              data science tools available, Python. Utilize the DemystData
              python package to explore and enrich your data to get the external
              data you need.
            </p>
          ),
          actions: (
            <a
              className="button mdb"
              target="_blank"
              rel="noopener noreferrer"
              href="https://demyst.com/docs/"
            >
              View API Docs
            </a>
          ),
        },
      ].filter(Boolean)}
    />
  );
}

GetData.propTypes = {
  isDemystUser: PropTypes.bool.isRequired,
  product: isProduct.isRequired,
};
