import { get } from "../helpers/network";

export const GET_DATA_CATEGORIES_REQUEST = "GET_DATA_CATEGORIES_REQUEST";
export const GET_DATA_CATEGORIES_SUCCESS = "GET_DATA_CATEGORIES_SUCCESS";
export const GET_DATA_CATEGORIES_ERROR = "GET_DATA_CATEGORIES_ERROR";

export const fetchDataCategories = () => async (dispatch, getState) => {
  const store = getState();

  if (store.data_categories.isFetching || store.data_categories.data) {
    // Request for (somewhat) static data - no need for multiple requests
    return store.data_categories.data;
  }

  dispatch({ type: GET_DATA_CATEGORIES_REQUEST });

  try {
    const data_categories = await get("/public/data_categories");
    dispatch({
      type: GET_DATA_CATEGORIES_SUCCESS,
      data: data_categories
    });
    return data_categories;
  } catch (error) {
    dispatch({
      type: GET_DATA_CATEGORIES_ERROR,
      error
    });
    throw error;
  }
};
