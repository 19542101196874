import React from "react";
import PropTypes from "prop-types";
import classNames from "merge-class-names";

import {
  buttonClickGAAction,
  GAEvent,
  GA_CATEGORY,
} from "../../utility/google_analytics";

import "./navigation.less";

import Authentication from "../../containers/header/authentication";
import Menu from "../../containers/header/menu";

export default function Navigation({ isOpen, user, isFetching }) {
  if (isFetching) {
    return null;
  }

  const items = [
    user && {
      label: "Data Catalog",
      href: "/products",
    },
    {
      label: "Docs",
      href: "/docs",
    },
  ].filter(Boolean);

  return (
    <nav
      className={classNames(
        "Navigation",
        // Don't "simplify" it to a ternary - isOpen is null by default
        isOpen === true && "Navigation--open",
        isOpen === false && "Navigation--closed"
      )}
    >
      <Menu items={items} />
      <Authentication />
    </nav>
  );
}

Navigation.propTypes = {
  isOpen: PropTypes.bool,
  isFetching: PropTypes.bool,
};
