import React from "react";
import ReactPaginate from "react-paginate";

import { isHistory, isLocation, isPagination } from "../../utility/prop-types";
import { addToQuery } from "../../../../stingray/frontend/utility/url";

import LeftArrow from "../../static/icons/arrow_left.svg";
import RightArrow from "../../static/icons/arrow_right.svg";

import "./paginate.less";

const getLocation = (location, urlKey, value) => {
  const newValue = value ? value : null;

  const newQuery = addToQuery(location.search, urlKey, newValue);

  return {
    ...location,
    search: newQuery
  };
};

const replaceFilters = (history, location, urlKey, value) => {
  history.replace(getLocation(location, urlKey, value));
};

export default function Paginate({
  history,
  location,
  paginationInfo: {
    current_page,
    total_pages
  }
}) {
  const onChange = ({ selected }) => {
    const value = selected + 1;
    replaceFilters(history, location, "page", value);
  };

  const currentPage = current_page > 0 ? current_page - 1 : 0;
  return (
    <div className="Paginate">
      <ReactPaginate
        previousLabel={<img src={LeftArrow} alt="Previous" />}
        nextLabel={<img src={RightArrow} alt="Next" />}
        breakLabel="..."
        breakClassName="break-me"
        pageCount={total_pages}
        marginPagesDisplayed={2}
        pageRangeDisplayed={5}
        onPageChange={onChange}
        forcePage={currentPage}
        containerClassName="Paginate__list"
        subContainerClassName="pages pagination"
        activeClassName="active"
      />
    </div>
  );
}

Paginate.propTypes = {
  history: isHistory.isRequired,
  location: isLocation.isRequired,
  paginationInfo: isPagination.isRequired
};
