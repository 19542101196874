import React from "react";
import PropTypes from "prop-types";
import classNames from "merge-class-names";

import "./placeholder.less";

export default function Placeholder({ children, isEnabled, theme }) {
  return isEnabled ? (
    <div className={classNames("Placeholder", `Placeholder--${theme}`)}>
      {children}
    </div>
  ) : children;
}

Placeholder.propTypes = {
  children: PropTypes.node,
  isEnabled: PropTypes.bool,
  theme: PropTypes.oneOf(["dark", "light"])
};
