import React from "react";
import PropTypes from "prop-types";
import { Helmet } from "react-helmet";

import { clipText } from "../utility/helpers";

function Head({
  canonicalUrl = window.location.href,
  children,
  description = "",
  title
}) {
  const originUrl = window.location.origin;
  const formattedTitle = `${title.trim()} | Demyst`;
  const formattedDescription = description && clipText(description.trim());
  const formattedCanonical = canonicalUrl + (canonicalUrl.slice(-1) != "/" ? "/" : "");

  return (
    <Helmet>
      <title>{formattedTitle}</title>
      <meta property="og:url" content={formattedCanonical} />
      <meta name="title" content={formattedTitle} />
      <meta property="og:title" content={formattedTitle} />
      <meta name="twitter:title" content={formattedTitle} />
      {description && <meta name="description" content={formattedDescription} />}
      {description && <meta property="og:description" content={formattedDescription} />}
      {description && <meta name="twitter:description" content={formattedDescription} />}
      <script type="application/ld+json">{`
        {
          "@context":"https://schema.org",
          "@graph": [
            {
              "@type":"Organization",
              "name":"Demyst",
              "url":"${originUrl}",
              "logo":"https://cdn.buttercms.com/RaRl4Fw7RuO5EvtBrQvx",
              "sameAs":[
                "https://twitter.com/demystdata",
                "https://www.linkedin.com/company/demystdata"
              ]
            },
            {
              "@type":"WebSite",
              "@id":"${originUrl}/#website",
              "url":"${originUrl}",
              "name":"Demyst",
              "inLanguage":"en-US",
              "potentialAction":[
                {
                  "@type":"SearchAction",
                  "target":"${originUrl}/products?search={search_term_string}",
                  "query-input":"required name=search_term_string"
                }
              ]
            },
            {
              "@type":"WebPage",
              "@id":"${formattedCanonical}#webpage",
              "url":"${formattedCanonical}",
              "name":"${formattedTitle}",
              "isPartOf":{
                "@id":"${originUrl}/#website"
              },
              "inLanguage":"en-US",
              ${description ? `"description":"${formattedDescription}",` : ""}
              "potentialAction":[
                {
                  "@type":"ReadAction",
                  "target":[
                    "${formattedCanonical}"
                  ]
                }
              ]
            }
          ]
        }
      `}</script>
      {children}
    </Helmet>
  );
}

Head.propTypes = {
  canonicalUrl: PropTypes.string,
  children: PropTypes.node,
  description: PropTypes.string,
  title: PropTypes.string.isRequired
};

export default Head;
