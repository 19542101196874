import React from "react";
import PropTypes from "prop-types";

import { isValidAttributeStat } from "../../utility/helpers";

import "./attribute_stat.less";

export default function AttributeStat({
  title,
  value
}) {
  if (!isValidAttributeStat(value)) {
    return null;
  }

  return (
    <div className="AttributeStat">
      <div className="AttributeStat__value">
        {value}
      </div>
      <div className="AttributeStat__title">
        {title}
      </div>
    </div>
  );
}

AttributeStat.propTypes = {
  title: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]).isRequired
};
