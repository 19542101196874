import React from "react";
import PropTypes from "prop-types";

const SortIcon = ({ color = "#424242", ...props }) => (
  <svg width="20px" height="20px" viewBox="0 0 20 20" {...props}>
    <path
      fill={color}
      fillRule="evenodd"
      d="M4.5 5.828v10.086a1 1 0 0 1-2 0V5.828l-.793.793A1 1 0 1 1 .293 5.207L3.5
      2l3.207 3.207a1 1 0 0 1-1.414 1.414L4.5 5.828zM9 13.914h11v3H9v-3zm0-5.5h9v3H9v-3zm0-5.5h7v3H9v-3z"
    />
  </svg>
);

SortIcon.propTypes = {
  color: PropTypes.string
};

export default SortIcon;
