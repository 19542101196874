import { createSelector } from "reselect";

export const getFileTransfers = store => store.file_transfers?.data;

export const getFileTransfer = createSelector(
  getFileTransfers,
  (store, fileId) => fileId,
  (files, fileId) => {
    if (!files) {
      return null;
    }
    // Check if file has id. Server can return [null]
    const match = files?.filter((file => file?.id === fileId));
    if (!match) {
      return null;
    }
    return match[0];
  }
);

export const isFetchingFileTransfers = store => store.file_transfers.isFetching;
