import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

// Actions
import { logOut } from "../../actions/session";

// Selectors

// Components
import Navigation from "../../components/header/navigation";

export default withRouter(connect(
  store => ({
    isFetching: store.session.isFetching,
    user: store.session.data && store.session.data.user
  }),
  {
    logOut
  }
)(Navigation));
