import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

// Actions
import { fetchUseCaseUsers } from "../../actions/use_cases";

// Selectors
import {
  getUseCaseUsers,
  isFetchingUseCaseUsers
} from "../../selectors/use_cases";

// Components
import TeamMembers from "../../components/use_case/team_members";

// Helpers
import { getIdFromParams } from "../../utility/helpers";

export default withRouter(
  connect(
    (store, ownProps) => {
      const useCaseId = getIdFromParams(ownProps.match.params);

      if (!useCaseId) {
        return {};
      }

      return {
        teamMembers: getUseCaseUsers(store, useCaseId),
        isFetching: isFetchingUseCaseUsers(store),
        useCaseId
      };
    },
    {
      fetchUseCaseUsers
    }
  )(TeamMembers)
);
