const defaultState = {
  isFetching: false,
  fetchedAt: null,
  error: null,
  data: null
};

export default function makeStaticReducer({
  actions: [
    REQUEST,
    SUCCESS,
    ERROR
  ]
}) {
  return (state = defaultState, action) => {
    switch (action.type) {
      case REQUEST: {
        return {
          ...state,
          isFetching: true
        };
      }
      case SUCCESS: {
        const { data } = action;

        return {
          ...state,
          isFetching: false,
          fetchedAt: new Date(),
          error: null,
          data
        };
      }
      case ERROR: {
        const { error } = action;

        return {
          ...state,
          isFetching: false,
          error
        };
      }
      default: return state;
    }
  };
}
