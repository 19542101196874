import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

// Actions
import { logOut } from "../../actions/session";

// Selectors
import { isDemystUser, getCurrentUser } from "../../selectors/session";

// Components
import Authentication from "../../components/header/authentication";

export default withRouter(connect(
  store => ({
    isDemystUser: isDemystUser(store),
    isFetching: store.session.isFetching,
    user: getCurrentUser(store)
  }),
  {
    logOut
  }
)(Authentication));
