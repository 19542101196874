import React, { useState } from "react";
import PropTypes from "prop-types";

export default function ExpandableRow({ children, expandChildren, rowIndex }) {
  const [isExpanded, setIsExpanded] = useState(false);

  if (expandChildren) {
    const expandRowId = `${rowIndex}_expand`;

    const expandCell = (
      <td>
        <button
          type="button"
          className="ExpandableRow__expandButton"
          aria-expanded={isExpanded}
          aria-controls={expandRowId}
          onClick={() => setIsExpanded(!isExpanded)}
          title={isExpanded ? "Collapse" : "Expand"}
        >
          {isExpanded ? "▼" : "▶"}
        </button>
      </td>
    );

    return (
      <>
        <tr>
          {children}
          {expandCell}
        </tr>
        {isExpanded && (
          <tr id={expandRowId}>
            <td colSpan={React.Children.count(children) + 1}>
              {expandChildren}
            </td>
          </tr>
        )}
      </>
    );
  }

  return (
    <tr>
      {children}
    </tr>
  );
}

ExpandableRow.propTypes = {
  children: PropTypes.node,
  expandChildren: PropTypes.node,
  rowIndex: PropTypes.number.isRequired
};
