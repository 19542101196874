import { get } from "../helpers/network";
import { stringify } from "query-string";
import { getDataSourceByName } from "../selectors/data_sources";

export const GET_DATA_SOURCES_REQUEST = "GET_DATA_SOURCES_REQUEST";
export const GET_DATA_SOURCES_SUCCESS = "GET_DATA_SOURCES_SUCCESS";
export const GET_DATA_SOURCES_ERROR = "GET_DATA_SOURCES_ERROR";

export const GET_DATA_SOURCE_REQUEST = "GET_DATA_SOURCE_REQUEST";
export const GET_DATA_SOURCE_SUCCESS = "GET_DATA_SOURCE_SUCCESS";
export const GET_DATA_SOURCE_ERROR = "GET_DATA_SOURCE_ERROR";

const shouldFetchData = results =>
  !results || !(results.isFetching || results.data);

export const fetchDataSources = () => async (dispatch, getState) => {
  const store = getState();
  const stringifiedParams = JSON.stringify({});
  if (!shouldFetchData(store.data_sources.results[stringifiedParams])) {
    // Request for (somewhat) static data - no need for multiple requests
    return Promise.resolve(store.data_sources.data);
  }

  dispatch({ type: GET_DATA_SOURCES_REQUEST });

  try {
    const data = await get("/public/data_sources");
    dispatch({
      type: GET_DATA_SOURCES_SUCCESS,
      data
    });
    return data;
  } catch (error) {
    dispatch({
      type: GET_DATA_SOURCES_ERROR,
      error
    });
    throw error;
  }
};

export const fetchDataSourceByID = dataSourceId => (dispatch, getState) => {
  const store = getState();

  if (store.data_sources.isFetching || store.data_sources.data) {
    // Request for (somewhat) static data - no need for multiple requests
    return Promise.resolve(store.data_sources.entities[dataSourceId]);
  }

  dispatch({ type: GET_DATA_SOURCE_REQUEST });

  return get(`/public/data_sources/${dataSourceId}`)
    .then((dataSource) => {
      dispatch({
        type: GET_DATA_SOURCE_SUCCESS,
        data: dataSource
      });
    })
    .catch((error) => {
      dispatch({
        type: GET_DATA_SOURCE_ERROR,
        error
      });
    });
};

export const fetchDataSourceByName = dataSourceName => (dispatch, getState) => {
  const store = getState();

  if (store.data_sources.isFetching || store.data_sources.data) {
    // Request for (somewhat) static data - no need for multiple requests
    return Promise.resolve(getDataSourceByName(store, dataSourceName));
  }

  dispatch({ type: GET_DATA_SOURCE_REQUEST });

  return get(`/public/data_sources/${dataSourceName}`)
    .then((dataSource) => {
      dispatch({
        type: GET_DATA_SOURCE_SUCCESS,
        data: dataSource
      });
    })
    .catch((error) => {
      dispatch({
        type: GET_DATA_SOURCE_ERROR,
        error
      });
    });
};

export const fetchDataSourcesSearch = params => async (dispatch, getState) => {
  const store = getState();

  const stringifiedParams = JSON.stringify(params);

  if (!shouldFetchData(store.data_sources.results[stringifiedParams])) {
    // Request for (somewhat) static data - no need for multiple requests
    return Promise.resolve(store.data_sources.results[stringifiedParams]);
  }

  dispatch({
    type: GET_DATA_SOURCES_REQUEST,
    params
  });

  try {
    const brands = await get(`/public/data_sources?${stringify(params)}`);
    dispatch({
      type: GET_DATA_SOURCES_SUCCESS,
      data: brands,
      params
    });
    return brands;
  } catch (error) {
    dispatch({
      type: GET_DATA_SOURCES_ERROR,
      error,
      params
    });
    throw error;
  }
};
