import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

// Actions
import { fetchProducts } from "../../../actions/products";

// Selectors
import { getProducts } from "../../../selectors/products";

// Components
import DataProviderFilter from "../../../components/attribute_catalog/filters/data_provider_filter";

export default withRouter(connect(
  store => ({
    products: getProducts(store, {})
  }),
  {
    fetchProducts
  }
)(DataProviderFilter));
