import React from "react";
import { Route, Redirect, Switch } from "react-router-dom";
import Products from "./products";
import DataSources from "./data_sources";
import Users from "../containers/users";
import AttributeCatalog from "./attribute_catalog/attribute_catalog";
import PrivacyPolicy from "./cms/privacy_policy/privacy_policy";
import TermsAndConditions from "./cms/terms_and_conditions/terms_and_conditions";
import APIDocs from "./cms/api_docs/api_docs";
import Error404 from "./error_404";
import UseCase from "../containers/use_case/use_case";
import UseCases from "../containers/use_cases/use_cases";
import AuthRoute from "../containers/auth_route";
import FileTransfers from "../containers/file_transfers/file_transfers";
import FileTransferDetail from "../containers/file_transfers/file_transfer_detail";
import HostedNotebook from "./cms/hosted_notebook/hosted_notebook";

export default function Main() {
  return (
    <Switch>
      <AuthRoute path="/products" component={Products} />
      <AuthRoute path="/data-sources" component={DataSources} />
      <Route path="/hosted-notebook" component={HostedNotebook} />
      <Route path="/privacy-policy" component={PrivacyPolicy} />
      <Route path="/terms-and-conditions" component={TermsAndConditions} />
      <Route path="/docs" component={APIDocs} />
      <Route path="/users" component={Users} />
      <Redirect exact from="/" to="/products" />
      <Route path="*" component={Error404} />
    </Switch>
  );
}
