import React from "react";
import PropTypes from "prop-types";

import "./security_pill.less";

const TYPES = {
  FCRA: "fcra",
  PII: "pii"
};

export default function SecurityPill({ children, type }) {
  const pillType = type ? TYPES[type.toUpperCase()] : "default";
  return (
    <div className={`SecurityPill SecurityPill--${pillType}`}>
      <span className="SecurityPill__content" title={children}>
        {children}
      </span>
    </div>
  );
}

SecurityPill.propTypes = {
  children: PropTypes.node,
  type: PropTypes.string
};
