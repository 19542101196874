@import (reference) "../../../styles/spacing.less";

.FormElement {
  display: flex;

  &:not(:last-child) {
    margin-bottom: @margin;
  }

  .Container {
    flex-grow: 1;
    flex-flow: column;
  }

  p {
    margin-bottom: 0;
  }

  &__label {
    text-align: left;
  }

  &__input {
    min-width: 240px;
    max-width: 260px;

    select, input, textarea {
      max-width: 100% !important;
      width: 100%;
    }

    button {
      display: block !important;
      margin-left: auto;
      min-width: 135px !important;
    }
  }

  .Box & {
    .Container {
      flex-flow: row;
    }
  }
}
