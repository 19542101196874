import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

import Placeholder from "../../placeholders/placeholder";
import UseCaseButton from "./use_case_button";

import "./data_product_button.less";

export default function DataProductButton({ isPlaceholder, product }) {
  const content = () => {
    const body = (
      <UseCaseButton
        subtitle={product?.data_source_name}
        title={product?.name}
        icon={<img alt={product?.name} src={product?.logo} />}
        iconClass="DataProductButton__icon"
      />
    );

    if (isPlaceholder) {
      // Do not make an active link when its a placeholder
      return <div className="DataProductButton">{body}</div>;
    }

    return (
      <Link className="DataProductButton" to={`/products/${product?.id}/data_dictionary`}>
        {body}
      </Link>
    );
  };

  return (
    <Placeholder isEnabled={isPlaceholder} theme="dark">
      {content()}
    </Placeholder>
  );
}

DataProductButton.propTypes = {
  isPlaceholder: PropTypes.bool,
  product: PropTypes.shape({
    data_source_name: PropTypes.string.isRequired,
    id: PropTypes.number.isRequired,
    logo: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired
  }).isRequired
};
