import React, { isValidElement } from "react";
import PropTypes from "prop-types";
import classNames from "merge-class-names";

import { toNaturalSentence } from "../../utility/string";

import "./use_case_button.less";

export default function UseCaseButton({
  containerClass,
  disabled,
  icon,
  iconClass,
  iconPosition,
  subtitle,
  title
}) {
  const isLeft = iconPosition === "left";
  const iconContent = icon && (
    <div className={classNames("UseCaseButton__icon", `UseCaseButton__icon--${iconPosition}`, iconClass)}>
      {icon}
    </div>
  );

  const formatTitle = () => {
    if (isValidElement(title)) {
      // If we passed in a component, assume it's already formatted properly
      return title;
    }
    return toNaturalSentence(title);
  };

  return (
    <div
      className={classNames(
        "UseCaseButton",
        disabled && "UseCaseButton--disabled",
        !isLeft && "UseCaseButton--space-between",
        containerClass
      )}
    >
      {isLeft && iconContent}
      <div className="UseCaseButton__body">
        <div className="UseCaseButton__title">{formatTitle()}</div>
        <div className="UseCaseButton__subtitle">{subtitle}</div>
      </div>
      {!isLeft && iconContent}
    </div>
  );
}

UseCaseButton.defaultProps = {
  iconPosition: "left"
};

UseCaseButton.propTypes = {
  containerClass: PropTypes.string,
  disabled: PropTypes.bool,
  icon: PropTypes.node,
  iconClass: PropTypes.string,
  iconPosition: PropTypes.oneOf(["left", "right"]),
  subtitle: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired
};
