@import (reference) "./styles/typography.less";
@import (reference) "./breakpoints.less";

.catalog-margin() {
  margin: 40px auto;

}

.attribute-catalog-margin() {
  margin: 20px 40px;

  .mobile({
    margin-left: 0;
    margin-right: 0;
  }, @upto-phone-landscape);
}

.arrow() {
  display: inline-block;
  transform: scaleY(0.5);
  font-size: 0.9em;
}

.slider() {
  .rc-slider {
    @slider-height: 2px;

    &-rail {
      height: @slider-height;
    }

    &-track {
      height: @slider-height;
      background-color: @light-blue-lighten-1;
    }

    &-step {
      height: @slider-height;
    }

    &-handle {
      border: solid 2px @light-blue-lighten-1;
      background-color: @light-blue-lighten-1;
      margin-top: -6px;
    }
  }
}

@img-padding: 60px;
@img-margin: 40px; //@space;

.border-divider(@position: left, @color: @snow-alt-1) {
  border-@{position}: 1px solid @color;
}

.empty-message-icon() {
  filter: grayscale(100%);
  display: block;
  margin: 0 auto 2em;
}

.circle(@size: auto) {
  width: @size;
  height: @size;
  border-radius: 50%;
  overflow: hidden;
}

.center-content() {
  display: flex;
  align-items: center;
  justify-content: center;
}

.max-lines(@numberOfLines) {
  // Since global line-height is 1.5, maximum height should be number of lines * 1.5
  max-height: @numberOfLines * 1.5em;
  overflow: hidden;

  @supports (display: -webkit-box) {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: @numberOfLines;
  }
}

.rounded-corners-small() {
  border-radius: 2px;
}

.rounded-corners() {
  border-radius: 4px;
}

.screen-reader-only() {
  position: absolute !important;
  height: 1px;
  width: 1px;
  overflow: hidden;
  clip: rect(1px 1px 1px 1px);
  /* IE6, IE7 */
  clip: rect(1px, 1px, 1px, 1px);
}
