import React, { lazy, Suspense, useEffect } from "react";
import { Redirect, Route, Switch } from "react-router-dom";

import "../../../stingray/frontend/components/mdboot/users.less";

// import MantaLegacyPageRedirect from "../../../stingray/frontend/components/mdboot/manta_legacy_page_redirect";
import Spinner from "../../../stingray/frontend/components/ui/spinner";
import DemystLogo from "../static/demyst_logo_short.svg";

import { isLocation, isUser } from "../utility/prop-types";
import { GAPageView } from "../utility/google_analytics";

import Head from "./head";

// Asynchronously loaded routes
const NotActivatedYet = lazy(() =>
  import("../../../stingray/frontend/components/not_activated_yet")
);
const SignUp = lazy(() => import("../containers/users/sign_up"));
const SignIn = lazy(() => import("../containers/users/sign_in"));
const ResetPassword = lazy(() => import("../containers/users/reset_password"));
const SendResetPasswordInstructions = lazy(() =>
  import("../containers/users/send_reset_password_instructions")
);
const ResendUnlockInstructions = lazy(() =>
  import("../containers/users/resend_unlock_instructions")
);
const ResendConfirmationInstructions = lazy(() =>
  import("../containers/users/resend_confirmation_instructions")
);
const ThankYou = lazy(() =>
  import("../../../stingray/frontend/components/thank_you")
);

const resendUnlockInstructionsProps = {
  errorMessage: "An error occurred while sending user unlocking instructions.",
  headerLabel: "Resend unlock instructions",
  submitLabel: "Resend Unlock Instructions",
  instructionsSentMessage:
    "Instructions for unlocking your account has been resent to",
};

const sendResetPasswordInstructionsProps = {
  errorMessage: "An error occurred while sending reset password instructions.",
  headerLabel: "Forgot your password?",
  submitLabel: "Send Reset Instructions",
  instructionsSentMessage:
    "Instructions for resetting your password have been sent to",
};

const resendConfirmationInstructions = {
  errorMessage: "An error occurred while sending user confirming instructions.",
  headerLabel: "Resend confirmation instructions",
  submitLabel: "Resend Confirmation Instructions",
  instructionsSentMessage:
    "Instructions for confirming your account has been resent to",
};

const locationMetatitles = {
  "/users/sign_in": "Sign In",
  "/users/sign_up": "Sign Up",
  "/users/password/edit": "Reset Password",
  "/users/password/new": "Forgot Password",
  "/users/unlock": "Resent Unlock Instructions",
  "/users/confirmation": "Resend Confirmation Instructions",
  "/users/not_activated_yet": "Not Activated Yet",
  "/users/thank-you": "Thank You",
};

export default function Users({ location, user }) {
  useEffect(() => {
    if (location) {
      locationMetatitles[location?.pathname] &&
        GAPageView(locationMetatitles[location?.pathname]);
    }
  }, [location]);

  const isLoggedIn = Boolean(user);

  const defaultLocation =
    (location.state && location.state.locationAfterLogin) || "/";

  return (
    <div className="Users">
      <div className="Users__container">
        <div className="Users__logo">
          <a href="https://demyst.com/">
            <img src={DemystLogo} />
          </a>
        </div>
        <div className="Users__card">
          <Suspense fallback={<Spinner big multicolor />}>
            <Switch>
              {/*
              <Route path="/users/password_expired" component={MantaLegacyPageRedirect} />
              */}
              {!isLoggedIn && (
                <Route path="/users/sign_in" component={SignIn} />
              )}
              {!isLoggedIn && (
                <Route path="/users/sign_up" component={SignUp} />
              )}
              {!isLoggedIn && (
                <Route path="/users/password/edit" component={ResetPassword} />
              )}
              {!isLoggedIn && (
                <Route
                  path="/users/password/new"
                  render={() => (
                    <SendResetPasswordInstructions
                      {...sendResetPasswordInstructionsProps}
                    />
                  )}
                />
              )}
              {!isLoggedIn && (
                <Route
                  path="/users/unlock"
                  render={() => (
                    <ResendUnlockInstructions
                      {...resendUnlockInstructionsProps}
                    />
                  )}
                />
              )}
              {!isLoggedIn && (
                <Route
                  path="/users/confirmation"
                  render={() => (
                    <ResendConfirmationInstructions
                      {...resendConfirmationInstructions}
                    />
                  )}
                />
              )}
              }
              <Route
                path="/users/not_activated_yet"
                component={NotActivatedYet}
              />
              <Route path="/users/thank-you" component={ThankYou} />
              <Redirect to={defaultLocation} />
            </Switch>
          </Suspense>
        </div>
        <div className="Users__footer">
          <nav>
            <ul>
              <li>
                <a href="mailto:support@demystdata.com">Support</a>
              </li>
              <li>
                <a href="https://demyst.com/terms-and-conditions">Terms</a>
              </li>
              <li>
                <a href="https://demyst.com/privacy-policy">Privacy</a>
              </li>
            </ul>
          </nav>
        </div>
      </div>
    </div>
  );
}

Users.propTypes = {
  location: isLocation.isRequired,
  user: isUser,
};
