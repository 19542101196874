export class PromiseCancelledException extends Error {
  constructor(message, type) {
    super(message, type);
    this.name = "PromiseCancelledException";
    this.message = message;
    this.type = type;
  }
}

export const makeCancellable = (promise) => {
  let isCancelled = false;

  const wrappedPromise = new Promise((resolve, reject) => {
    promise.then(
      (...args) => (isCancelled ? reject(new PromiseCancelledException("Promise cancelled")) : resolve(...args)),
      error => (isCancelled ? reject(new PromiseCancelledException("Promise cancelled")) : reject(error)),
    );
  });

  return {
    promise: wrappedPromise,
    cancel() {
      isCancelled = true;
    }
  };
};
