import React, { useEffect } from "react";
import PropTypes from "prop-types";

import AttachedFileButton from "./attached_file_button";
import AttachedFileButtonPlaceholder from "../../placeholders/use_case/attached_file_button";
import UseCaseSection from "./use_case_section";

export default function AttachedFiles({
  fetchUseCaseModels,
  files,
  isDemystUserNotOnRDP,
  isFetching,
  useCaseId
}) {
  useEffect(() => {
    useCaseId && fetchUseCaseModels(useCaseId);
  }, [fetchUseCaseModels, useCaseId]);

  if ((!isFetching && !files) || files?.length === 0) {
    return null;
  }

  const content = () => {
    if (isFetching) {
      return Array.from(new Array(5)).map((el, i) => (
        <AttachedFileButtonPlaceholder key={i} />
      ));
    }
    return (
      <>
        {files.map((file, i) => (
          <AttachedFileButton
            key={i}
            file={file.model}
            type={file.model_type}
            disabled={isDemystUserNotOnRDP}
          />
        ))}
      </>
    );
  };

  return (
    <UseCaseSection title="Files Added">
      <div className="AttachedFiles">{content()}</div>
    </UseCaseSection>
  );
}

AttachedFiles.propTypes = {
  fetchUseCaseModels: PropTypes.func.isRequired,
  files: PropTypes.arrayOf(
    PropTypes.shape({
      model: PropTypes.object.isRequired, // TODO: Dynamic - might be worth to abstract using OneOf(AegeanBatchInput, AegeanBatchRun) etc
      model_type: PropTypes.string.isRequired
    })
  ),
  isDemystUserNotOnRDP: PropTypes.bool.isRequired,
  isFetching: PropTypes.bool,
  useCaseId: PropTypes.number
};
