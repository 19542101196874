import React from "react";

import Dropdown from "../../../../../stingray/frontend/components/ui/dropdown";

import { FILTER_TYPES, SORT_DIRECTIONS, SORT_PARAMS } from "../../../utility/constants";
import { isHistory, isLocation } from "../../../utility/prop-types";
import { addToQuery } from "../../../../../stingray/frontend/utility/url";

const getLocation = (location, sortBy, sort) => {
  let newQuery = addToQuery(location.search, FILTER_TYPES.SORT_BY, sortBy);
  newQuery = addToQuery(newQuery, FILTER_TYPES.SORT, sort);

  return {
    ...location,
    search: newQuery
  };
};

const replaceFilters = (history, location, sortBy, sort) => {
  history.replace(getLocation(location, sortBy, sort));
};

export default function SortDropdown({ history, location }) {
  const getReplaceFilters = (sortBy, sort) => () => replaceFilters(history, location, sortBy, sort);

  return (
    <Dropdown
      label="Sort by"
      items={[
        <button
          type="button"
          onClick={getReplaceFilters(SORT_PARAMS.NAME, SORT_DIRECTIONS.ASC)}
        >
          Ascending (A-Z)
        </button>,
        <button
          type="button"
          onClick={getReplaceFilters(SORT_PARAMS.NAME, SORT_DIRECTIONS.DESC)}
        >
          Descending (Z-A)
        </button>,
        <button
          type="button"
          onClick={getReplaceFilters(SORT_PARAMS.NUMBER_OF_ATTRIBUTES, SORT_DIRECTIONS.DESC)}
        >
          Number of Attributes: Highest
        </button>,
        <button
          type="button"
          onClick={getReplaceFilters(SORT_PARAMS.NUMBER_OF_ATTRIBUTES, SORT_DIRECTIONS.ASC)}
        >
          Number of Attributes: Lowest
        </button>,
        <button
          type="button"
          onClick={getReplaceFilters(SORT_PARAMS.CREATED_AT, SORT_DIRECTIONS.DESC)}
        >
          Date Released: Latest
        </button>,
        <button
          type="button"
          onClick={getReplaceFilters(SORT_PARAMS.CREATED_AT, SORT_DIRECTIONS.ASC)}
        >
          Date Released: Oldest
        </button>,
        <button
          type="button"
          onClick={getReplaceFilters(SORT_PARAMS.MATCH_RATE, SORT_DIRECTIONS.DESC)}
        >
          Match Rate: Highest
        </button>,
        <button
          type="button"
          onClick={getReplaceFilters(SORT_PARAMS.MATCH_RATE, SORT_DIRECTIONS.ASC)}
        >
          Match Rate: Lowest
        </button>,
        <button
          type="button"
          onClick={getReplaceFilters(SORT_PARAMS.ERROR_RATE, SORT_DIRECTIONS.DESC)}
        >
          Error Rate: Highest
        </button>,
        <button
          type="button"
          onClick={getReplaceFilters(SORT_PARAMS.ERROR_RATE, SORT_DIRECTIONS.ASC)}
        >
          Error Rate: Lowest
        </button>
      ]}
    />
  );
}

SortDropdown.propTypes = {
  history: isHistory.isRequired,
  location: isLocation.isRequired
};
