import { get } from "../helpers/network";

export const GET_PUBLIC_SESSION_HASH_REQUEST = "GET_PUBLIC_SESSION_HASH_REQUEST";
export const GET_PUBLIC_SESSION_HASH_SUCCESS = "GET_PUBLIC_SESSION_HASH_SUCCESS";
export const GET_PUBLIC_SESSION_HASH_ERROR = "GET_PUBLIC_SESSION_HASH_ERROR";

export const fetchPublicSessionHash = () => async (dispatch, getState) => {
  const store = getState();

  if (store.public_session_hash.isFetching || store.public_session_hash.data) {
    // Request for (somewhat) static data - no need for multiple requests
    return Promise.resolve();
  }

  dispatch({ type: GET_PUBLIC_SESSION_HASH_REQUEST });

  try {
    const { session_hash } = await get("/session_hash");
    dispatch({
      type: GET_PUBLIC_SESSION_HASH_SUCCESS,
      data: session_hash
    });
    return session_hash;
  } catch (error) {
    dispatch({
      type: GET_PUBLIC_SESSION_HASH_ERROR,
      error
    });
    throw error;
  }
};
