import { stringify } from "query-string";
import { get } from "../helpers/network";

export const GET_ATTRIBUTES_REQUEST = "GET_ATTRIBUTES_REQUEST";
export const GET_ATTRIBUTES_SUCCESS = "GET_ATTRIBUTES_SUCCESS";
export const GET_ATTRIBUTES_ERROR = "GET_ATTRIBUTES_ERROR";

const shouldFetchData = results => !results || !(results.isFetching || results.data);
const getPaginationHeaders = (headers) => {
  const paginationHeaders = headers["x-pagination"];
  if (!paginationHeaders) {
    return {};
  }
  return JSON.parse(headers["x-pagination"]);
};

const INCLUDE_HEADERS = true;
const OPTIONS = {};

export const fetchAttributes = () => async (dispatch, getState) => {
  const store = getState();

  const stringifiedParams = JSON.stringify({});

  if (!shouldFetchData(store.attributes.results[stringifiedParams])) {
    // Request for (somewhat) static data - no need for multiple requests
    return store.attributes.results[stringifiedParams].data;
  }

  dispatch({ type: GET_ATTRIBUTES_REQUEST });

  try {
    const {
      headers,
      data: attributes
    } = await get("/public-api/attributes", OPTIONS, INCLUDE_HEADERS);

    const paginationHeaders = getPaginationHeaders(headers);

    dispatch({
      type: GET_ATTRIBUTES_SUCCESS,
      data: attributes,
      paginationHeaders
    });

    return attributes;
  } catch (error) {
    dispatch({
      type: GET_ATTRIBUTES_ERROR,
      error
    });

    throw error;
  }
};

// Make sure this matches range in src/manta/app/controllers/public/public_attributes_controller.rb
const range = {
  $: [0.0, 1.56],
  $$: [1.56, 4.86],
  $$$: [4.86, 8.22],
  $$$$: [8.22, 1000.0]
};

function whitelistCostFromParams(params) {
  let { cost_low, cost_high } = params;
  const lowRange = range[cost_low];
  const highRange = range[cost_high];

  if (lowRange) {
    [cost_low] = lowRange;
  }
  if (highRange) {
    [, cost_high] = highRange;
  }

  return {
    ...params,
    cost_low,
    cost_high
  };
}
export const fetchAttributesSearch = params => async (dispatch, getState) => {
  const store = getState();

  const stringifiedParams = JSON.stringify(params);

  if (!shouldFetchData(store.attributes.results[stringifiedParams])) {
    // Request for (somewhat) static data - no need for multiple requests
    return store.attributes.results[stringifiedParams].data;
  }

  const safeParams = whitelistCostFromParams(params);

  dispatch({
    type: GET_ATTRIBUTES_REQUEST,
    params
  });

  try {
    const urlSafeParams = stringify(safeParams, { arrayFormat: "bracket" });
    const {
      headers,
      data: attributes
    } = await get(`/public-api/attributes?${urlSafeParams}`, OPTIONS, INCLUDE_HEADERS);

    const paginationHeaders = getPaginationHeaders(headers);

    dispatch({
      type: GET_ATTRIBUTES_SUCCESS,
      data: attributes,
      params,
      paginationHeaders
    });

    return attributes;
  } catch (error) {
    dispatch({
      type: GET_ATTRIBUTES_ERROR,
      error,
      params
    });

    throw error;
  }
};
