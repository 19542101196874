import React, { useEffect } from "react";
import { Link, Redirect } from "react-router-dom";
import PropTypes from "prop-types";
import { toast } from "react-toastify";

import StatusTag from "../use_case/status_tag";
import LayoutWithSubheader from "../ui/layout_with_subheader";
import UseCasesItemPlaceholder from "./use_cases_item_placeholder";

import { isError, isUseCase } from "../../utility/prop-types";
import { GAPageView } from "../../utility/google_analytics";

import "./use_cases.less";

export default function UseCases({ error, fetchUseCases, useCases }) {
  const metatitle = "Use Cases Dashboard";

  useEffect(() => {
    fetchUseCases();
    metatitle && GAPageView(metatitle);
  }, [fetchUseCases, metatitle]);

  if (error?.code === 401) {
    // TODO: Add to single use case page as well - prob go to stg and see what the payload in redux looks like to fix the reducer
    toast.error(error.message, { autoClose: false });
    return <Redirect to="/" />;
  }

  if (!useCases) {
    return null;
  }

  const renderDescription = (description) => {
    if (description) {
      if (description?.length <= 175) {
        return description;
      } else {
        return `${description?.substring(0, 172)}...`;
      }
    }
    return (
      <em>No description</em>
    );
  };

  return (
    <LayoutWithSubheader className="UseCases" metaTitle={metatitle} title="Use Cases">
      <div className="UseCases__wrapper">
        {useCases.map(({
          description,
          edaas_tags,
          id,
          name
        }) => (
          <Link
            key={id}
            className="UseCases__item"
            to={`/use-cases/${id}`}
          >
            <div className="UseCases__item__name">{name}</div>
            <p className="UseCases__item__description">
              {renderDescription(description)}
            </p>
            <div className="UseCases__item__tags">
              {edaas_tags?.map(tag => (
                <StatusTag key={tag.name} title={tag.name} />
              ))}
            </div>
          </Link>
        ))}
        <UseCasesItemPlaceholder />
      </div>
    </LayoutWithSubheader>
  );
}

UseCases.propTypes = {
  error: isError,
  fetchUseCases: PropTypes.func.isRequired,
  useCases: PropTypes.arrayOf(isUseCase)
};
