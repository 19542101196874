import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import Footer from "../../components/footer/footer";

export default withRouter(
  connect(store => ({
    user: store.session.data && store.session.data.user
  }))(Footer)
);
