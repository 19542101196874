import React from "react";
import PropTypes from "prop-types";

import Head from "../head";
import Header from "../header/header";
import Footer from "../../containers/footer/footer";
import Section from "../cms/cms_section/cms_section"; // TODO: Refactor into normal section

import "./layout_with_subheader.less";

export default function LayoutWithSubheader({
  children,
  metaTitle,
  title,
  useSection,
  ...otherProps
}) {
  return (
    <div {...otherProps}>
      <Header />
      <div className="LayoutWithSubheader">
        <Head title={metaTitle || title} />
        <Section className="LayoutWithSubheader__header">
          <div className="LayoutWithSubheader__header__content">
            <h1>{title}</h1>
          </div>
        </Section>
        <div className="LayoutWithSubheader__body">
          {useSection ? <Section col>{children}</Section> : children}
        </div>
      </div>
      <Footer />
    </div>
  );
}

LayoutWithSubheader.defaultProps = {
  useSection: true
};

LayoutWithSubheader.propTypes = {
  children: PropTypes.node.isRequired,
  metaTitle: PropTypes.string,
  title: PropTypes.string.isRequired,
  useSection: PropTypes.bool
};
