import { connect } from "react-redux";

// Actions
import { fetchFileTransfer } from "../../actions/file_transfers";

// Selectors
import { getFileTransfer } from "../../selectors/file_transfers";

// Components
import FileTransferDetail from "../../components/file_transfers/file_transfer_detail";

// Helpers
import { getIdFromParams } from "../../utility/helpers";

export default connect(
  (store, ownProps) => {
    const fileId = getIdFromParams(ownProps.match.params);

    if (!fileId) {
      return {};
    }

    return {
      file: getFileTransfer(store, fileId),
      fileId
    };
  },
  {
    fetchFileTransfer
  }
)(FileTransferDetail);
