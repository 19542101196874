import React from "react";
import PropTypes from "prop-types";

import "./product_details.less";

import Placeholder from "../../placeholders/placeholder";
import ProviderLogo from "../ui/provider_logo";

import { getProductAliasOrName } from "../../utility/helpers";
import { isHistory, isLocation, isProduct } from "../../utility/prop-types";

import CloseIcon from "../../static/icons/components/close";

export default function ProductDetailsHeader({
  history,
  isPlaceholder,
  location,
  onCloseLink,
  product
}) {
  const headerContent = (
    <>
      <div className="ProductDetails__controls">
        <h2>{getProductAliasOrName(product)}</h2>
        <button
          type="button"
          className="ProductDetails__close"
          title="Close Product Details"
          onClick={() => history.push({
            ...location,
            pathname: onCloseLink || "/products"
          })}
        >
          <CloseIcon />
        </button>
      </div>
      <div className="ProductDetails__logo">
        <ProviderLogo product={product} theme="dark" />
      </div>
    </>
  );

  return (
    <div className="ProductDetails__header">
      <Placeholder
        isEnabled={isPlaceholder}
        theme="dark"
      >
        {headerContent}
      </Placeholder>
    </div>
  );
}

ProductDetailsHeader.propTypes = {
  history: isHistory.isRequired,
  isPlaceholder: PropTypes.bool,
  location: isLocation.isRequired,
  onCloseLink: PropTypes.string,
  product: isProduct.isRequired,
  productId: PropTypes.number.isRequired // eslint-disable-line react/no-unused-prop-types
};
