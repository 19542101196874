import {
  GET_SAMPLE_DATA_REQUEST,
  GET_SAMPLE_DATA_SUCCESS,
  GET_SAMPLE_DATA_ERROR
} from "../actions/sample_data";

const defaultState = {};

const defaultStatePiece = {
  isFetching: false,
  fetchedAt: null,
  error: null,
  data: null
};

export default (state = defaultState, action) => {
  switch (action.type) {
    case GET_SAMPLE_DATA_REQUEST: {
      const { productId } = action;

      const statePiece = {
        ...defaultStatePiece,
        ...state[productId],
        isFetching: true
      };

      return {
        ...state,
        [productId]: statePiece
      };
    }
    case GET_SAMPLE_DATA_SUCCESS: {
      const { data, productId } = action;

      const statePiece = {
        ...defaultStatePiece,
        ...state[productId],
        isFetching: false,
        fetchedAt: new Date(),
        error: null,
        data
      };

      return {
        ...state,
        [productId]: statePiece
      };
    }
    case GET_SAMPLE_DATA_ERROR: {
      const { error, productId } = action;

      const statePiece = {
        ...defaultStatePiece,
        ...state[productId],
        isFetching: false,
        error
      };

      return {
        ...state,
        [productId]: statePiece
      };
    }
    default: return state;
  }
};
