import React from "react";
import PropTypes from "prop-types";
import { Redirect, Route } from "react-router-dom";

import {
  buttonClickGAAction,
  GAEvent,
  GA_LABEL_TITLE
} from "../utility/google_analytics";

import { isUser } from "../utility/prop-types";

export default function AuthRoute({
  component: Component,
  isFetched,
  user,
  ...otherProps
}) {
  return (
    <Route
      render={(routeProps) => {
        if (!isFetched) {
          // Until session data is not fetched, don't render any page and don't redirect the user anywhere
          return null;
        }

        const isLoggedIn = Boolean(user);

        if (!isLoggedIn) {
          const { location: { pathname } } = routeProps;
          // Track if user with no access clicked on an auth route that points to a product page
          const found = pathname.match(
            /\/products\/.+\/(data_dictionary|sample_data|single_lookup)$/
          );
          if (found) {
            GAEvent({
              action: buttonClickGAAction("redirect to sign up"),
              labelTitle: GA_LABEL_TITLE.REQUEST_DATA,
              labelInfo: `(from product page - tabs - ${found[1]})`
            });
          }
          return (
            <Redirect
              to={{
                pathname: "/users/sign_in",
                state: {
                  locationAfterLogin: pathname
                }
              }}
            />
          );
        }

        return (
          <Component {...routeProps} />
        );
      }}
      {...otherProps}
    />
  );
}

AuthRoute.propTypes = {
  ...Route.propTypes,
  isFetched: PropTypes.bool.isRequired,
  user: isUser
};
