import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

import InputWithSuggestions from "../../../../stingray/frontend/components/ui/input_with_suggestions/input_with_suggestions";

const renderItem = (item) => {
  const { link, logo, name } = item;
  return (
    <li className="Search__item" key={name}>
      <Link to={link}>
        <div className="Search__item__icon">
          {logo && (
            <img src={logo} alt="Brand Logo" />
          )}
        </div>
        <div className="Search__item__label">
          <span className="Search__item__label__name">{name}</span>
        </div>
      </Link>
    </li>
  );
};

const renderItemGroup = (groupName, values) => {
  if (!values.length) {
    return null;
  }

  return (
    <Fragment key={groupName}>
      <h5 className="Search__header">{groupName}</h5>
      <ul className={`Search__items Search__items--${groupName.replace(/\s+/g, "-").toLowerCase()}`}>
        {values.map(item => renderItem(item))}
      </ul>
    </Fragment>
  );
};

export default function SearchContent({ children, data, performRawSearch }) {
  const renderItemGroups = (search) => {
    if (!search) {
      return null;
    }

    const rawSearchElement = (
      <ul className="Search__items Search__items--raw" key="raw">
        <li className="Search__item" key="raw search">
          <button type="button" onClick={performRawSearch}>
            <div className="Search__item__label">
              <span className="Search__item__label__name">
                Search "{search}"
              </span>
            </div>
          </button>
        </li>
      </ul>
    );

    const hasAnyItems = Object.values(data).some(items => items.length);

    if (!hasAnyItems) {
      // If no "autocomplte" matches have been found
      // Simply hide the results and just show the option
      // for the user to search the raw string they inputted
      return rawSearchElement;
    }

    const itemEntries = Object.entries(data);

    const itemGroups = itemEntries.map(([groupName, values]) =>
      renderItemGroup(groupName, values));

    return [...itemGroups, rawSearchElement];
  };

  return (
    <InputWithSuggestions getSuggestions={renderItemGroups}>
      {children}
    </InputWithSuggestions>
  );
}

SearchContent.propTypes = {
  children: PropTypes.node,
  data: PropTypes.objectOf(
    PropTypes.arrayOf(
      PropTypes.shape({
        link: PropTypes.string.isRequired,
        logo: PropTypes.string,
        name: PropTypes.string.isRequired
      })
    )
  ),
  performRawSearch: PropTypes.func.isRequired
};
