import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { isDemystUser } from "../../selectors/session";
import { get } from "../../helpers/network";

export default function OrganizationSelect({ onChange }) {
  const [organizations, setOrganizations] = useState(null);
  const isUserFromDemystOrg = useSelector(store => isDemystUser(store));

  useEffect(() => {
    if (isUserFromDemystOrg) {
      const getOrganizations = async () => {
        try {
          const organizations = await get("/organization/get_all_organizations");
          setOrganizations(organizations);
        } catch (error) {
          // Pass
          console.log("ERROR - ", error);
        }
      };
      getOrganizations();
    }
  }, [isUserFromDemystOrg]);

  const onChangeSelect = (event) => {
    const { target: { value } } = event;

    if (value) {
      onChange(value);
    }
  };

  if (!isUserFromDemystOrg) {
    return null;
  }

  return (
    <>
      <label htmlFor="organization_id">
        Organization
      </label>
      <select
        id="organization_id"
        name="organization_id"
        disabled={organizations === null}
        onChange={onChangeSelect}
        required
      >
        <option hidden value="">
          Select organization
        </option>
        {organizations?.map(item => (
          <option key={item.id} value={item.id}>
            {item.name}
          </option>
        ))}
      </select>
    </>
  );
}

OrganizationSelect.propTypes = {
  onChange: PropTypes.func.isRequired
};
