import React from "react";
import PropTypes from "prop-types";

import MultiselectFilter from "../../../containers/catalog/filters/multiselect_filter";

import { getSortByPropSorter } from "../../../utility/helpers";
import { useMountEffect } from "../../../utility/hooks";
import { isTag } from "../../../utility/prop-types";

export default function UseCasesFilter({
  fetchTags,
  tags
}) {
  useMountEffect(() => {
    fetchTags();
  });

  const formattedUseCases = tags?.map(({ name }) => ({
    name,
    value: name
  }));

  const data = formattedUseCases?.sort(getSortByPropSorter("name", true, name => name.toUpperCase()));

  const urlKey = "use_cases";

  return (
    <MultiselectFilter
      items={data}
      label="Filter by Use Case"
      name={urlKey}
      urlKey={urlKey}
    />
  );
}

UseCasesFilter.propTypes = {
  fetchTags: PropTypes.func.isRequired,
  tags: PropTypes.arrayOf(isTag)
};
