import React from "react";
import PropTypes from "prop-types";

const FilterIcon = ({ color = "#424242", ...props }) => (
  <svg width="16px" height="16px" viewBox="0 0 16 16" {...props}>
    <path
      fill={color}
      fillRule="evenodd"
      d="M6 7.178L1.615 1.817A.5.5 0 0 1 2.001 1h12a.5.5 0 0 1 .387.817L10 7.178V12.5a.5.5 0 0 1-.223.416l-3 2A.5.5 0 0 1 6 14.5V7.178z"
    />
  </svg>
);

FilterIcon.propTypes = {
  color: PropTypes.string
};

export default FilterIcon;
