import React, { useState } from "react";
import PropTypes from "prop-types";
import { NativeTypes } from "react-dnd-html5-backend";
import { useDrop } from "react-dnd";
import UploadedFileList from "./uploaded_file_list";

import "./drop_file.less";

export default function DropFile({ onFileAttached, showList }) {
  const [droppedFile, setDroppedFile] = useState(null);

  const handleNewFile = (newFile) => {
    setDroppedFile(newFile);
    onFileAttached(newFile);
  };

  const [{ canDrop, isOver }, drop] = useDrop({
    accept: [NativeTypes.FILE],
    drop(item, monitor) {
      const { files } = monitor.getItem();
      const [file] = files;
      handleNewFile(file);
    },
    collect: monitor => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop()
    })
  });

  const isActive = canDrop && isOver;

  const onChange = (e) => {
    const { files } = e.target;
    const [file] = files;
    handleNewFile(file);
  };

  return (
    <>
      <div ref={drop} className="DropFile">
        <input type="file" id="upload-file" name="file" onChange={onChange} />
        <p>
          {isActive
            ? "Release to drop"
            : "Drop a file here or select from computer"}
        </p>
      </div>
      {showList && <UploadedFileList files={[droppedFile].filter(Boolean)} />}
    </>
  );
}

DropFile.propTypes = {
  onFileAttached: PropTypes.func.isRequired,
  showList: PropTypes.bool
};
