import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

// Actions
import { createNoteEvent } from "../../actions/use_cases";

// Selectors
import { getCurrentUser, isDemystUserNotOnRDP } from "../../selectors/session";

// Components
import NoteEditor from "../../components/use_case/note_editor";

// Helpers
import { getIdFromParams } from "../../utility/helpers";

export default withRouter(
  connect(
    (store, ownProps) => {
      const useCaseId = getIdFromParams(ownProps.match.params);

      if (!useCaseId) {
        return {};
      }
      return {
        isDemystUserNotOnRDP: isDemystUserNotOnRDP(store),
        user: getCurrentUser(store),
        useCaseId
      };
    },
    {
      createNoteEvent
    }
  )(NoteEditor)
);
