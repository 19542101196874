import React from "react";
import PropTypes from "prop-types";

import "./authentication.less";

import { Link } from "react-router-dom";

import Dropdown from "../../../../stingray/frontend/components/ui/dropdown";
import UserAvatar from "../ui/user_avatar";

import getUserLinks from "./user_links";
import { isLocation, isUser } from "../../utility/prop-types";

export default function Authentication({
  isDemystUser,
  isFetching,
  location,
  logOut,
  user,
}) {
  if (isFetching && !user) {
    return null;
  }

  return (
    <section className="Authentication">
      {user ? (
        <div className="Authentication__userInfo">
          <Dropdown
            label={<UserAvatar size={37} />}
            hideArrow={true}
            items={getUserLinks({
              isDemystUser,
              logOut,
            })}
          />
        </div>
      ) : (
        <a
          className="Authentication__request-data link-button"
          href="https://demyst.com/login"
          target="_blank"
        >
          Customer Log in
        </a>
      )}
    </section>
  );
}

Authentication.propTypes = {
  isDemystUser: PropTypes.bool.isRequired,
  isFetching: PropTypes.bool,
  location: isLocation.isRequired,
  logOut: PropTypes.func.isRequired,
  user: isUser,
};
