export const removeChildElement = (child, locationOnDom = document.body) => locationOnDom.removeChild(child);

export const createAndAppendDomElement = ({
  element = "a",
  attributes = [{
    type: "href",
    value: "#"
  }],
  locationOnDom = document.body
}) => {
  const newElement = document.createElement(element);
  attributes.forEach(({ type, value }) => newElement.setAttribute(type, value));
  return locationOnDom.appendChild(newElement); // Required for FF
};

export const createAndAppendDownloadLink = ({ href, download }) => createAndAppendDomElement({
  attributes: [
    {
      type: "href",
      value: href
    },
    {
      type: "download",
      value: download
    }
  ]
});
