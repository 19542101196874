import React from "react";
import PropTypes from "prop-types";

export default function DownloadIcon({ color = "#0D7DA5", ...props }) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 16 16" {...props}>
      <defs>
        <path
          d="M6.75 0h2.5c.416 0 .75.334.75.75V6h2.74c.557 0 .835.672.441 1.066l-4.753 4.756a.605.605 0 01-.853 0l-4.76-4.756A.624.624 0 013.257 6H6V.75c0-.416.334-.75.75-.75zM16 11.75v3.5c0 .416-.334.75-.75.75H.75a.748.748 0 01-.75-.75v-3.5c0-.416.334-.75.75-.75h4.584l1.532 1.531c.628.628 1.64.628 2.268 0L10.666 11h4.584c.416 0 .75.334.75.75zm-3.875 2.75a.627.627 0 00-.625-.625.627.627 0 00-.625.625c0 .344.281.625.625.625a.627.627 0 00.625-.625zm2 0a.627.627 0 00-.625-.625.627.627 0 00-.625.625c0 .344.281.625.625.625a.627.627 0 00.625-.625z"
          id="download_svg__a"
        />
      </defs>
      <g fill="none" fillRule="evenodd" opacity={0.5}>
        <mask id="download_svg__b" fill="#fff">
          <use xlinkHref="#download_svg__a" />
        </mask>
        <g mask="url(#download_svg__b)" fill={color}>
          <path d="M0 0h16v16H0z" />
        </g>
      </g>
    </svg>
  );
}

DownloadIcon.propTypes = {
  color: PropTypes.string
};
