import { connect } from "react-redux";

import { fetchFileTransfers } from "../../actions/file_transfers";
import {
  getFileTransfers,
  isFetchingFileTransfers
} from "../../selectors/file_transfers";
import FileTransfers from "../../components/file_transfers/file_transfers";

export default connect(
  store => ({
    files: getFileTransfers(store),
    isFetchingFileTransfers: isFetchingFileTransfers(store)
  }),
  {
    fetchFileTransfers
  }
)(FileTransfers);
