import React, { useEffect, useMemo } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

import FileTransfersTable from "./file_transfers_table";
import LayoutWithSubheader from "../ui/layout_with_subheader";
import UploadFile from "./upload_file";
import GPGKey from "./gpg_key";

import { isFileTransfer } from "../../utility/prop-types";
import { humanizedDate } from "../../utility/date";
import DownloadIcon from "../../static/icons/components/download";
import { GAPageView } from "../../utility/google_analytics";

import Spinner from "../spinner";

import "./file_transfers.less";

export default function FileTransfers({
  fetchFileTransfers,
  files,
  isFetchingFileTransfers,
}) {
  useEffect(() => {
    fetchFileTransfers?.();
  }, [fetchFileTransfers]);

  const columns = useMemo(
    () => [
      {
        Header: "ID",
        accessor: "id",
      },
      {
        Header: "Name",
        accessor: "display_key",
        Cell: ({
          // eslint-disable-next-line react/prop-types
          row: {
            // eslint-disable-next-line react/prop-types
            original: { display_key, id },
          },
        }) => <Link to={`/files/${id}`}>{display_key}</Link>,
      },
      {
        Header: "Uploaded By",
        accessor: (originalRow) => {
          const uploader = originalRow?.uploader;
          if (!uploader) {
            return "-";
          }
          return uploader.name;
        },
      },
      {
        Header: "Organization",
        accessor: "organization_name",
      },
      {
        Header: "Use Case",
        accessor: (originalRow) => {
          const use_cases = originalRow?.edaas_use_cases;
          if (!use_cases) {
            return "-";
          }
          const cows = use_cases.map((x) => (
            <Link to={`/use-cases/${x.id}`}>{x.name}</Link>
          ));
          return cows;
        },
      },
      {
        Header: "Created At",
        accessor: "created_at",
        Cell: ({ value }) => humanizedDate(value),
      },
      {
        Header: "Uploaded At",
        accessor: "updated_at",
        Cell: ({ value }) => humanizedDate(value),
      },
      {
        Header: "Download File",
        accessor: (originalRow) => {
          if (!originalRow.expires_in_the_future) {
            return "Expired at " + originalRow.expiry_time;
          }
          if (originalRow.perished) {
            return "Expired off S3 automatically";
          }
          const presigned_get_url = originalRow.presigned_get_url;
          return (
            <a
              className="button cyan border-only"
              href={presigned_get_url}
              download
            >
              <DownloadIcon />
            </a>
          );
        },
      },
    ],
    []
  );

  const data = useMemo(() => files, [files]);

  if (isFetchingFileTransfers) {
    return <Spinner />;
  }

  if (!files) {
    return <div>You currently have no accessible files</div>;
  }

  GAPageView("My Files");

  return (
    <LayoutWithSubheader title="My Files" useSection={false}>
      <div className="FileTransfers">
        <div className="FileTransfers__left">
          <FileTransfersTable columns={columns} data={data} />
        </div>
        <div className="FileTransfers__right">
          <div>
            <h3>Upload File</h3>
            <UploadFile />
            <GPGKey />
          </div>
        </div>
      </div>
    </LayoutWithSubheader>
  );
}

FileTransfers.propTypes = {
  fetchFileTransfers: PropTypes.func.isRequired,
  files: PropTypes.arrayOf(isFileTransfer),
  isFetchingFileTransfers: PropTypes.bool.isRequired,
};
