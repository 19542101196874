import React from "react";
import PropTypes from "prop-types";

import "./use_case_section.less";

export default function UseCaseSection({ children, title }) {
  return (
    <div className="UseCaseSection">
      <h3 className="UseCaseSection__title">{title}</h3>
      {children}
    </div>
  );
}

UseCaseSection.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.string.isRequired
};
