import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { parse } from "query-string";

// Actions
import { fetchAttributes, fetchAttributesSearch } from "../../actions/attributes";

// Selectors
import { getAttributes } from "../../selectors/attributes";

// Components
import AttributeCards from "../../components/attribute_catalog/attribute_cards";

export default withRouter(connect(
  (store, ownProps) => {
    const params = parse(ownProps.location.search, { arrayFormat: "bracket" });
    return {
      attributes: getAttributes(store, params)
    };
  },
  {
    fetchAttributes,
    fetchAttributesSearch
  }
)(AttributeCards));
