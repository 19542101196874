import React, { Component } from "react";
import PropTypes from "prop-types";
import throttle from "lodash.throttle";

import "./infinite_scroll.less";

const findScrollContainer = (element) => {
  if (!element) {
    return undefined;
  }

  let parent = element.parentElement;
  while (parent) {
    const { overflow } = window.getComputedStyle(parent);
    if (overflow.split(" ").every(o => o === "auto" || o === "scroll")) {
      return parent;
    }
    parent = parent.parentElement;
  }

  return document.documentElement;
};

export default class InfiniteScroll extends Component {
  static propTypes = {
    children: PropTypes.node
  };

  state = {
    displayCount: 10
  };

  componentDidMount() {
    this.scrollContainer = findScrollContainer(this.container);

    this.scrollContainer.addEventListener("scroll", this.onScroll);
  }

  componentWillUnmount() {
    this.scrollContainer.removeEventListener("scroll", this.onScroll);
  }

  onScroll = throttle(() => {
    // No Show more button = no more children to render
    if (!this.showMoreButton) {
      return;
    }

    const windowBottomBorderY = this.scrollContainer.scrollTop + window.innerHeight;
    const buttonTopBorderY = this.showMoreButton.offsetTop;

    if (windowBottomBorderY + window.innerHeight * 0.3 > buttonTopBorderY) {
      this.showMore();
    }
  }, 400);

  showMore = () => {
    this.setState(prevState => ({
      displayCount: prevState.displayCount + 10
    }));
  };

  renderShowMore() {
    const { children } = this.props;
    const { displayCount } = this.state;

    if (displayCount >= React.Children.count(children)) {
      return null;
    }

    return (
      <button
        type="button"
        className="InfiniteScroll__showMoreButton"
        onClick={this.showMore}
        ref={(ref) => { this.showMoreButton = ref; }}
      >
        Show more
      </button>
    );
  }

  render() {
    const { children } = this.props;
    const { displayCount } = this.state;

    return (
      <div
        className="InfiniteScroll"
        ref={(ref) => { this.container = ref; }}
      >
        {React.Children.toArray(children).slice(0, displayCount)}
        {this.renderShowMore()}
      </div>
    );
  }
}
