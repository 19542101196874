@import (reference) "../../styles/colors.less";
@import (reference) "../../helpers.less";

.ProductTableHeader {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  &__name {
    padding: 0 1em;
    text-transform: capitalize;
  }

  &__control_buttons {
    display: flex;
    margin-left: 2em;

    .Dropdown__label {
      white-space: nowrap;
      &__label {
        .center-content();
        svg {
          display: block;
        }
      }
    }
    svg {
      &.filter_icon {
        width: auto;
        height: auto;
      }

      &.sort_icon {
        width: 1.15em;
        height: 1.15em;
      }

      path {
        fill: #59698d; // TODO: Not in palette yet
      }

      &.active path {
        fill: @light-blue-lighten-1;
      }
    }

    > button, .MultiselectDropdown {
      .border-divider();
      display: inline-block;
    }

    > button {
      padding: 0 1em;
      margin: 2px;
      &.sort_table_button {
        .center-content();
      }
    }

    > .MultiselectDropdown {
      padding: 0;

      .Dropdown__items {
        min-width: 13.5em;

        .MultiselectDropdown__item {
          input[type="search"] {
            max-width: unset;
          }

          &__label {
            font-weight: normal;
          }

          &__checkbox {
            margin-right: .5em;
          }

          button {
            padding: 0 .5em;
          }
        }
      }
    }
  }
}
