import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { parse } from "query-string";

// Actions

// Selectors

// Components
import ClearFilterButton from "../../components/catalog/clear_filter_button";

const isFilterEnabled = (filter) => {
  if (
    !filter
    || Array.isArray(filter) && !filter.length
  ) {
    return false;
  }

  return true;
};

export default withRouter(connect(
  (store, ownProps) => {
    const {
      categories,
      regions,
      sort_by,
      sort
    } = parse(ownProps.location.search, { arrayFormat: "bracket" });
    const filterOptions = {
      categories,
      regions,
      sort_by,
      sort
    };

    const areFiltersEnabled = Object.values(filterOptions).some(isFilterEnabled);

    return {
      areFiltersEnabled
    };
  }
)(ClearFilterButton));
