export const createBlob = (string) => {
  if (!string) {
    return null;
  }

  return new Blob([string], {
    type: "text/plain"
  });
};

export const getBlobUrl = (blob) => {
  if (!blob) {
    return null;
  }

  return URL.createObjectURL(blob);
};

const prepareCell = (cell) => {
  if (cell === null || cell === undefined) {
    return "";
  }

  return `"${cell}"`;
};

/**
 * There are two data shapes supported.
 *
 * 1.
 * {
 *   Label: "Value",
 *   "Another label": ["Another value 1", "Another value 2"]
 * }
 *
 * 2.
 *
 * [
 *   {
 *     Label: "Value",
 *     "Another label": "Another value"
 *   },
 *   {
 *     Label: "Value",
 *     "Another label": ["Another value 1", "Another value 2"]
 *   },
 * ]
 */
export const createCSV = (data) => {
  if (!data) {
    return null;
  }

  const isComplexTable = Array.isArray(data);

  if (isComplexTable ? !data.length : !Object.keys(data).length) {
    return null;
  }

  const headerRow = isComplexTable ? data[0] : data;
  const headers = Object.keys(headerRow);
  const rows = (isComplexTable ? data : [data])
    .map(row => headers.map(header => row[header]));

  const CSVData = [headers, ...rows];
  const CSV = CSVData
    .map(row => row.map(prepareCell).join(","))
    .join("\r\n");

  return CSV;
};
