import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { getSortRowsSorter, getSearchQuery, matchValue } from "../../../utility/table";

import "./complex_table.less";

import Cell from "./cell";
import ExpandableRow from "./expandable_row";
import Search from "../search";
import TableHeader from "./header";

export const EXPAND = Symbol();

export class ComplexTable extends PureComponent {
  static propTypes = {
    /**
     * Data shape supported:
     *
     * [
     *   {
     *     Label: "Value",
     *     "Another label": "Another value"
     *   },
     *   {
     *     Label: "Value",
     *     "Another label": ["Another value 1", "Another value 2"]
     *   },
     * ]
     */
    data: PropTypes.arrayOf(PropTypes.shape({})),
    location: PropTypes.shape({
      search: PropTypes.string
    }).isRequired,
    search: PropTypes.string, // If given, will be used instead of search query from URL (see docs for withSearch).
    searchPlaceholder: PropTypes.string.isRequired,
    /**
     * Given `true`: Will look for "search" param in the URL and use it as a query for row filtering and highlighting.
     * Given string: Will look for a given param in the URL and use it as a query for row filtering and highlighting.
     * Given falsy value (default): Search will be disabled in this table.
     * Note: Search will not work for non-stringifiable values, like React nodes.
     */
    withSearch: PropTypes.oneOfType([
      PropTypes.bool,
      PropTypes.string
    ])
  };

  static defaultProps = {
    searchPlaceholder: "Search…"
  };

  state = {
    sortBy: null,
    sortDir: null // 1 - asc, -1 - desc
  };

  onSortChange = (sortParams) => {
    this.setState(sortParams);
  }

  get headerRow() {
    const { data } = this.props;
    return data && data[0];
  }

  get headers() {
    const { headerRow } = this;
    return headerRow && Object.keys(headerRow).filter(headerRowKey => headerRowKey !== EXPAND);
  }

  get search() {
    const { location, withSearch, search } = this.props;

    return getSearchQuery({
      location,
      withSearch,
      search
    });
  }

  get sortRowsSorter() {
    const { sortBy, sortDir } = this.state;
    return getSortRowsSorter({
      sortBy,
      sortDir
    });
  }

  renderHeader() {
    const { headerRow, headers } = this;
    const { sortBy, sortDir } = this.state;

    return (
      <TableHeader
        headerRow={headerRow}
        headers={headers}
        onSortChange={this.onSortChange}
        sortBy={sortBy}
        sortDir={sortDir}
      />
    );
  }

  renderCell = (value, cellIndex) => (
    <Cell
      key={cellIndex}
      search={this.search}
      value={value}
    />
  );

  renderRow = (row, rowIndex) => {
    const { headers, search } = this;

    if (search) {
      const hasMatches = Object.values(row).some(value => matchValue(value, search));

      // Don't display a row if there's no matching value inside
      if (!hasMatches) {
        return null;
      }
    }

    return (
      <ExpandableRow
        key={rowIndex}
        rowIndex={rowIndex}
        expandChildren={row[EXPAND]}
      >
        {headers.map((header, cellIndex) => this.renderCell(row[header], cellIndex))}
      </ExpandableRow>
    );
  }

  renderRows = () => {
    const { data: rows } = this.props;

    if (!rows.length) {
      return null;
    }

    const renderedRows = rows
      .sort(this.sortRowsSorter)
      .map(this.renderRow)
      .filter(Boolean);

    return renderedRows;
  };

  render() {
    const { data, searchPlaceholder, withSearch } = this.props;

    if (!data || !data.length) {
      return null;
    }

    const rows = this.renderRows();

    return (
      <>
        {withSearch && (
          <Search placeholder={searchPlaceholder} />
        )}
        {rows && (
          <table className="ComplexTable">
            {this.renderHeader()}
            <tbody>
              {rows}
            </tbody>
          </table>
        )}
      </>
    );
  }
}

export default withRouter(ComplexTable);
