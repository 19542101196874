import { connect } from "react-redux";

// Selectors
import { getProduct } from "../../selectors/products";

// Components
import ViewSampleData from "../../components/product_details/view_sample_data";

import { post } from "../../helpers/network";

const requestSampleData = productName => post(
  "/request_new_resource",
  {
    body: {
      sample_data: { provider_name: productName }
    }
  }
);

export default connect(
  (store, ownProps) => {
    const { productId } = ownProps;

    return {
      product: getProduct(store, productId),
      requestSampleData,
      user: store.session.data && store.session.data.user
    };
  }
)(ViewSampleData);
