import React from "react";
import PropTypes from "prop-types";

import { isSortable } from "../../../utility/table";

export default function TableHeader({
  headerRow, headers, onSortChange, sortBy, sortDir
}) {
  function onClickHeader(header) {
    const sortParams = (() => {
      if (sortBy === header) {
        return {
          sortDir: -sortDir
        };
      } else {
        return {
          sortBy: header,
          sortDir: 1
        };
      }
    })();

    onSortChange(sortParams);
  }

  return (
    <thead>
      <tr>
        {headers.map((header) => {
          const isHeaderSortable = isSortable(headerRow[header]);
          const shouldBeHidden = header === "Actions";

          return (
            <th key={header} className={shouldBeHidden ? "hidden-header" : ""}>
              {isHeaderSortable ? (
                <button
                  type="button"
                  onClick={() => onClickHeader(header)}
                  title={`Sort table by: ${header}`}
                >
                  {header} {sortBy === header ? (sortDir === 1 ? "▲" : "▼") : ""}
                </button>
              ) : header}
            </th>
          );
        })}
      </tr>
    </thead>
  );
}

const isHeader = PropTypes.string;
const isRow = PropTypes.object;

TableHeader.propTypes = {
  headerRow: isRow,
  headers: PropTypes.arrayOf(isHeader),
  onSortChange: PropTypes.func.isRequired,
  sortBy: isHeader,
  sortDir: PropTypes.oneOf([-1, 1])
};
