import { connect } from "react-redux";

// Selectors
import { getProduct } from "../../selectors/products";

// Components
import RunSingleLookUp from "../../components/product_details/run_single_look_up";

export default connect(
  (store, ownProps) => {
    const { productId } = ownProps;

    return {
      product: getProduct(store, productId)
    };
  }
)(RunSingleLookUp);
