import React from "react";
import PropTypes from "prop-types";

import ComplexTable from "./complex_table";
import SimpleTable from "./simple_table";

export { EXPAND } from "./complex_table";

export default function Table(props) {
  const { data } = props;

  if (!data) {
    return null;
  }

  const isComplexTable = Array.isArray(data);

  return (
    isComplexTable
      ? <ComplexTable {...props} />
      : <SimpleTable {...props} />
  );
}

Table.propTypes = {
  /**
   * There are two data shapes supported by child components.
   *
   * 1.
   * {
   *   Label: "Value",
   *   "Another label": ["Another value 1", "Another value 2"]
   * }
   *
   * 2.
   *
   * [
   *   {
   *     Label: "Value",
   *     "Another label": "Another value"
   *   },
   *   {
   *     Label: "Value",
   *     "Another label": ["Another value 1", "Another value 2"]
   *   },
   * ]
   */
  data: PropTypes.oneOfType([
    PropTypes.shape({}),
    PropTypes.arrayOf(PropTypes.shape({}))
  ])
};
