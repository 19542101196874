import React from "react";
import { Route, Switch } from "react-router-dom";

import "./products.less";

import Catalog from "./catalog/catalog";
import Header from "./header/header";
import ProductDetails from "../containers/product_details/product_details";
import ProductSubpages from "../containers/product_subpages";
import Head from "./head";

export default function Products() {
  return (
    <div>
      <Switch>
        <Route path="/products/:id?" exact component={Header} />
        <Route path="/products/:id" exact component={Header} />
      </Switch>
      <div className="Products">
        <Route path="/products/:id?" exact component={Catalog} />
        <Route path="/products/:id" exact component={ProductDetails} />
        <Route path="/products/:id/(.+)" component={ProductSubpages} />
      </div>
      <Head
        title="Data Catalog"
        description="Explore Demyst's highly secure and compliant library of over 500 data products. We vet each source so that you can confidently utilize external data."
      />
    </div>
  );
}
