import React, { Component } from "react";
import PropTypes from "prop-types";
import Fit from "react-fit";
import classNames from "classnames";

import "./input_with_suggestions.less";

export default class InputWithSuggestions extends Component {
  static propTypes = {
    children: PropTypes.node,
    getSuggestions: PropTypes.func.isRequired,
    invertAxis: PropTypes.bool,
    invertSecondaryAxis: PropTypes.bool
  };

  state = {
    isOpen: null
  };

  componentWillUnmount() {
    document.removeEventListener("mousedown", this.onOutsideAction);
    document.removeEventListener("focusin", this.onOutsideAction);
    document.addEventListener("keydown", this.onEnterAction);
  }

  onToggle = () => {
    const { isOpen } = this.state;

    if (isOpen) {
      document.addEventListener("mousedown", this.onOutsideAction);
      document.addEventListener("focusin", this.onOutsideAction);
      document.addEventListener("keydown", this.onEnterAction);
    } else {
      document.removeEventListener("mousedown", this.onOutsideAction);
      document.removeEventListener("focusin", this.onOutsideAction);
      document.removeEventListener("keydown", this.onEnterAction);
    }
  }

  onOutsideAction = (event) => {
    if (this.wrapper && !this.wrapper.contains(event.target)) {
      this.closeDropdown();
    }
  }

  onEnterAction = (event) => {
    const { key } = event;
    if (this.wrapper && key === "Enter") {
      setTimeout(() => {
        this.closeDropdown();
        this.wrapper.querySelector("input[type='search']").blur();
      }, 0);
    }
  }

  onInsideAction = (event) => {
    if (this.label && !this.label.contains(event.target) && this.label !== event.target) {
      setTimeout(this.closeDropdown, 0);
    }
  }

  onChange = (event, onChange) => {
    if (onChange) {
      onChange(event);
    }

    this.setState({ value: event.target.value });
  };

  openDropdown = () => this.setState((prevState) => {
    if (prevState.isOpen) {
      return null;
    }

    return { isOpen: true };
  }, this.onToggle);

  closeDropdown = () => this.setState((prevState) => {
    if (!prevState.isOpen) {
      return null;
    }

    return { isOpen: false };
  }, this.onToggle);

  renderItems() {
    const { invertAxis, invertSecondaryAxis, getSuggestions } = this.props;
    const { isOpen, value } = this.state;

    if (isOpen === null) {
      return null;
    }

    const items = getSuggestions(value);

    if (!items) {
      return null;
    }

    return (
      <Fit invertAxis={invertAxis} invertSecondaryAxis={invertSecondaryAxis}>
        <div
          className="InputWithSuggestions__items"
          ref={(ref) => {
            if (!ref) {
              return;
            }

            ref.style.height = 0;
            ref.style.height = `${ref.scrollHeight}px`;
          }}
        >
          {items}
        </div>
      </Fit>
    );
  }

  render() {
    const { children } = this.props;
    const { isOpen } = this.state;

    const child = React.Children.only(children);

    return (
      /**
       * This onClick is okay, because we don't listen for clicking on a <div> (which is a wrapper),
       * but we catch bubbling onClick events coming from valid buttons and anchors.
       */
      // eslint-disable-next-line jsx-a11y/no-static-element-interactions
      <div
        className={classNames("InputWithSuggestions", isOpen ? "InputWithSuggestions--open" : "InputWithSuggestions--closed")}
        onClick={this.onInsideAction}
        ref={(ref) => {
          if (!ref) {
            return;
          }

          this.wrapper = ref;
        }}
      >
        {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions */}
        <span
          onChange={event => this.onChange(event, child.props.onChange)}
          onFocus={this.openDropdown}
          ref={(ref) => {
            if (!ref) {
              return;
            }

            this.label = ref;
          }}
        >
          {child}
        </span>
        {this.renderItems()}
      </div>
    );
  }
}
