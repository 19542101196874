/**
 * Container elements is used for even spacing between children elements. It works by applying
 * negative margins to itself and positive margin to all its children.
 *
 * Container is useless if provided with only one child.
 */

import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

import "./container.less";

export default function Container({
  align,
  allowWrap,
  children,
  className,
  direction,
  verticalAlign
}) {
  return (
    <div
      className={classNames(
        "Container",
        {
          [`Container--${align}`]: align,
          [`Container--vertical-${verticalAlign}`]: verticalAlign,
          [`Container--${direction}`]: direction,
          "Container--allowWrap": allowWrap
        },
        className
      )}
    >
      {children}
    </div>
  );
}

const isAlign = PropTypes.oneOf(["center", "left", "right"]);
const isVerticalAlign = PropTypes.oneOf(["bottom", "center", "top"]);

Container.propTypes = {
  align: isAlign,
  allowWrap: PropTypes.bool,
  children: PropTypes.node,
  className: PropTypes.string,
  direction: PropTypes.oneOf(["row", "row-reverse", "column", "column-reverse"]),
  verticalAlign: isVerticalAlign
};
