import { withRouter } from "react-router-dom";

// Actions

// Selectors

// Components
import Tags from "../../components/product_details/tags";

export default withRouter(Tags);
