import React from "react";
import PropTypes from "prop-types";

import "./timeline.less";

export default function Timeline({ children }) {
  return (
    <div className="Timeline">
      <ul className="Timeline__content">{children}</ul>
    </div>
  );
}

Timeline.propTypes = {
  children: PropTypes.node.isRequired
};

export { default as TimelineEvent } from "./timeline_event";
