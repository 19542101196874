import { deepFlat } from "./helpers";

export const flattenOutputField = (outputField, namePrefix = "") => {
  const nameWithPrefix = namePrefix ? `${namePrefix}.${outputField.name}` : outputField.name;

  const outputFieldWithPrefix = {
    ...outputField,
    name: nameWithPrefix
  };

  if (outputField.children) {
    const childNamePrefix = (() => {
      switch (outputField.type) {
        case "List":
          return `${nameWithPrefix}[*]`;
        default:
          return nameWithPrefix;
      }
    })();

    return [
      outputFieldWithPrefix,
      ...outputField.children.map(child => flattenOutputField(child, childNamePrefix))
    ];
  }

  return outputFieldWithPrefix;
};

export const flattenOutputFields = outputFields => deepFlat(
  outputFields.map(outputField => flattenOutputField(outputField))
);
