import { connect } from "react-redux";

// Actions
import { fetchProduct } from "../../actions/products";

// Selectors
import { getProduct } from "../../selectors/products";
import { getCurrentUser, isDemystUser } from "../../selectors/session";

// Components
import ProductDetails from "../../components/product_details/product_details";

// Helpers
import { getIdFromParams } from "../../utility/helpers";

export default connect(
  (store, ownProps) => {
    const productId = getIdFromParams(ownProps.match.params);

    if (!productId) {
      return {};
    }

    return {
      isDemystUser: isDemystUser(store),
      isLoggedIn: Boolean(getCurrentUser(store)),
      product: getProduct(store, productId),
      productId
    };
  },
  {
    fetchProduct
  }
)(ProductDetails);
