import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

import "./view_sample_data.less";

import AuthSection from "../../containers/auth_section";

import { getProductUrlId } from "../../utility/helpers";
import { isProduct } from "../../utility/prop-types";

export default function RunSingleLookUp({ product }) {
  return (
    <AuthSection title="single lookup">
      <Link
        className="RunSingleLookUp button mdb"
        to={`/products/${getProductUrlId(product)}/single_lookup`}
      >
        Run Single Lookup
      </Link>
    </AuthSection>
  );
}

RunSingleLookUp.propTypes = {
  product: isProduct,
  productId: PropTypes.number.isRequired // eslint-disable-line react/no-unused-prop-types
};
