import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

import "./tags.less";

import { FILTER_TYPES } from "../../utility/constants";
import { uniq } from "../../utility/helpers";
import { isLocation } from "../../utility/prop-types";
import { addToQuery } from "../../../../stingray/frontend/utility/url";
import { useIsMobile } from "../../utility/hooks";

const getLocation = (location, tag, isMobile) => {
  const lowerCaseTag = tag.toLowerCase();
  const newQuery = addToQuery(location.search, FILTER_TYPES.SEARCH, lowerCaseTag);

  return {
    ...location,
    pathname: isMobile ? "/products" : location.pathname,
    search: newQuery
  };
};

function TagsItem({ isPlaceholder, location, tag }) {
  const isMobile = useIsMobile();

  return (
    <li>
      {isPlaceholder
        ? (
          <button type="button" disabled>
            {tag}
          </button>
        )
        : (
          <Link to={getLocation(location, tag, isMobile)}>
            {tag}
          </Link>
        )
      }
    </li>
  );
}

TagsItem.propTypes = {
  isPlaceholder: PropTypes.bool,
  location: isLocation.isRequired,
  tag: PropTypes.string
};

export default function Tags({ isPlaceholder, location, tags }) {
  if (!tags) {
    return null;
  }

  const uniqueTags = uniq(tags);

  return (
    <div className="Tags">
      <ul>
        {uniqueTags.map(tag => (
          <TagsItem
            key={tag}
            isPlaceholder={isPlaceholder}
            location={location}
            tag={tag}
          />
        ))}
      </ul>
    </div>
  );
}

Tags.propTypes = {
  isPlaceholder: PropTypes.bool,
  location: isLocation.isRequired,
  tags: PropTypes.arrayOf(PropTypes.string)
};
