import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import HeaderLogo from "../../components/header/header_logo";

export default withRouter(
  connect(store => ({
    isFetching: store.session.isFetching,
    user: store.session.data && store.session.data.user
  }))(HeaderLogo)
);
