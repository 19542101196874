import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

import { isLocation, isUser } from "../../utility/prop-types";

import "./topbar.less";

export default function TopBar({ isFetching, location, user }) {
  if (isFetching || user) {
    return null;
  }

  return (
    <div>
      <div className="TopBar">
        <div className="TopBar__content">
          <a className="TopBar__link" href="mailto:support@demystdata.com">
            Support
          </a>
          <Link
            className="TopBar__link Authentication__login link-button"
            to={{
              pathname: "/users/sign_in",
              state: {
                locationAfterLogin: "/products",
              },
            }}
          >
            Sign In
          </Link>
        </div>
      </div>
    </div>
  );
}

TopBar.propTypes = {
  isFetching: PropTypes.bool,
  location: isLocation.isRequired,
  user: isUser,
};
