import React, { useState } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { parse } from "query-string";

import "./header_with_filters.less";

import ClearFilterButton from "../../containers/catalog/clear_filter_button";
import Filters from "./filters/filters";
import FilterChips from "../../containers/catalog/filters/filter_chips";

import { isLocation, isProduct } from "../../utility/prop-types";

export default function HeaderWithFilters({ location, products }) {
  const [isFilterOpen, setFilterOpen] = useState(false);
  const { search } = parse(location.search);
  const isSearchMode = Boolean(search);

  return (
    <div className="HeaderWithFilters">
      <div className="HeaderWithFilters__header">
        <ul className="HeaderWithFilters__tabs" role="tablist">
          {isSearchMode ? (
            <li role="tab" aria-selected="true">
              Search Results{products ? ` (${products.length})` : ""}
            </li>
          ) : null}
          <li role="tab" aria-selected={!isSearchMode}>
            <Link
              to={{
                ...location,
                search: ""
              }}
            >
              All Products
            </Link>
          </li>
        </ul>
        <ClearFilterButton />
        <button
          type="button"
          className="HeaderWithFilters__toggleButton"
          onClick={() => setFilterOpen(!isFilterOpen)}
        >
          Sort | Filter
          {" "}
          <span className="HeaderWithFilters__toggleButton__arrow">
            {isFilterOpen ? "▲" : "▼"}
          </span>
        </button>
      </div>
      {isFilterOpen && <Filters />}
      <FilterChips />
    </div>
  );
}

HeaderWithFilters.propTypes = {
  location: isLocation.isRequired,
  products: PropTypes.arrayOf(isProduct)
};
