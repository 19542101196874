import { connect } from "react-redux";

// Actions
import { fetchProduct } from "../actions/products";

// Selectors
import { getProduct } from "../selectors/products";

// Components
import ProductSubpages from "../components/product_subpages";

// Helpers
import { getIdFromParams } from "../utility/helpers";

import { isDemystUser } from "../selectors/session";

export default connect(
  (store, ownProps) => {
    const productId = getIdFromParams(ownProps.match.params);

    if (!productId) {
      return {};
    }

    return {
      product: getProduct(store, productId),
      productId,
      isDemystUser: isDemystUser(store)
    };
  },
  {
    fetchProduct
  }
)(ProductSubpages);

