const isValidDate = date => !isNaN(date.getTime());

export const toLocaleDateString = (dateString, options = {}) => {
  if (!dateString) {
    return dateString;
  }
  const date = new Date(dateString);
  if (!isValidDate(date)) {
    return dateString;
  }

  if (!options.timeZone) {
    options.timeZone = "UTC";
  }

  return date.toLocaleDateString("en-US", options);
};

export const toLocaleTimeString = (dateString, options = {}) => {
  if (!dateString) {
    return dateString;
  }
  const date = new Date(dateString);
  if (!isValidDate(date)) {
    return dateString;
  }

  if (!options.timeZone) {
    options.timeZone = "UTC";
  }

  return date.toLocaleTimeString("en-US", options);
};

export const toLocaleString = (dateString, options = {}) => {
  if (!dateString) {
    return dateString;
  }
  const date = new Date(dateString);
  if (!isValidDate(date)) {
    return dateString;
  }

  if (!options.timeZone) {
    options.timeZone = "UTC";
  }

  return date.toLocaleString("en-US", options);
};

export const humanizedDate = dateString => (
  toLocaleDateString(dateString, {
    month: "short",
    day: "numeric",
    year: "numeric"
  })
);

export const humanizedTime = dateString =>
  toLocaleTimeString(dateString, {
    hour: "numeric",
    minute: "numeric",
    timeZoneName: "short"
  });
