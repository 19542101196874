import React from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { parse } from "query-string";
import { replaceInQuery } from "../../utility/url";

const updateHistory = (history, location, newPathname, key, value) => {
  const newSearch = replaceInQuery(location.search, key, value);

  if (newPathname && newPathname !== location.pathname) {
    history.push({
      ...location,
      hash: null,
      pathname: newPathname,
      search: newSearch,
      state: {
        redirectUsingSearch: true
      }
    });
  } else {
    const newState = (() => {
      if (!location.state) {
        return undefined;
      }

      const keys = Object.keys(location.state)
        .filter(key => key !== "redirectUsingSearch");

      if (!keys.length) {
        return undefined;
      }

      return keys.reduce((resultObj, key) => ({
        ...resultObj,
        [key]: location.state[key]
      }), {});
    })();

    history.replace({
      ...location,
      hash: null,
      search: newSearch,
      state: newState
    });
  }
};

export const Search = ({
  big,
  history,
  location,
  onChange,
  pathname,
  placeholder,
  value
}) => {
  const urlParams = parse(
    (!pathname || pathname === location.pathname)
      ? location.search
      : null
  );
  const { search } = urlParams;

  return (
    <input
      type="search"
      name="search"
      autoComplete="off"
      className={big ? "big" : null}
      onChange={onChange || (event => updateHistory(history, location, pathname, "search", event.target.value))}
      placeholder={placeholder}
      ref={(ref) => {
        if (!ref) {
          return;
        }

        if (location.state && location.state.redirectUsingSearch) {
          const fldLength = ref.value.length;
          ref.focus();
          ref.setSelectionRange(fldLength, fldLength);
        }
      }}
      value={value || search || ""}
    />
  );
};

Search.propTypes = {
  big: PropTypes.bool,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
    replace: PropTypes.func.isRequired
  }).isRequired,
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
    search: PropTypes.string,
    state: PropTypes.object
  }).isRequired,
  onChange: PropTypes.func,
  pathname: PropTypes.string,
  placeholder: PropTypes.string,
  value: PropTypes.string
};

Search.defaultProps = {
  placeholder: "Search…"
};

export default withRouter(Search);
