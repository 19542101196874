import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

// Actions
import { fetchUseCases } from "../../actions/use_cases";

// Selectors
import {
  getUseCases,
  getUseCasesError
} from "../../selectors/use_cases";

// Components
import UseCases from "../../components/use_cases/use_cases";

export default withRouter(
  connect(
    store => ({
      error: getUseCasesError(store),
      useCases: getUseCases(store)
    }),
    { fetchUseCases }
  )(UseCases)
);
