import { get } from "../helpers/network";

export const GET_TAGS_REQUEST = "GET_TAGS_REQUEST";
export const GET_TAGS_SUCCESS = "GET_TAGS_SUCCESS";
export const GET_TAGS_ERROR = "GET_TAGS_ERROR";

export const fetchTags = () => async (dispatch, getState) => {
  const store = getState();

  if (store.tags.isFetching || store.tags.data) {
    // Request for (somewhat) static data - no need for multiple requests
    return store.tags.data;
  }

  dispatch({ type: GET_TAGS_REQUEST });

  try {
    const tags = await get("/public/tags");
    dispatch({
      type: GET_TAGS_SUCCESS,
      data: tags
    });
    return tags;
  } catch (error) {
    dispatch({
      type: GET_TAGS_ERROR,
      error
    });
    throw error;
  }
};
