import {
  GET_SESSION_REQUEST,
  GET_SESSION_SUCCESS,
  GET_SESSION_ERROR,
  LOGIN_REQUEST,
  LOGIN_SUCCESS,
  LOGIN_ERROR,
  LOGOUT_REQUEST,
  LOGOUT_SUCCESS,
  LOGOUT_ERROR,
  SESSION_EXPIRED
} from "../actions/session";

const defaultState = {
  isFetching: false,
  fetchedAt: null,
  error: null,
  data: null
};

export default (state = defaultState, action) => {
  switch (action.type) {
    case GET_SESSION_REQUEST:
    case LOGIN_REQUEST:
    case LOGOUT_REQUEST: {
      return {
        ...state,
        isFetching: true
      };
    }
    case GET_SESSION_SUCCESS:
    case LOGIN_SUCCESS: {
      const { organization, user } = action;

      return {
        ...state,
        isFetching: false,
        fetchedAt: new Date(),
        error: null,
        data: {
          ...state.data,
          organization,
          user
        }
      };
    }
    case LOGOUT_SUCCESS: {
      return {
        ...state,
        isFetching: false,
        fetchedAt: new Date(),
        error: null,
        data: null
      };
    }
    case GET_SESSION_ERROR: {
      const { error } = action;

      // We treat error in getting a session like a valid response
      return {
        ...state,
        isFetching: false,
        fetchedAt: new Date(),
        error,
        data: null
      };
    }
    case LOGIN_ERROR:
    case LOGOUT_ERROR: {
      const { error } = action;

      return {
        ...state,
        isFetching: false,
        error
      };
    }

    case SESSION_EXPIRED: {
      if (!state.data) {
        // No session data - nothing to reset
        return state;
      }

      return {
        ...state,
        isFetching: false,
        fetchedAt: new Date(),
        error: null,
        data: null
      };
    }
    default: return state;
  }
};
