import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

// Actions
import {
  fetchUseCaseTableProviders
} from "../../actions/use_cases";

// Selectors
import {
  getUseCaseProviders,
  isFetchingUseCaseProviders
} from "../../selectors/use_cases";

// Components
import DataProducts from "../../components/use_case/data_products";

// Helpers
import { getIdFromParams } from "../../utility/helpers";

export default withRouter(connect(
  (store, ownProps) => {
    const useCaseId = getIdFromParams(ownProps.match.params);

    if (!useCaseId) {
      return {};
    }

    return {
      dataProducts: getUseCaseProviders(store, useCaseId),
      isFetching: isFetchingUseCaseProviders(store),
      useCaseId
    };
  },
  {
    fetchUseCaseTableProviders
  }
)(DataProducts));
