import React, { useEffect, useRef } from "react";
import PropTypes from "prop-types";
import classNames from "merge-class-names";
import { withRouter } from "react-router-dom";

import Article from "./article";

import "./product_details_section.less";

import { isLocation } from "../../utility/prop-types";

export function ProductDetailsSection({
  articles, children, display, header, id, location
}) {
  const subContentClassname = classNames("ProductDetailsSection", `ProductDetailsSection--${display}`);

  const container = useRef(null);
  useEffect(() => {
    if (location.hash && id === location.hash.slice(1)) {
      container && container.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [location.hash, id]);

  return (
    <section
      id={id}
      className={subContentClassname}
      ref={container}
    >
      <header className="ProductDetailsSection__header">
        <h3>
          {header}
        </h3>
      </header>
      <div className="ProductDetailsSection__articles">
        {children}
        {articles && articles.map(article => (
          <Article
            display={display}
            key={article.header}
            {...article}
          />
        ))}
      </div>
    </section>
  );
}

const isArticle = PropTypes.shape({
  actions: PropTypes.node,
  header: PropTypes.string,
  img: PropTypes.node,
  subHeader: PropTypes.string,
  text: PropTypes.node
});

ProductDetailsSection.propTypes = {
  articles: PropTypes.arrayOf(isArticle),
  children: PropTypes.node,
  display: PropTypes.oneOf(["rows", "columns"]),
  header: PropTypes.string,
  id: PropTypes.string,
  location: isLocation.isRequired
};

ProductDetailsSection.defaultProps = {
  display: "rows"
};

export default withRouter(ProductDetailsSection);
