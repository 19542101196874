import { connect } from "react-redux";

// Actions
import { fetchUseCase } from "../../actions/use_cases";

// Selectors
import { getUseCase } from "../../selectors/use_cases";

// Components
import UseCase from "../../components/use_case/use_case";

// Helpers
import { getIdFromParams } from "../../utility/helpers";

export default connect(
  (store, ownProps) => {
    const useCaseId = getIdFromParams(ownProps.match.params);

    if (!useCaseId) {
      return {};
    }

    return {
      useCase: getUseCase(store, useCaseId),
      useCaseId
    };
  },
  {
    fetchUseCase
  }
)(UseCase);
