import React from "react";
import PropTypes from "prop-types";

const CloseIcon = ({ color = "#616161", ...props }) => (
  <svg width="22" height="22" viewBox="0 0 22 22" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g stroke={color} strokeWidth="2">
      <line x1="3" y1="3" x2="19" y2="19" />
      <line x1="19" y1="3" x2="3" y2="19" />
    </g>
  </svg>
);

CloseIcon.propTypes = {
  color: PropTypes.string
};

export default CloseIcon;
