import React from "react";
import { createPortal } from "react-dom";
import PropTypes from "prop-types";
import classNames from "classnames";

import "./popup.less";

import BackIcon from "../../assets/svgs/backIcon";
import CloseIcon from "../../assets/svgs/closeIcon";

const root = (() => {
  let el = document.getElementById("react-modal-container");

  if (!el) {
    el = document.createElement("div");
    el.id = "react-modal-container";
    document.body.appendChild(el);
  }

  return el;
})();

/* eslint-disable jsx-a11y/no-static-element-interactions, jsx-a11y/click-events-have-key-events */

const Popup = ({
  children,
  className,
  contentClassName,
  footer,
  isOpen,
  onClose,
  onClickBack,
  showBackButton,
  showCloseButton,
  title
}) => (
  createPortal(
    <div className={classNames("Popup", { "Popup--open": isOpen }, className)}>
      <div
        className="Popup__background"
        onClick={onClose}
      />
      <dialog open className="Popup__container">
        <div className="Popup__header">
          <div className="Popup__header__back">
            {showBackButton && (
              <button
                type="button"
                onClick={onClickBack || onClose}
                title="Back"
              >
                <BackIcon />
              </button>
            )}
          </div>
          {title && <h2>{title}</h2>}
          <div className="Popup__header__close">
            {showCloseButton && (
              <button
                type="button"
                onClick={onClose}
                title="Close popup"
              >
                <CloseIcon />
              </button>
            )}
          </div>
        </div>
        <div className={classNames("Popup__content", contentClassName)}>
          {children}
        </div>
        {footer && (
          <div className="Popup__footer">
            {footer}
          </div>
        )}
      </dialog>
    </div>,
    root,
  )
);

Popup.defaultProps = {
  showBackButton: false,
  showCloseButton: true
};

Popup.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  contentClassName: PropTypes.string,
  footer: PropTypes.node,
  isOpen: PropTypes.bool,
  onClickBack: PropTypes.func,
  onClose: PropTypes.func.isRequired,
  showBackButton: PropTypes.bool,
  showCloseButton: PropTypes.bool,
  title: PropTypes.string
};

export default Popup;
