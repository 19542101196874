import React from "react";
import PropTypes from "prop-types";

import Placeholder from "../../placeholders/placeholder";
import { UserAvatar } from "../ui/user_avatar";

export default function TeamMember({ isPlaceholder, name }) {
  return (
    <Placeholder isEnabled={isPlaceholder} theme="dark">
      <li>
        <UserAvatar user={{ name }} />
        <span>{name}</span>
      </li>
    </Placeholder>
  );
}

TeamMember.propTypes = {
  isPlaceholder: PropTypes.bool,
  name: PropTypes.string.isRequired
};
