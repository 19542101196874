import React, { lazy, useEffect, Suspense } from "react";
import PropTypes from "prop-types";
import { Route, Switch } from "react-router-dom";

import "./product_subpages.less";

import AuthRoute from "../containers/auth_route";
import Header from "./catalog/product_header";
import HeaderPlaceholder from "../placeholders/catalog/header";
import ProductNavigation from "../containers/catalog/product_navigation";
import Spinner from "../../../stingray/frontend/components/ui/spinner";
import Head from "./head";

import { getProductAliasOrName, getProductUrlId } from "../utility/helpers";
import { isProduct } from "../utility/prop-types";

// Asynchronously loaded routes
const DataDictionaryViewer = lazy(() =>
  import("../containers/data_dictionary_viewer/data_dictionary_viewer")
);
const ProductInfo = lazy(() =>
  import("../containers/product_info/product_info")
);
const SampleDataViewer = lazy(() =>
  import("../containers/sample_data_viewer/sample_data_viewer")
);
const SingleLookup = lazy(() =>
  import("../containers/single_lookup/single_lookup")
);

export default function ProductSubpages({
  fetchProduct,
  product,
  productId,
  isDemystUser,
}) {
  useEffect(() => {
    fetchProduct(productId);
  }, [fetchProduct, productId]);

  function renderHeader() {
    if (!product) {
      return <HeaderPlaceholder />;
    }

    const productNaturalName = getProductAliasOrName(product);
    const canonicalUrl = `https://demyst.com/products/${getProductUrlId(
      product
    )}`;
    const title = `${productNaturalName} | Data Catalog`;

    return (
      <>
        <Header product={product} />
        <Head
          canonicalUrl={canonicalUrl}
          description={product.description}
          title={title}
        />
      </>
    );
  }

  return (
    <div className="ProductSubpages">
      <ProductNavigation />
      {renderHeader()}
      <Suspense fallback={<Spinner big multicolor />}>
        <Switch>
          <Route path="/products/:id/info" component={ProductInfo} />
          <AuthRoute
            path="/products/:id/data_dictionary"
            component={DataDictionaryViewer}
          />
          {isDemystUser && (
            <>
              <AuthRoute
                path="/products/:id/single_lookup"
                component={SingleLookup}
              />
            </>
          )}
          <AuthRoute
            path="/products/:id/sample_data"
            component={SampleDataViewer}
          />
        </Switch>
      </Suspense>
    </div>
  );
}

ProductSubpages.propTypes = {
  fetchProduct: PropTypes.func.isRequired,
  product: isProduct,
  productId: PropTypes.number.isRequired,
};
