import React, { useState } from "react";

import Navigation from "../../containers/header/navigation";
import TopBar from "../../containers/header/topbar";
import HeaderLogo from "../../containers/header/header_logo";

import MenuIcon from "../../static/icons/menu_icon.svg";
import MenuIconOpen from "../../static/icons/menu_icon_open.svg";

import "./header.less";

export default function Header() {
  const [isMenuOpen, setMenuOpen] = useState(null);

  return (
    <header className="Header">
      <div className="Header__flexContainer">
        <HeaderLogo />
        <button
          type="button"
          className="Header__menuButton"
          onClick={() => setMenuOpen(!isMenuOpen)}
        >
          {isMenuOpen ? (
            <img
              className="Header__menuButton__menuIconOpen"
              src={MenuIconOpen}
            />
          ) : (
            <img className="Header__menuButton__menuIcon" src={MenuIcon} />
          )}
        </button>
        <Navigation isOpen={isMenuOpen} />
      </div>
    </header>
  );
}
