import React from "react";
import PropTypes from "prop-types";

import Container from "../../../../stingray/frontend/components/ui/container";

export default function Article({
  actions,
  display,
  header,
  img,
  subHeader,
  text
}) {
  return (
    <article className="Article">
      <div className="Article__image">
        {img}
      </div>
      <div className="Article__contentWithButton">
        <div className="Article__contentWithButton__content">
          <h3>{header}</h3>
          {subHeader && (
            <p className="Article__subHeader">{subHeader}</p>
          )}
          {text}
        </div>
        <div className="Article__contentWithButton__button">
          <Container align={display === "rows" ? "left" : "center"} allowWrap>
            {actions}
          </Container>
        </div>
      </div>
    </article>
  );
}

Article.propTypes = {
  actions: PropTypes.node,
  display: PropTypes.oneOf(["rows", "columns"]),
  header: PropTypes.string,
  img: PropTypes.node,
  subHeader: PropTypes.string,
  text: PropTypes.node
};
