import React, { Component } from "react";
import PropTypes from "prop-types";

import DownloadIcon from "../../../../stingray/frontend/assets/svgs/download";

import { isProduct, isUser } from "../../utility/prop-types";

import "./download_sample_data.less";

export default class DownloadSampleData extends Component {
  static propTypes = {
    className: PropTypes.string,
    fetchSampleData: PropTypes.func.isRequired,
    icon: PropTypes.element,
    isFetching: PropTypes.bool,
    product: isProduct,
    productId: PropTypes.number.isRequired,
    sampleData: PropTypes.shape({}),
    user: isUser
  };

  static defaultProps = {
    className: "DownloadSampleData button link-button-inline",
    icon: <DownloadIcon color="#00acc1" />
  };

  componentDidMount() {
    if (this.shouldDownloadSampleData) {
      this.fetchSampleData();
    }
  }

  componentDidUpdate() {
    const { isFetching, sampleData } = this.props;

    if (!isFetching && !sampleData && this.shouldDownloadSampleData) {
      this.fetchSampleData();
    }
  }

  get shouldDownloadSampleData() {
    const { product, user } = this.props;

    return (
      user && user.permissions && user.permissions.can_download_sample_data
      && product && product.sample_data_preview
    );
  }

  fetchSampleData = () => {
    const { fetchSampleData, productId } = this.props;

    fetchSampleData(productId);
  }

  render() {
    const {
      className,
      icon,
      isFetching,
      sampleData,
      user
    } = this.props;

    if (!user || isFetching || !sampleData) {
      return null;
    }

    const { name = "#", url } = sampleData || {};

    return (
      <a
        href={url}
        download={name}
        className={className}
      >
        {icon}
        <span>Download CSV</span>
      </a>
    );
  }
}
