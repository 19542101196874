import { createSelector } from "reselect";

const getEntities = store => store.use_cases.entities;
const getResults = store => store.use_cases.results;

export const getUseCase = createSelector(
  getEntities,
  (store, useCaseId) => useCaseId,
  (entities, useCaseId) => entities[useCaseId]
);

export const getUseCases = createSelector(
  getEntities,
  getResults,
  (entities, results) => results[JSON.stringify({})]?.data?.map(
      useCaseId => entities[useCaseId]
    )
);

export const getUseCasesError = createSelector(
  getEntities,
  getResults,
  (entities, results) => results[JSON.stringify({})]?.error
);

export const getUseCaseProviders = createSelector(
  store => store.use_case_data_products.data,
  (store, useCaseId) => useCaseId,
  (dataProducts, useCaseId) => {
    if (!dataProducts) {
      return null;
    }
    return dataProducts[useCaseId];
  }
);

export const isFetchingUseCaseProviders = store => store.use_case_data_products.isFetching;

export const getUseCaseEvents = createSelector(
  store => store.use_case_events.data,
  (store, useCaseId) => useCaseId,
  (events, useCaseId) => {
    if (!events) {
      return null;
    }
    return events[useCaseId];
  }
);

export const isFetchingUseCaseEvents = store => store.use_case_events.isFetching;

export const getUseCaseUsers = createSelector(
  store => store.use_case_users.data,
  (store, useCaseId) => useCaseId,
  (users, useCaseId) => {
    if (!users) {
      return null;
    }
    return users[useCaseId];
  }
);

export const isFetchingUseCaseUsers = store =>
  store.use_case_users.isFetching;

export const getUseCaseModels = createSelector(
  store => store.use_case_models.data,
  (store, useCaseId) => useCaseId,
  (models, useCaseId) => {
    if (!models) {
      return null;
    }
    return models[useCaseId];
  }
);

export const isFetchingUseCaseModels = store =>
  store.use_case_models.isFetching;

export const getUseCaseModelsByType = createSelector(
  getUseCaseModels,
  (models) => {
    if (!models) {
      return null;
    }
    return models.reduce(
      (acc, val) => ({
        ...acc,
        [val.model_type]: [...(acc[val.model_type] || []), val.model]
      }),
      {}
    );
  }
);

export const getUseCaseInputFiles = createSelector(
  getUseCaseModelsByType,
  (models) => {
    if (!models) {
      return null;
    }
    return models?.AegeanBatchInput;
  }
);

export const isFetchingUseCaseDataServiceJobs = store => store.use_case_data_service_jobs.isFetching;

export const getUseCaseDataServiceJobs = createSelector(
  store => store.use_case_data_service_jobs.data,
  (store, useCaseId) => useCaseId,
  (jobs, useCaseId) => {
    if (!jobs) {
      return null;
    }
    return jobs[useCaseId];
  }
);
