import { deepFlat, formatAsPercent, uniqBy } from "./helpers";
import { flattenOutputFields } from "./output_fields";

/**
 * Surfaces nested arrays to root
 * [ [ [a], [ [b], [c] ] ], [d] ] =>  [ [a], [b], [c], [d] ]
 */
export const formatRequiredInputsIntoSets = arr => arr.reduce((acc, val) => {
  if (Array.isArray(val)) {
    return Array.isArray(val[0]) ? [...acc, ...formatRequiredInputsIntoSets(val)] : [...acc, val];
  }
  return [...acc, val];
}, []);

// Alias. Easier to change if output requirements change
export const formatRequiredOutputsIntoSets = formatRequiredInputsIntoSets;

// uniq 2D array: [[1,2], [1,3], [1,3]] => [[1,2], [1,3]]
// Does not work N levels deep. Very useful for de-duping input sets
export const removeDuplicatesFromNestedArray = (arr) => {
  const map = {};
  return arr.reduce((acc, val) => {
    const stringifiedArray = JSON.stringify(val);
    if (!map[stringifiedArray]) {
      map[stringifiedArray] = true;
      return [...acc, val];
    }
    return acc;
  }, []);
};

export const getUniqElementsFromSetsByProperty = (sets, prop) => uniqBy(deepFlat(sets), prop);

export const getProductAttributes = (schema) => {
  if (!schema) {
    return [];
  }

  const outputSets = schema.map(version => version.output);

  const formattedRequiredOutputSets = formatRequiredOutputsIntoSets(outputSets);
  const uniqRequiredOutputSets = removeDuplicatesFromNestedArray(
    formattedRequiredOutputSets
  );
  const outputFields = getUniqElementsFromSetsByProperty(uniqRequiredOutputSets, "name");
  const attributes = flattenOutputFields(outputFields).filter(attr => !(["List", "Dictionary"].includes(attr.type)));

  return attributes;
};

export const getProductInputs = (schema) => {
  if (!schema) {
    return [];
  }

  const inputSets = schema.map(version => version.input);

  const requiredSets = inputSets.map(set => set.required);
  const optionalSets = inputSets.map(set => set.optional);

  const formattedRequiredInputSets = formatRequiredInputsIntoSets(requiredSets);
  const uniqRequiredInputSets = removeDuplicatesFromNestedArray(formattedRequiredInputSets);

  const required = getUniqElementsFromSetsByProperty(uniqRequiredInputSets, "name");
  const optional = getUniqElementsFromSetsByProperty(optionalSets, "name");

  return {
    required,
    optional,
    uniqRequiredInputSets
  };
};

export const getSamplePayloadInputs = (flattenedUniqueInputs, types) => {
  const payloadInputs = flattenedUniqueInputs.reduce((result, input) => ({
    ...result,
    [input.name]: types.find(type => type.name === input.type).example_input
  }), {});

  return payloadInputs;
};

/**
 * Since we can get multiple column results of the same attribute (outputfield name)
 * from hogfish as panel data, we can end up with the following:

 * company[0].name
 * company[1].name
 * company[2].name

 * This simply replaces them with `[*]` which is how outputfields (attributes)
 * have their flattened_names formatted as

 * company[*].name
 */
export const normalizePanelDataAttributeName = (panelDataStatPiece) => {
  if (!panelDataStatPiece || !panelDataStatPiece.flattened_name) {
    return null;
  }

  return panelDataStatPiece.flattened_name.replace(/\[\d+\]/g, "[*]");
};

export const getAttributePanelData = (panelDataStats, attrFlattenedName) => {
  if (!panelDataStats || !Array.isArray(panelDataStats) || !attrFlattenedName) {
    return null;
  }

  return panelDataStats.find(stat => normalizePanelDataAttributeName(stat) === attrFlattenedName);
};

export const getProductAttributeExample = (product, attr, examples) => {
  if (!product || !attr) {
    return null;
  }

  const { name: productName } = product;
  const { example: attrExample, name: attrName } = attr;

  if (attrExample) {
    return attrExample;
  }

  if (!examples || !examples[productName]) {
    return null;
  }

  const productData = examples[productName];
  // We only need the first column of sample attribute data (examples) thus why we are matching against [0]
  const formattedAttrName = attrName.replace("[*]", "[0]");

  if (!productData.flattened_data || !productData.flattened_data[formattedAttrName]) {
    return null;
  }

  return productData.flattened_data[formattedAttrName];
};

const defaultExtraProductColumnValues = {
  "fill rate": "",
  "unique values": "",
  "unique value rate": ""
};

export const attributePanelDataColumns = (attr, panel_data) => {
  if (!attr) {
    return null;
  }

  if (!panel_data || panel_data.error) {
    return defaultExtraProductColumnValues;
  }

  const {
    metadata: { num_per_month },
    stats
  } = panel_data;

  const attributeWithPanelData = getAttributePanelData(stats, attr.name);
  if (!attributeWithPanelData) {
    return defaultExtraProductColumnValues;
  }

  const {
    field_is_populated_rate,
    num_distinct_values
  } = attributeWithPanelData;

  const uniqValues = num_distinct_values || 0;
  const uniqueValueRate = (uniqValues / (field_is_populated_rate * num_per_month)) || 0;

  return {
    "fill rate": formatAsPercent(field_is_populated_rate * 100),
    "unique values": uniqValues,
    "unique value rate": formatAsPercent(uniqueValueRate * 100)
  };
};

export const getProductPayload = (providerNames, payloadInputs) => ({
  providers: providerNames,
  inputs: payloadInputs,
  config: {
    mode: "sample",
    return_flattened_data: true
  }
});

export const getProductsExamplesPayload = (products, types) => {
  if (!products || !types) {
    return null;
  }

  const payloadInputs = products.reduce((acc, product) => {
    if (!product.latest_version || !product.latest_version.schema) {
      return acc;
    }

    const { schema } = product.latest_version;

    const { required, optional } = getProductInputs(schema);
    const sampleInputs = getSamplePayloadInputs(
      [...required, ...optional],
      types
    );
    return {
      ...acc,
      ...sampleInputs
    };
  }, {});

  return getProductPayload(products.map(product => product.name), payloadInputs);
};

export const getProductColumns = (product, examples) => {
  if (!product) {
    return [];
  }

  const { latest_version, panel_data } = product;
  const schema = latest_version && latest_version.schema;

  const attributes = getProductAttributes(schema);

  return attributes.map((attr) => {
    const { name, ...otherProps } = attr;
    return {
      attribute: name,
      ...otherProps,
      example: (function () {
        if (!examples) {
          return "";
        }
        const example = getProductAttributeExample(product, attr, examples);
        if (!example) {
          return "";
        }
        return example;
      })(),
      ...attributePanelDataColumns(attr, panel_data)
    };
  });
};
