import React from "react";

/**
 * Breaks the string on typical special characters found in e.g. emails to avoid long strings breaking
 * the table layout.
 *
 * @param {String} text String to be changed into breakable string.
 */
export const toBreakableString = (text) => {
  if (typeof text !== "string") {
    return text;
  }

  const pattern = new RegExp("[@_\+-]", "gi");
  const splitText = text.split(pattern);

  if (splitText.length <= 1) {
    return text;
  }

  const matches = text.match(pattern);

  return splitText.reduce((arr, element, index) => (matches[index] ? [
    ...arr,
    element,
    <wbr key={index} />,
    matches[index]
  ] : [...arr, element]), []);
};
