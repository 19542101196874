import React, { useEffect } from "react";
import PropTypes from "prop-types";

import LayoutWithSubheader from "../ui/layout_with_subheader";

import SimpleTable from "../../../../stingray/frontend/components/ui/table";

import { isFileTransfer } from "../../utility/prop-types";
import { humanizedDate, toLocaleString } from "../../utility/date";

export default function FileTransferDetail({
  fetchFileTransfer,
  file,
  fileId
}) {
  useEffect(() => {
    fileId && fetchFileTransfer?.(fileId);
  }, [fetchFileTransfer, fileId]);

  if (!file) {
    return null;
  }

  const {
    id,
    region,
    created_at,
    updated_at,
    organization_name,
    display_key,
    expiry_time,
    expires_in_the_future
  } = file;

  const perished = Boolean(file.perished);

  const data = {
    // ID: id.toString(),
    // Region: region,
    "File Name": display_key,
    "Uploaded By": file.uploader ? file.uploader.name : "-",
    Organization: organization_name,
    Created: humanizedDate(created_at),
    //    "Last Updated": humanizedDate(updated_at),
    Expired: (!expires_in_the_future || perished),
    Expires: toLocaleString(expiry_time)
  };

  return (
    <LayoutWithSubheader title={file.display_key?.replace(/\.\w+/i, "")}>
      <SimpleTable data={data} />
      { expires_in_the_future && !perished
        && <a className="button cyan" href={file.presigned_get_url} download>
            Download File
        </a>}
      { (perished || !expires_in_the_future)
          && <div> File Has Expired </div>
      }
    </LayoutWithSubheader>
  );
}

FileTransferDetail.propTypes = {
  fetchFileTransfer: PropTypes.func.isRequired,
  file: isFileTransfer,
  fileId: PropTypes.number.isRequired
};
