@import (reference) "../../styles/colors.less";
@import (reference) "../../helpers.less";

.ProductTable {
  &__no-data {
    .center-content();
    flex-grow: 1;
    flex-direction: column;
    padding: 0 5em;

    img {
      .empty-message-icon();
    }
  }

  .Toolbar {
    flex-shrink: 0;
  }

  .Table {
    flex-grow: 1;
    overflow: auto;

    table {
      border-collapse: collapse;
      border-spacing: 0;
      width: 100%;

      th, td {
        border-right: 1px solid @divider-color;
        white-space: nowrap;
        &.index:first-child {
          background: white;
          border: 1px solid @divider-color;
          position: sticky;
          left: 0;
          box-shadow: 0px 1px 0px 1px @divider-color;
          width: 56px;
        }
      }

      th {
        padding: 0;
        position: sticky;
        top: 0;
        background: white;
        box-shadow: 0 1px 0 0 @divider-color;
        &.index:first-child {
          box-shadow: 0px 0px 0px 1px @divider-color;
          z-index: 2;
        }
      }

      td {
        padding: 4px 20px;
      }

      tbody tr {
        &:nth-child(odd) {
          background-color: #f2f2f2; // TODO: Not in palette yet
        }

        &:last-child {
          border-bottom: 1px solid @divider-color;
        }
      }
    }
  }
}
