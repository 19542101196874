import {
  GET_FILE_TRANSFERS_REQUEST,
  GET_FILE_TRANSFERS_SUCCESS,
  GET_FILE_TRANSFERS_ERROR,
  GET_FILE_TRANSFER_REQUEST,
  GET_FILE_TRANSFER_SUCCESS,
  GET_FILE_TRANSFER_ERROR
} from "../actions/file_transfers";

const defaultState = {
  isFetching: false,
  fetchedAt: null,
  error: null,
  data: null
};

export default (state = defaultState, action) => {
  switch (action.type) {
    case GET_FILE_TRANSFER_REQUEST:
    case GET_FILE_TRANSFERS_REQUEST: {
      return {
        ...state,
        isFetching: true
      };
    }
    case GET_FILE_TRANSFER_SUCCESS:
    case GET_FILE_TRANSFERS_SUCCESS: {
      const { data } = action;

      return {
        ...state,
        isFetching: false,
        fetchedAt: new Date(),
        error: null,
        data
      };
    }
    case GET_FILE_TRANSFER_ERROR:
    case GET_FILE_TRANSFERS_ERROR: {
      const { error } = action;

      return {
        ...state,
        isFetching: false,
        error
      };
    }
    default: return state;
  }
};
