import { post } from "../helpers/network";

export const GET_SAMPLE_DATA_REQUEST = "GET_SAMPLE_DATA_REQUEST";
export const GET_SAMPLE_DATA_SUCCESS = "GET_SAMPLE_DATA_SUCCESS";
export const GET_SAMPLE_DATA_ERROR = "GET_SAMPLE_DATA_ERROR";

export const fetchSampleData = productId => async (dispatch, getState) => {
  const store = getState();

  if (store.sample_data[productId] && (store.sample_data[productId].isFetching || store.sample_data[productId].data)) {
    // Request for (somewhat) static data - no need for multiple requests
    return Promise.resolve();
  }

  dispatch({
    type: GET_SAMPLE_DATA_REQUEST,
    productId
  });

  try {
    const result = await post(
      `/table_providers/${productId}/download_sample_data`,
      {
        body: { table_provider: { id: productId } }
      }
    );

    dispatch({
      type: GET_SAMPLE_DATA_SUCCESS,
      data: result,
      productId
    });
  } catch (error) {
    dispatch({
      type: GET_SAMPLE_DATA_ERROR,
      error,
      productId
    });
    throw error;
  }
};
