import React from "react";
import PropTypes from "prop-types";

import "./pill.less";

export default function Pill({ children, label }) {
  return (
    <div className="Pill">
      {label && (
        <span className="Pill__label">
          {label}:
        </span>
      )}
      <span className="Pill__content" title={children}>
        {children}
      </span>
    </div>
  );
}

Pill.propTypes = {
  children: PropTypes.node,
  label: PropTypes.node
};
