import makeSimpleReducer from "./_make_simple_reducer";

import {
  GET_PUBLIC_SESSION_HASH_REQUEST,
  GET_PUBLIC_SESSION_HASH_SUCCESS,
  GET_PUBLIC_SESSION_HASH_ERROR
} from "../actions/public_session_hash";

export default makeSimpleReducer({
  actions: [
    GET_PUBLIC_SESSION_HASH_REQUEST,
    GET_PUBLIC_SESSION_HASH_SUCCESS,
    GET_PUBLIC_SESSION_HASH_ERROR
  ]
});
