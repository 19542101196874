import React, { useEffect } from "react";

import "./attribute_catalog.less";

import HeaderWithSort from "../../containers/attribute_catalog/header_with_sort";
import AttributeCards from "../../containers/attribute_catalog/attribute_cards";
import Filters from "./filters";
import Search from "../catalog/search";
import Header from "../header/header";
import Footer from "../../containers/footer/footer";
import TypedSuggestions from "../catalog/typed_suggestions";
import Head from "../head";

import { GAPageView } from "../../utility/google_analytics";

const suggestions = [
  "Revenue",
  "NAICS",
  "Phone Number",
  "Address",
  "Zip Code",
  "Zip4",
  "Income",
  "Construction Type",
  "County",
  "Tax ID",
  "Business Owner Name",
  "Date of Birth",
  "Age",
  "Marital Status",
  "Gender",
  "VIN Number",
  "Operating Hours",
  "Title",
  "Email Address",
  "Education Level",
  "Employer",
  "Date of Death",
  "Date of Incorporation",
  "Legal Entity Type"
];

const metatitle = "Data Attributes Catalog (Beta)";

export default function AttributeCatalog() {
  useEffect(() => {
    GAPageView(metatitle); // only sending 1 page view (not counting searches and filters; will enhance if needed)
  }, []);

  return (
    <div>
      <Header />
      <div className="AttributeCatalog">
        <Head
          title={metatitle}
          description="Demyst's first-of-its-kind data attribute catalog today contains over 100,000 data attributes for people, businesses, vehicles, and more."
        />
        <div className="AttributeCatalog__wrapper">
          <aside className="AttributeCatalog__wrapper__filters">
            <Filters />
          </aside>
          <main className="AttributeCatalog__wrapper__content">
            <h1 className="AttributeCatalog__wrapper__content__title">
              Attribute Search
              <span>BETA</span>
            </h1>
            <Search placeholder="Find an attribute…" />
            <TypedSuggestions dark suggestions={suggestions} />
            <HeaderWithSort />
            <AttributeCards />
          </main>
          <div className="AttributeCatalog__wrapper__filler" />
        </div>
      </div>
      <Footer />
    </div>
  );
}
