import { parse, stringify } from "query-string";

export function replaceInQuery(search, key, value) {
  const oldSearch = parse(search, { arrayFormat: "bracket" });

  if (value === null || value === "") {
    const {
      [key]: removedValue,
      ...oldSearchWithoutParam
    } = oldSearch;
    return stringify(oldSearchWithoutParam, { arrayFormat: "bracket" });
  }

  return stringify({
    ...oldSearch,
    [key]: value
  }, { arrayFormat: "bracket" });
}

export function addToQuery(search, key, value) {
  return replaceInQuery(search, key, value);
}

export function removeFromQuery(search, key) {
  return replaceInQuery(search, key, null);
}
