import { withRouter } from "react-router-dom";

// Actions

// Selectors

// Components
import MultiselectFilter from "../../../components/catalog/filters/multiselect_filter";

export default withRouter(MultiselectFilter);
