import { get, post } from "../helpers/network";

export const GET_USE_CASES_REQUEST = "GET_USE_CASES_REQUEST";
export const GET_USE_CASES_SUCCESS = "GET_USE_CASES_SUCCESS";
export const GET_USE_CASES_ERROR = "GET_USE_CASES_ERROR";

export const GET_USE_CASE_REQUEST = "GET_USE_CASE_REQUEST";
export const GET_USE_CASE_SUCCESS = "GET_USE_CASE_SUCCESS";
export const GET_USE_CASE_ERROR = "GET_USE_CASE_ERROR";

export const GET_USE_CASE_TABLE_PROVIDERS_REQUEST = "GET_USE_CASE_TABLE_PROVIDERS_REQUEST";
export const GET_USE_CASE_TABLE_PROVIDERS_SUCCESS = "GET_USE_CASE_TABLE_PROVIDERS_SUCCESS";
export const GET_USE_CASE_TABLE_PROVIDERS_ERROR = "GET_USE_CASE_TABLE_PROVIDERS_ERROR";

export const GET_USE_CASE_EVENTS_REQUEST = "GET_USE_CASE_EVENTS_REQUEST";
export const GET_USE_CASE_EVENTS_SUCCESS = "GET_USE_CASE_EVENTS_SUCCESS";
export const GET_USE_CASE_EVENTS_ERROR = "GET_USE_CASE_EVENTS_ERROR";

export const GET_USE_CASE_USERS_REQUEST = "GET_USE_CASE_USERS_REQUEST";
export const GET_USE_CASE_USERS_SUCCESS = "GET_USE_CASE_USERS_SUCCESS";
export const GET_USE_CASE_USERS_ERROR = "GET_USE_CASE_USERS_ERROR";

export const GET_USE_CASE_MODELS_REQUEST = "GET_USE_CASE_MODELS_REQUEST";
export const GET_USE_CASE_MODELS_SUCCESS = "GET_USE_CASE_MODELS_SUCCESS";
export const GET_USE_CASE_MODELS_ERROR = "GET_USE_CASE_MODELS_ERROR";

export const GET_USE_CASE_DATA_SERVICE_JOBS_REQUEST = "GET_USE_CASE_DATA_SERVICE_JOBS_REQUEST";
export const GET_USE_CASE_DATA_SERVICE_JOBS_SUCCESS = "GET_USE_CASE_DATA_SERVICE_JOBS_SUCCESS";
export const GET_USE_CASE_DATA_SERVICE_JOBS_ERROR = "GET_USE_CASE_DATA_SERVICE_JOBS_ERROR";

export const CREATE_USE_CASE_NOTE_EVENT_REQUEST = "CREATE_USE_CASE_NOTE_EVENT_REQUEST";
export const CREATE_USE_CASE_NOTE_EVENT_SUCCESS = "CREATE_USE_CASE_NOTE_EVENT_SUCCESS";
export const CREATE_USE_CASE_NOTE_EVENT_ERROR = "CREATE_USE_CASE_NOTE_EVENT_ERROR";

const shouldFetchData = results =>
  !results || !(results.isFetching || results.data);

export const fetchUseCases = () => async (dispatch, getState) => {
  const store = getState();
  if (!shouldFetchData(store.use_cases.results[JSON.stringify({})])) {
    // Request for (somewhat) static data - no need for multiple requests
    return Promise.resolve(store.use_cases.data);
  }

  dispatch({ type: GET_USE_CASES_REQUEST });

  try {
    const data = await get("/edaas");
    dispatch({
      type: GET_USE_CASES_SUCCESS,
      data
    });
    return data;
  } catch (error) {
    dispatch({
      type: GET_USE_CASES_ERROR,
      error
    });
    throw error;
  }
};

const isUseCaseFullyLoaded = useCase =>
  useCase && useCase.name && useCase.description; // TODO: check if other stuff is loaded for use case

export const fetchUseCase = useCaseId => (dispatch, getState) => {
  const store = getState();

  if (isUseCaseFullyLoaded(store.use_cases.entities[useCaseId])) {
    // Request for (somewhat) static data - no need for multiple requests
    return Promise.resolve(store.use_cases.entities[useCaseId]);
  }

  dispatch({ type: GET_USE_CASE_REQUEST });

  return get(`/edaas/${useCaseId}`)
    .then((use_case) => {
      dispatch({
        type: GET_USE_CASE_SUCCESS,
        data: use_case
      });
    })
    .catch((error) => {
      dispatch({
        type: GET_USE_CASE_ERROR,
        error
      });
    });
};

export const fetchUseCaseTableProviders = useCaseId => async (dispatch, getState) => {
  const store = getState();

  if (!shouldFetchData(store.use_case_data_products.data)) {
    // Request for (somewhat) static data - no need for multiple requests
    return Promise.resolve(store.use_case_data_products.data);
  }

  dispatch({ type: GET_USE_CASE_TABLE_PROVIDERS_REQUEST });

  try {
    const data = await get(`/edaas/${useCaseId}/table_providers`);
    dispatch({
      type: GET_USE_CASE_TABLE_PROVIDERS_SUCCESS,
      data,
      params: {
        id: useCaseId
      }
    });
    return data;
  } catch (error) {
    dispatch({
      type: GET_USE_CASE_TABLE_PROVIDERS_ERROR,
      error
    });
    throw error;
  }
};

export const fetchUseCaseEvents = useCaseId => async (
  dispatch,
  getState
) => {
  const store = getState();

  if (!shouldFetchData(store.use_case_events.data)) {
    // Request for (somewhat) static data - no need for multiple requests
    return Promise.resolve(store.use_case_events.data);
  }

  dispatch({ type: GET_USE_CASE_EVENTS_REQUEST });

  try {
    const data = await get(`/edaas/${useCaseId}/events`);
    dispatch({
      type: GET_USE_CASE_EVENTS_SUCCESS,
      data,
      params: {
        id: useCaseId
      }
    });
    return data;
  } catch (error) {
    dispatch({
      type: GET_USE_CASE_EVENTS_ERROR,
      error
    });
    throw error;
  }
};


export const fetchUseCaseUsers = useCaseId => async (dispatch, getState) => {
  const store = getState();

  if (!shouldFetchData(store.use_case_users.data)) {
    // Request for (somewhat) static data - no need for multiple requests
    return Promise.resolve(store.use_case_users.data);
  }

  dispatch({ type: GET_USE_CASE_USERS_REQUEST });

  try {
    const data = await get(`/edaas/${useCaseId}/users`);
    dispatch({
      type: GET_USE_CASE_USERS_SUCCESS,
      data,
      params: {
        id: useCaseId
      }
    });
    return data;
  } catch (error) {
    dispatch({
      type: GET_USE_CASE_USERS_ERROR,
      error
    });
    throw error;
  }
};

export const fetchUseCaseModels = useCaseId => async (dispatch, getState) => {
  const store = getState();

  if (!shouldFetchData(store.use_case_models.data)) {
    // Request for (somewhat) static data - no need for multiple requests
    return Promise.resolve(store.use_case_models.data);
  }

  dispatch({ type: GET_USE_CASE_MODELS_REQUEST });

  try {
    const data = await get(`/edaas/${useCaseId}/models`);
    dispatch({
      type: GET_USE_CASE_MODELS_SUCCESS,
      data,
      params: {
        id: useCaseId
      }
    });
    return data;
  } catch (error) {
    dispatch({
      type: GET_USE_CASE_MODELS_ERROR,
      error
    });
    throw error;
  }
};

export const fetchUseCaseDataServiceJobs = useCaseId => async (dispatch, getState) => {
  const store = getState();

  if (!shouldFetchData(store.use_case_data_service_jobs.data)) {
    // Request for (somewhat) static data - no need for multiple requests
    return Promise.resolve(store.use_case_data_service_jobs.data);
  }

  dispatch({ type: GET_USE_CASE_DATA_SERVICE_JOBS_REQUEST });

  try {
    const data = await get(`/edaas/${useCaseId}/data_service_jobs`);
    dispatch({
      type: GET_USE_CASE_DATA_SERVICE_JOBS_SUCCESS,
      data,
      params: {
        id: useCaseId
      }
    });
    return data;
  } catch (error) {
    dispatch({
      type: GET_USE_CASE_DATA_SERVICE_JOBS_ERROR,
      error
    });
    throw error;
  }
};

export const createNoteEvent = (noteParams, useCaseId, s3ObjectId) => async (dispatch) => {
  dispatch({ type: CREATE_USE_CASE_NOTE_EVENT_REQUEST });

  try {
    const data = await post("/edaas_events", {
      body: {
        edaas_event: {
          ...noteParams,
          edaas_use_case_id: useCaseId,
          s3_object_id: s3ObjectId
        }
      }
    });

    dispatch({
      type: CREATE_USE_CASE_NOTE_EVENT_SUCCESS,
      data,
      params: {
        id: useCaseId
      }
    });
    return data;
  } catch (error) {
    dispatch({
      type: CREATE_USE_CASE_NOTE_EVENT_ERROR,
      error
    });
    throw error;
  }
};
