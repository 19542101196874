import React, { Component } from "react";
import PropTypes from "prop-types";

import "./singleselect_dropdown.less";

import Dropdown from "../dropdown";
import { highlightPattern, toFuzzyRegExp } from "../../../utility/search";

const isValidValue = PropTypes.oneOfType([
  PropTypes.string,
  PropTypes.number,
  PropTypes.bool
]);

export default class SingleselectDropdown extends Component {
  static propTypes = {
    items: PropTypes.arrayOf(PropTypes.oneOfType([
      isValidValue,
      PropTypes.shape({
        checked: PropTypes.bool,
        label: PropTypes.string,
        value: isValidValue
      })
    ])),
    name: PropTypes.string.isRequired,
    onItemChecked: PropTypes.func,
    placeholderLabel: PropTypes.node,
    value: PropTypes.arrayOf(isValidValue)
  };

  state = {
    search: null
  }

  onSearchChange = (event) => {
    this.setState({ search: event.target.value });
  };

  get label() {
    const { placeholderLabel } = this.props;
    const { value } = this.props;

    if (!value || !value.length) {
      return placeholderLabel;
    }

    return value;
  }

  get listItems() {
    const { items } = this.props;
    const { search } = this.state;

    if (!items) {
      return null;
    }

    return items.map((item) => {
      const isValueInOption = typeof item === "object" && "value" in item;
      const label = item.label || item;
      const highlighedLabel = search ? highlightPattern(label, toFuzzyRegExp(search)) : label;

      return (
        <div
          className="SingleselectDropdown__item"
          key={isValueInOption ? item.value : item}
          hidden={this.shouldBeHidden(item)}
        >
          <button
            type="button"
            onClick={(event) => {
              const button = event.currentTarget;
              const checkbox = button.querySelector("input");
              if (event.target !== checkbox) {
                checkbox.click();
              }
            }}
          >
            <div className="SingleselectDropdown__item__radio">
              <input
                name={name}
                type="radio"
                value={isValueInOption ? item.value : item}
                checked={item.checked}
                onChange={item.onItemChecked}
              />
            </div>
            <div className="SingleselectDropdown__item__label">
              {highlighedLabel}
            </div>
          </button>
        </div>
      );
    });
  }

  get listItemsWithSearch() {
    const { listItems } = this;

    if (!listItems) {
      return null;
    }

    const search = (
      <div className="SingleselectDropdown__item">
        <input
          type="search"
          onChange={this.onSearchChange}
          placeholder="Search…"
        />
      </div>
    );

    return [search, ...listItems];
  }

  shouldBeHidden = (item) => {
    const { search } = this.state;

    if (!search) {
      return false;
    }

    const regExp = toFuzzyRegExp(search);
    return !((item.label || item).match(regExp));
  }

  render() {
    const { items, ...otherProps } = this.props;

    return (
      <Dropdown
        className="SingleselectDropdown"
        disableCloseOnInsideAction
        items={this.listItemsWithSearch}
        label={this.label}
        {...otherProps}
      />
    );
  }
}
