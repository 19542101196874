import React, { useEffect } from "react";
import PropTypes from "prop-types";

import DataProductButton from "./data_product_button";
import DataProductButtonPlaceholder from "../../placeholders/use_case/data_product_button";
import UseCaseSection from "./use_case_section";

import { isProduct } from "../../utility/prop-types";

import "./data_products.less";

export default function DataProducts({
  isFetching,
  fetchUseCaseTableProviders,
  dataProducts,
  useCaseId
}) {
  useEffect(() => {
    useCaseId && fetchUseCaseTableProviders(useCaseId);
  }, [fetchUseCaseTableProviders, useCaseId]);

  if ((!isFetching && !dataProducts) || dataProducts?.length === 0) {
    return null;
  }

  const content = () => {
    if (isFetching) {
      return Array.from(new Array(5)).map((el, i) => (
        <DataProductButtonPlaceholder key={i} />
      ));
    }
    return (
      <>
        {dataProducts.map((dataProduct, i) => (
          <DataProductButton key={i} product={dataProduct} />
        ))}
      </>
    );
  };

  return (
    <UseCaseSection title="Data Products">
      <div className="DataProducts">{content()}</div>
    </UseCaseSection>
  );
}

DataProducts.propTypes = {
  dataProducts: PropTypes.arrayOf(isProduct),
  fetchUseCaseTableProviders: PropTypes.func.isRequired,
  isFetching: PropTypes.bool.isRequired,
  useCaseId: PropTypes.number
};
