import React, { useEffect } from "react";

import PropTypes from "prop-types";
import Prism from "prismjs";

import { useCMSAPISinglePage } from "../../../utility/hooks";
import { GAPageView } from "../../../utility/google_analytics";

import Head from "../../head";
import SafeContent from "../../safe_content";

import "./api_docs.less";

import "../../../static/css/prism.css";
import "../../../static/css/prism_overrides.css";

export default function APIDocsContent({
  match: {
    params: { slug }
  }
}) {
  const pageData = useCMSAPISinglePage(slug, {}, "docs");

  useEffect(() => {
    if (pageData) {
      Prism.highlightAll();
      GAPageView(pageData?.data?.fields?.metatitle);
    }
  }, [pageData]);

  if (!pageData) {
    return null;
  }

  const {
    data: {
      fields: {
        content_body,
        content_title,
        metatitle
      }
    }
  } = pageData;

  return (
    <div>
      <Head title={metatitle} />
      <h1>{content_title}</h1>
      <SafeContent>{content_body}</SafeContent>
    </div>
  );
}

APIDocsContent.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      slug: PropTypes.string.isRequired
    }).isRequired
  }).isRequired
};
