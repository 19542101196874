import React from "react";
import PropTypes from "prop-types";

import "./product_table_toolbar.less";

import Dropdown from "../../../../stingray/frontend/components/ui/dropdown";
import Search from "../../../../stingray/frontend/components/ui/search";

import DownloadIcon from "../../../../stingray/frontend/assets/svgs/download";

export default function ProductTableToolbar({
  areFiltersSelected,
  dataLength,
  filteredDataLength,
  onDownloadFilteredDataset,
  onDownloadFullDataset
}) {
  return (
    <div className="ProductTableToolbar">
      <Search placeholder="Search keywords in table…" />
      <div className="ProductTableToolbar__count">
        {areFiltersSelected && <span>{`${filteredDataLength} of`}</span>}
        <span>{dataLength}</span>
        {dataLength === 1 ? "Row" : "Rows"} of Data
      </div>
      <div className="ProductTableToolbar__download">
        <Dropdown
          hideArrow
          items={[
            areFiltersSelected && filteredDataLength > 0 && (
              <button
                disabled={filteredDataLength === 0}
                onClick={onDownloadFilteredDataset}
                title="Download Filtered Dataset"
                type="button"
              >
                {`Filtered Dataset (${filteredDataLength} rows)`}
              </button>
            ),
            <button
              disabled={dataLength === 0}
              onClick={onDownloadFullDataset}
              title="Download Full Dataset"
              type="button"
            >
              {`Full Dataset (${dataLength} rows)`}
            </button>
          ]}
          label={(
            <>
              Download
              <span className="ProductTableToolbar__download__icon">
                <DownloadIcon />
              </span>
            </>
          )}
          title="Download data"
        />
      </div>
    </div>
  );
}

ProductTableToolbar.propTypes = {
  areFiltersSelected: PropTypes.bool.isRequired,
  dataLength: PropTypes.number.isRequired,
  filteredDataLength: PropTypes.number.isRequired,
  onDownloadFilteredDataset: PropTypes.func.isRequired,
  onDownloadFullDataset: PropTypes.func.isRequired
};
