import React, { useState, useEffect, useMemo } from "react";

import PropTypes from "prop-types";
import { useLocation, useHistory } from "react-router-dom";
import { parse } from "query-string";
import { useDispatch, useSelector } from "react-redux";

import "./search.less";

import SearchContent from "./search_content";
import { useDebouncedEffect } from "../../utility/hooks";
import { getDataSources } from "../../selectors/data_sources";
import { fetchDataSourcesSearch } from "../../actions/data_sources";
import { syncSearchWithUrl } from "../../utility/helpers";

const LOADING_TEXT = "Loading…";

const SEARCH_TYPE = {
  BRAND: "Data Sources",
  USE_CASE: "Use Cases"
};

const formatBrands = (brands) => {
  if (!brands) {
    return [{
      name: LOADING_TEXT,
      logo: null,
      link: "#"
    }];
  }

  const formatted = brands.map(({ image_url, name }) => {
    return {
      name,
      logo: image_url,
      link: `/data-sources/${name}`
    };
  });

  return formatted;
};

export default function Search({
  placeholder
}) {
  const [searchInputValue, setSearchInputValue] = useState("");
  const location = useLocation();
  const dispatch = useDispatch();
  const history = useHistory();
  const brands = useSelector(store =>
    getDataSources(store, { search: searchInputValue }));

  useEffect(() => {
    // Location will only change if we visit the page with search pre-filled
    // or if we manually click on the item to search what the user entered
    // So sync search with url whenever location changes
    const urlParams = parse(location.search);
    const { search } = urlParams;
    search && setSearchInputValue(search);
  }, [location]);

  useDebouncedEffect(
    () => {
      if (brands) {
        return;
      }
      if (searchInputValue) {
        dispatch(fetchDataSourcesSearch({ search: searchInputValue }));
      }
    },
    [searchInputValue],
    400
  );

  const onChange = event => setSearchInputValue(event.target.value);

  const performRawSearch = () => syncSearchWithUrl(history, location, "search", searchInputValue);

  const keyPressed = ({ key }) => {
    if (key === "Enter") {
      performRawSearch();
    }
  };

  const data = useMemo(() => {
    const formattedBrands = formatBrands(brands);
    return {
      [SEARCH_TYPE.BRAND]: formattedBrands
    };
  }, [brands]);

  return (
    <div className="Search">
      <SearchContent data={data} performRawSearch={performRawSearch}>
        <input
          type="search"
          name="search"
          autoComplete="off"
          onChange={onChange}
          placeholder={placeholder}
          value={searchInputValue}
          onKeyPress={keyPressed}
        />
      </SearchContent>
    </div>
  );
}

Search.propTypes = {
  placeholder: PropTypes.string
};

Search.defaultProps = {
  placeholder: "Search…"
};
