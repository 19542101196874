import React, { Component } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

import "./spinner.less";

const inside = (
  <>
    <div className="circle-clipper left">
      <div className="circle" />
    </div>
    <div className="gap-patch">
      <div className="circle" />
    </div>
    <div className="circle-clipper right">
      <div className="circle" />
    </div>
  </>
);

export default class Spinner extends Component {
  renderSpinner() {
    const {
      className, multicolor, red, green, white, yellow
    } = this.props;

    if (multicolor) {
      return (
        <>
          <div className="spinner-layer spinner-blue">
            {inside}
          </div>
          <div className="spinner-layer spinner-red">
            {inside}
          </div>
          <div className="spinner-layer spinner-yellow">
            {inside}
          </div>
          <div className="spinner-layer spinner-green">
            {inside}
          </div>
        </>
      );
    }

    const spinnerClasses = classNames(
      "spinner-layer",
      white ? "spinner-white-only" : false,
      red ? "spinner-red-only" : false,
      green ? "spinner-green-only" : false,
      yellow ? "spinner-yellow-only" : "spinner-blue-only",
      className
    );

    return (
      <div className={spinnerClasses}>
        {inside}
      </div>
    );
  }

  render() {
    const { className, big, small } = this.props;

    const wrapperClasses = classNames(
      "preloader-wrapper",
      "active",
      big ? "big" : false,
      small ? "small" : false,
      className
    );

    return (
      <div className={wrapperClasses}>
        {this.renderSpinner()}
      </div>
    );
  }
}

Spinner.propTypes = {
  big: PropTypes.bool,
  className: PropTypes.string,
  green: PropTypes.bool,
  multicolor: PropTypes.bool,
  red: PropTypes.bool,
  small: PropTypes.bool,
  white: PropTypes.bool,
  yellow: PropTypes.bool
};
