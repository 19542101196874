import React from "react";
import PropTypes from "prop-types";

import Placeholder from "../../placeholders/placeholder";
import ProductDetailsSection from "./product_details_section";

import { isProduct } from "../../utility/prop-types";

export default function Description({ isPlaceholder, product }) {
  return (
    <ProductDetailsSection
      id="description"
      header="Description"
    >
      <Placeholder
        isEnabled={isPlaceholder}
        theme="dark"
      >
        <p>{product.description}</p>
        {product.data_source ? (
          <section>
            <h3>Data Source</h3>
            <p>
              <a
                className="link-button-inline"
                target="_blank"
                rel="noopener noreferrer"
                href={product.data_source.website}
              >
                {product.data_source.name}
              </a>
            </p>
          </section>
        ) : null}
        <section>
          <h3>Product ID</h3>
          <p>{product.name}</p>
        </section>
      </Placeholder>
    </ProductDetailsSection>
  );
}

Description.propTypes = {
  isPlaceholder: PropTypes.bool,
  product: isProduct.isRequired
};
