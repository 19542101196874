import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { parse } from "query-string";

import "./filter_chips.less";

import { replaceInQuery } from "../../../../../stingray/frontend/utility/url";
import { isHistory, isLocation } from "../../../utility/prop-types";

import DeleteIcon from "../../../static/icons/delete_icon.svg";

const getLocation = (location, prop, value) => {
  const { [prop]: propValues } = parse(location.search, { arrayFormat: "bracket" });
  // Remove current value from propValues
  propValues.splice(propValues.indexOf(value), 1);

  return {
    ...location,
    search: replaceInQuery(location.search, prop, propValues)
  };
};

function FilterChipsItem({
  history, location, prop, value
}) {
  const nextLocation = getLocation(location, prop, value);

  return (
    <li>
      <Link
        to={nextLocation}
        onClick={(event) => {
          event.preventDefault();
          history.replace(nextLocation);
        }}
      >
        <span>
          {value}
        </span>
        <img src={DeleteIcon} />
      </Link>
    </li>
  );
}

FilterChipsItem.propTypes = {
  history: isHistory.isRequired,
  location: isLocation.isRequired,
  prop: PropTypes.string,
  value: PropTypes.string
};

export default function FilterChips({ history, location }) {
  const {
    categories,
    regions
  } = parse(location.search, { arrayFormat: "bracket" });

  return (
    <div className="FilterChips">
      <ul>
        {categories && categories.map(category => (
          <FilterChipsItem
            key={category}
            history={history}
            location={location}
            prop="categories"
            value={category}
          />
        ))}
        {regions && regions.map(region => (
          <FilterChipsItem
            key={region}
            history={history}
            location={location}
            prop="regions"
            value={region}
          />
        ))}
      </ul>
    </div>
  );
}

FilterChips.propTypes = {
  history: isHistory.isRequired,
  location: isLocation.isRequired
};
