import React from "react";
import PropTypes from "prop-types";

import "./menu.less";

import MenuItem, { isItem } from "./menu_item";

import getUserLinks from "./user_links";
import { isUser, isLocation } from "../../utility/prop-types";

export default function Menu({
  isDemystUser,
  items,
  location,
  logOut,
  user
}) {
  return (
    <div className="Menu">
      <ul className="Menu__list" role="menulist">
        {items && items.map(item => (
          <MenuItem
            key={item.label}
            location={location}
            item={item}
          />
        ))}
        {user && getUserLinks({
          isDemystUser,
          logOut
        }).map((link, index) => (
          <li
            key={index}
            className="Menu__list__item Menu__list__item--mobileOnly"
          >
            {link}
          </li>
        ))}
      </ul>
    </div>
  );
}

Menu.propTypes = {
  isDemystUser: PropTypes.bool.isRequired,
  items: PropTypes.arrayOf(isItem),
  location: isLocation.isRequired,
  logOut: PropTypes.func.isRequired,
  user: isUser
};
