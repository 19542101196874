import React, { createRef, Component } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";

import { Provider } from "./context";

import { makeCancellable, PromiseCancelledException } from "../../../utility/promise";

export class Form extends Component {
  static propTypes = {
    children: PropTypes.node,
    forwardedRef: PropTypes.any,
    hidden: PropTypes.bool,
    history: PropTypes.shape({
      block: PropTypes.func.isRequired
    }).isRequired,
    leaveConfirmation: PropTypes.bool,
    onSubmit: PropTypes.func
  };

  static defaultProps = {
    leaveConfirmation: true
  };

  state = {
    isChanged: false,
    isSubmitting: false,
    lastSubmitted: null
  };

  componentDidUpdate() {
    const { history, leaveConfirmation } = this.props;
    const { isChanged } = this.state;

    if (leaveConfirmation) {
      if (this.unblock) {
        this.unblock();
        this.unblock = undefined;
      }

      if (isChanged) {
        this.unblock = history.block(location => location.pathname);
      }
    }
  }

  componentWillUnmount() {
    const { leaveConfirmation } = this.props;

    if (leaveConfirmation) {
      if (this.unblock) {
        this.unblock();
      }
    }

    this.cancellablePromise && this.cancellablePromise.cancel();
  }

  onFormUpdate = () => {
    this.setState({ isChanged: this.isChanged });
  };

  onSubmit = (event) => {
    if (this.ref.current.checkValidity()) {
      event.preventDefault();

      this.submit();
    }
  }

  get isChanged() {
    if (!this.ref || !this.ref.current) {
      return null;
    }

    const elements = Array.from(this.ref.current.elements)
      .filter(element => element.type !== "search" && element.type !== "submit");

    return elements.some((element) => {
      switch (element.type) {
        case "checkbox":
          return element.checked !== element.defaultChecked;
        case "select-one":
        case "select-multiple":
          return Array.from(element.options).some(option => option.selected !== option.defaultSelected);
        default:
          return (
            element.defaultValue !== undefined
            && element.value !== element.defaultValue
          );
      }
    });
  }

  get ref() {
    const { forwardedRef } = this.props;

    return forwardedRef || this.form;
  }

  form = createRef();

  submit = async () => {
    const { onSubmit } = this.props;

    if (!this.ref || !this.ref.current) {
      return;
    }

    try {
      let promise = onSubmit(this.ref.current);
      if (!(promise instanceof Promise)) {
        promise = Promise.resolve();
      }

      this.setState({ isSubmitting: true });
      this.cancellablePromise = makeCancellable(promise);
      await this.cancellablePromise.promise;
      // This is when we need to refetch the data, so that the default values in the form would match form state
      this.setState({
        isChanged: false,
        isSubmitting: false,
        lastSubmitted: new Date()
      });
    } catch (error) {
      if (error && error instanceof PromiseCancelledException) {
        return;
      }
      this.setState({ isSubmitting: false });
    }
  };

  render() {
    const { children, hidden } = this.props;

    return (
      <form
        hidden={hidden}
        onChange={this.onFormUpdate}
        onKeyUp={this.onFormUpdate}
        onSubmit={this.onSubmit}
        ref={this.ref}
      >
        <Provider
          value={{
            ...this.state,
            submit: this.submit
          }}
        >
          {children}
        </Provider>
      </form>
    );
  }
}

const WrappedComponent = withRouter(Form);

export default React.forwardRef(
  function myFunction(props, ref) {
    return <WrappedComponent {...props} forwardedRef={ref} />;
  }
);
