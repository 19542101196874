import { get, post } from "../helpers/network";

export const GET_SINGLE_LOOKUP_CREDITS_REQUEST = "GET_SINGLE_LOOKUP_CREDITS_REQUEST";
export const GET_SINGLE_LOOKUP_CREDITS_SUCCESS = "GET_SINGLE_LOOKUP_CREDITS_SUCCESS";
export const GET_SINGLE_LOOKUP_CREDITS_ERROR = "GET_SINGLE_LOOKUP_CREDITS_ERROR";
export const USE_SINGLE_LOOKUP_CREDIT_REQUEST = "USE_SINGLE_LOOKUP_CREDIT_REQUEST";
export const USE_SINGLE_LOOKUP_CREDIT_SUCCESS = "USE_SINGLE_LOOKUP_CREDIT_SUCCESS";
export const USE_SINGLE_LOOKUP_CREDIT_ERROR = "USE_SINGLE_LOOKUP_CREDIT_ERROR";

export const fetchSingleLookupCredits = () => async (dispatch, getState) => {
  const store = getState();

  if (store.single_lookup.isFetching || store.single_lookup.data) {
    // Request for (somewhat) static data - no need for multiple requests
    return store.single_lookup.data;
  }

  dispatch({ type: GET_SINGLE_LOOKUP_CREDITS_REQUEST });

  try {
    const data = await get("/single_lookups");
    dispatch({
      type: GET_SINGLE_LOOKUP_CREDITS_SUCCESS,
      data
    });
    return data;
  } catch (error) {
    dispatch({
      type: GET_SINGLE_LOOKUP_CREDITS_ERROR,
      error
    });
    throw error;
  }
};

export const useSingleLookupCredit = () => async (dispatch, getState) => {
  const store = getState();

  if (store.single_lookup.isFetching) {
    // Request for (somewhat) static data - no need for multiple requests
    return Promise.resolve();
  }

  dispatch({ type: USE_SINGLE_LOOKUP_CREDIT_REQUEST });

  try {
    const data = await post("/single_lookups/use_credit");
    dispatch({
      type: USE_SINGLE_LOOKUP_CREDIT_SUCCESS,
      data
    });
    return data;
  } catch (error) {
    dispatch({
      type: USE_SINGLE_LOOKUP_CREDIT_ERROR,
      error
    });
    throw error;
  }
};
