import { useEffect, useMemo, useState } from "react";

import {
  getProductsExamplesPayload
} from "./data_dictionary";

import { makeCancellable, PromiseCancelledException } from "../../../stingray/frontend/utility/promise";
import { notify } from "../../../stingray/frontend/utility/notifications";

import butter from "../butter_cms_client";

const isClient = typeof window === "object";

export const useDebouncedEffect = (effect, deps, debounceTime) => {
  useEffect(() => {
    let cleanup;

    const timeout = setTimeout(() => {
      cleanup = effect();
    }, debounceTime);

    return () => {
      clearTimeout(timeout);

      if (typeof cleanup === "function") {
        cleanup();
      }
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps);
};

export const useMountEffect = fn => useEffect(fn, []);

export const useResizeEffect = (getter) => {
  const [value, setValue] = useState(getter);
  const handler = () => setValue(getter());
  useMountEffect(() => {
    if (!isClient) {
      return;
    }

    window.addEventListener("resize", handler);
    return () => window.removeEventListener("resize", handler);
  });
  return value;
};

const getWidth = () => isClient ? window.innerWidth : undefined;

const getIsMobile = () => isClient ? (getWidth() <= 900) : undefined;

export const useWindowWidth = () => {
  const windowSize = useResizeEffect(getWidth);
  return windowSize;
};

export const useIsMobile = () => {
  const isMobile = useResizeEffect(getIsMobile);
  return isMobile;
};


export const useProductsExamples = (products, types, queryBlackfin) => {
  const [examples, setExamples] = useState(null);

  useEffect(() => {
    if (!products || !types) {
      return;
    }

    let cancellablePromise;
    let productsForPayload = products;

    if (!Array.isArray(productsForPayload) && productsForPayload.latest_version) {
      // If we pass in a single product instead of an array of products
      // Avoids infinite loop of passing in array if wanting to use this method for a single product
      productsForPayload = [products];
    }

    async function getExamples() {
      try {
        const payload = getProductsExamplesPayload(productsForPayload, types);
        cancellablePromise = makeCancellable(queryBlackfin(payload));
        const response = await cancellablePromise.promise;
        setExamples(response.output);
      } catch (error) {
        if (error && error instanceof PromiseCancelledException) {
          return;
        }
        setExamples(false);
      }
    }

    getExamples();

    return () => {
      if (cancellablePromise) {
        cancellablePromise.cancel();
      }
    };
  }, [products, types, queryBlackfin]);

  return examples;
};

export const useCMSAPI = (requiredParams, type = "page", extraParams) => {
  // Ex: useCMSAPI([req1, req2, req3], type = "content", extraParams = { levels: 3});
  const [pageData, setPageData] = useState(null);

  useEffect(() => {
    if (!requiredParams || requiredParams.length === 0) {
      throw new Error("Missing required params");
    }

    const fetchData = async () => {
      try {
        const { data: respData } = await butter[type].retrieve(
          ...requiredParams,
          extraParams
        );
        setPageData(respData);
      } catch (error) {
        notify(error.message, "error");
      }
    };

    fetchData();
  }, [requiredParams, type, extraParams]);

  return pageData;
};

export const useCMSAPISinglePage = (slug, extraParams, pageType = "*") => {
  const deps = JSON.stringify(extraParams);
  const fields = useMemo(() => [pageType, slug], [pageType, slug]);
  const optionalParams = useMemo(() => (extraParams), [deps]); // eslint-disable-line react-hooks/exhaustive-deps
  return useCMSAPI(fields, "page", optionalParams);
};

export const useCMSAPICollection = (keys) => {
  // https://github.com/facebook/react/issues/14476#issuecomment-471199055
  const deps = JSON.stringify(keys);
  const fields = useMemo(() => [keys], [deps]); // eslint-disable-line react-hooks/exhaustive-deps
  const optionalParams = useMemo(() => ({ levels: 3 }), []);
  return useCMSAPI(fields, "content", optionalParams);
};
