import React from "react";

import CategoriesDropdown from "../../../containers/catalog/filters/categories_dropdown";
import Container from "../../../../../stingray/frontend/components/ui/container";
import SortDropdown from "../../../containers/catalog/filters/sort_dropdown";

import "./filters.less";

export default function Filters() {
  return (
    <div className="Filters">
      <Container allowWrap>
        <SortDropdown />
        <CategoriesDropdown />
      </Container>
    </div>
  );
}
