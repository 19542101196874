import React, { useEffect, useState } from "react";
import { parse } from "query-string";

import Range from "rc-slider/lib/Range";

import "./cost_filter.less";

import { isHistory, isLocation } from "../../../utility/prop-types";
import { addToQuery } from "../../../../../stingray/frontend/utility/url";

const MAX = 75;
const MIN = 0;

const getLocation = (location, urlKey, values) => {
  const lowKey = `${urlKey}_low`;
  const highKey = `${urlKey}_high`;

  const [low, high] = values;

  let newQuery = addToQuery(location.search, lowKey, low);
  newQuery = addToQuery(newQuery, highKey, high);

  return {
    ...location,
    search: newQuery
  };
};

const replaceFilters = (history, location, urlKey, values) => {
  history.replace(getLocation(location, urlKey, values));
};

const marks = {
  [MIN]: "$",
  25: "$$",
  50: "$$$",
  [MAX]: "$$$$"
};
export default function CostFilter({
  history,
  location
}) {
  const [costRange, setCostRange] = useState([MIN, MAX]);

  useEffect(() => {
    const {
      cost_low,
      cost_high
    } = parse(location.search, { arrayFormat: "bracket" });

    const keys = Object.keys(marks).map(numStr => Number(numStr));
    const lowKey = keys.find(key => marks[key] === cost_low);
    const highKey = keys.find(key => marks[key] === cost_high);
    // Avoid 0 being falsey
    const costLow = lowKey >= 0 ? lowKey : MIN;
    const costHigh = highKey >= 0 ? highKey : MAX;
    setCostRange([costLow, costHigh]);
  }, [location.search]);

  const onChange = (values) => {
    // values -> [25, 50]
    const formattedValues = values.map(val => marks[val]);
    replaceFilters(history, location, "cost", formattedValues);
  };

  const updateCostRange = range => setCostRange(range);

  return (
    <div className="CostFilters">
      <Range
        marks={marks}
        min={MIN}
        max={MAX}
        step={25}
        defaultValue={[MIN, MAX]}
        onChange={updateCostRange}
        onAfterChange={onChange}
        value={costRange}
      />
    </div>
  );
}

CostFilter.propTypes = {
  history: isHistory.isRequired,
  location: isLocation.isRequired
};
