import React from "react";
import PropTypes from "prop-types";

import { isProduct } from "../../utility/prop-types";
import { generateProviderLogoPlaceholder } from "../../utility/placeholders";

export default function ProviderLogo({ product, theme }) {
  if (!product || !product.logo) {
    return null;
  }

  return (
    <img
      key={product.logo}
      src={product.logo}
      alt={product.data_source && product.data_source.name}
      onError={(event) => {
        const { target } = event;
        target.src = generateProviderLogoPlaceholder();
        target.style.opacity = ".15";
        if (theme === "dark") {
          target.style.filter = "invert(1)";
        }
      }}
    />
  );
}

ProviderLogo.propTypes = {
  product: isProduct,
  theme: PropTypes.oneOf(["dark", "light"])
};
