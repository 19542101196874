import React, { Component } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

import "./view_sample_data.less";

import AuthSection from "../../containers/auth_section";
import RequestSampleDataPopup from "../../../../stingray/frontend/components/mdboot/modals/request_sample_data_popup";

import { getProductUrlId } from "../../utility/helpers";
import { isProduct, isUser } from "../../utility/prop-types";

export default class ViewSampleData extends Component {
  static propTypes = {
    className: PropTypes.string,
    product: isProduct,
    productId: PropTypes.number.isRequired, // eslint-disable-line react/no-unused-prop-types
    requestSampleData: PropTypes.func.isRequired,
    user: isUser
  };

  static defaultProps = {
    className: "ViewSampleData link-button-inline"
  };

  state = {
    isPopupOpen: null
  };

  get shouldDownloadSampleData() {
    const { product, user } = this.props;

    return (
      user && user.permissions && user.permissions.can_download_sample_data
      && product && product.sample_data_preview
    );
  }

  openPopup = () => this.setState({ isPopupOpen: true });

  closePopup = () => this.setState({ isPopupOpen: false });

  requestSampleData = () => {
    const { requestSampleData, product } = this.props;

    requestSampleData(product.name)
      .then(this.openPopup);
  };

  renderRequestSampleDataPopup() {
    const { isPopupOpen } = this.state;

    if (isPopupOpen === null) {
      return null;
    }

    return (
      <RequestSampleDataPopup
        isOpen={isPopupOpen}
        onClose={this.closePopup}
      />
    );
  }

  render() {
    const { className, product } = this.props;
    const title = "sample data";

    if (this.shouldDownloadSampleData) {
      return (
        <AuthSection title={title}>
          <Link
            className={className}
            to={`/products/${getProductUrlId(product)}/sample_data`}
          >
            View Sample Data
          </Link>
        </AuthSection>
      );
    }

    return (
      <AuthSection title={title}>
        <button
          type="button"
          className={className}
          onClick={this.requestSampleData}
        >
          Request Sample Data
        </button>
        {this.renderRequestSampleDataPopup()}
      </AuthSection>
    );
  }
}
