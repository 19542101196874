import React from "react";
import PropTypes from "prop-types";

import MultiselectFilter from "../../../containers/catalog/filters/multiselect_filter";

import { FILTER_TYPES } from "../../../utility/constants";

export default function CategoriesDropdown({ categories }) {
  return (
    <MultiselectFilter
      items={categories}
      label="Filter by Entity"
      name="categories"
      urlKey={FILTER_TYPES.CATEGORIES}
    />
  );
}

CategoriesDropdown.propTypes = {
  categories: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string
  }))
};
