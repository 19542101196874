import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import TopBar from "../../components/header/topbar";

export default withRouter(
  connect(store => ({
    isFetching: store.session.isFetching,
    user: store.session.data && store.session.data.user
  }))(TopBar)
);
