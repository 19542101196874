import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import ProductNavigation from "../../components/catalog/product_navigation";
import { isDemystUser } from "../../selectors/session";

export default withRouter(
  connect(store => ({
    isDemystUser: isDemystUser(store)
  }))(ProductNavigation)
);
