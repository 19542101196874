import { get, post } from "../helpers/network";

import { fetchEnvVariables } from "./env_variables";

export const getBlackfinUrl = () => async (dispatch) => {
  const env_variables = await dispatch(fetchEnvVariables());

  const { hub_id, atlantic_domain, tls_port } = env_variables;

  return `https://blackfin${hub_id ? `-${hub_id}` : ""}.${atlantic_domain}:${tls_port}`;
};

export const queryBlackfin = data => async (dispatch) => {
  const getBlackfinUrlPromise = dispatch(getBlackfinUrl());
  const getJwtPromise = get("/jwt/show");

  try {
    const [blackfinUrl, { jwt }] = await Promise.all([getBlackfinUrlPromise, getJwtPromise]);

    const dataForRequest = {
      ...data,
      api_key: jwt
    };

    return post(`${blackfinUrl}/v2/execute`, { body: dataForRequest });
  } catch (error) {
    throw new Error("Failed to query Blackfin");
  }
};
