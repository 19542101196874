import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

// Actions
import { fetchUseCaseDataServiceJobs } from "../../actions/use_cases";

// Selectors
import {
  getUseCaseDataServiceJobs,
  isFetchingUseCaseDataServiceJobs
} from "../../selectors/use_cases";

// Components
import DataServiceJobs from "../../components/use_case/data_service_jobs";

// Helpers
import { getIdFromParams } from "../../utility/helpers";

export default withRouter(
  connect(
    (store, ownProps) => {
      const useCaseId = getIdFromParams(ownProps.match.params);

      if (!useCaseId) {
        return {};
      }

      return {
        jobs: getUseCaseDataServiceJobs(store, useCaseId),
        isFetching: isFetchingUseCaseDataServiceJobs(store),
        useCaseId
      };
    },
    {
      fetchUseCaseDataServiceJobs
    }
  )(DataServiceJobs)
);
