import {
  get, post, del, put
} from "../helpers/network";

export const GET_SESSION_REQUEST = "GET_SESSION_REQUEST";
export const GET_SESSION_SUCCESS = "GET_SESSION_SUCCESS";
export const GET_SESSION_ERROR = "GET_SESSION_ERROR";
export const RESET_PASSWORD_REQUEST = "RESET_PASSWORD_REQUEST";
export const RESET_PASSWORD_SUCCESS = "RESET_PASSWORD_SUCCESS";
export const RESET_PASSWORD_ERROR = "RESET_PASSWORD_ERROR";
export const LOGIN_REQUEST = "LOGIN_REQUEST";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_ERROR = "LOGIN_ERROR";
export const LOGOUT_REQUEST = "LOGOUT_REQUEST";
export const LOGOUT_SUCCESS = "LOGOUT_SUCCESS";
export const LOGOUT_ERROR = "LOGOUT_ERROR";
export const SESSION_EXPIRED = "SESSION_EXPIRED";

export const fetchSession = () => async (dispatch, getState) => {
  const store = getState();

  if (store.session.isFetching || store.session.data) {
    // Request for (somewhat) static data - no need for multiple requests
    return Promise.resolve();
  }

  dispatch({ type: GET_SESSION_REQUEST });

  try {
    const { organization, user } = await get("/jwt/user_data");
    dispatch({
      type: GET_SESSION_SUCCESS,
      organization,
      user
    });
    return {
      organization,
      user
    };
  } catch (error) {
    dispatch({
      type: GET_SESSION_ERROR,
      error
    });
    throw error;
  }
};

export const logIn = (data, g_recaptcha_response) => async (dispatch, getState) => {
  const store = getState();

  if (store.session.isFetching) {
    // Request for (somewhat) static data - no need for multiple requests
    return Promise.resolve();
  }

  dispatch({ type: LOGIN_REQUEST });

  try {
    const { organization, user } = await post(
      "/users/sign_in",
      {
        body: {
          user: data,
          g_recaptcha_response
        }
      }
    );

    dispatch({
      type: LOGIN_SUCCESS,
      organization,
      user
    });
    return {
      organization,
      user
    };
  } catch (error) {
    dispatch({
      type: LOGIN_ERROR,
      error
    });
    throw error;
  }
};

export const logOut = () => (dispatch, getState) => {
  const store = getState();

  if (store.session.isFetching) {
    // Request for (somewhat) static data - no need for multiple requests
    return Promise.resolve();
  }

  dispatch({ type: LOGOUT_REQUEST });

  del("/users/sign_out")
    .then(() => {
      dispatch({
        type: LOGOUT_SUCCESS
      });
    })
    .catch((error) => {
      dispatch({
        type: LOGOUT_ERROR,
        error
      });
    });
};

export const resetPassword = (data, captchaResponse) => async (dispatch) => {
  dispatch({ type: RESET_PASSWORD_REQUEST });

  try {
    const { organization, user } = await put(
      "/users/password",
      {
        body: {
          user: data,
          g_recaptcha_response: captchaResponse
        }
      }
    );

    dispatch({
      type: RESET_PASSWORD_SUCCESS,
      organization,
      user
    });
  } catch (error) {
    dispatch({
      type: RESET_PASSWORD_ERROR,
      error
    });
    throw error;
  }
};
