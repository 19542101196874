import React from "react";
import PropTypes from "prop-types";
import { parse } from "query-string";

import "./attribute_cards.less";

import AttributeCard from "../../containers/attribute_catalog/attribute_card";
import AttributeCardPlaceholder from "../../placeholders/attribute_card";
import Paginate from "../../containers/attribute_catalog/paginate";

import { isLocation, isAttribute } from "../../utility/prop-types";
import { useDebouncedEffect } from "../../utility/hooks";
import { isEmptyObject } from "../../utility/helpers";

export default function AttributeCards({
  fetchAttributes,
  fetchAttributesSearch,
  location,
  attributes
}) {
  useDebouncedEffect(() => {
    if (attributes) {
      return;
    }

    const params = parse(location.search, { arrayFormat: "bracket" });

    if (!isEmptyObject(params)) {
      fetchAttributesSearch(params);
    } else {
      fetchAttributes();
    }
  }, [location], 400);

  return (
    <div className="AttributeCards">
      <ul className="AttributeCards__list">
        {attributes
          ? (
            <div>
              {attributes.map(attribute => (
                <li className="AttributeCards__list__item" key={attribute.id}>
                  <AttributeCard attribute={attribute} />
                </li>
              ))}
            </div>
          )
          : (
            Array.from(new Array(5)).map((element, index) => (
              <li className="AttributeCards__list__item" key={index}>
                <AttributeCardPlaceholder index={index} />
              </li>
            ))
          )
        }
      </ul>
      {attributes && <Paginate />}
    </div>
  );
}

AttributeCards.propTypes = {
  attributes: PropTypes.arrayOf(isAttribute),
  fetchAttributes: PropTypes.func.isRequired,
  fetchAttributesSearch: PropTypes.func.isRequired,
  location: isLocation.isRequired
};
