import { applyMiddleware, compose, createStore as internalCreateStore } from "redux";
import thunk from "redux-thunk";

import reducer from "./reducers";

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const enhancer = composeEnhancers(
  applyMiddleware(thunk),
);

const createStore = (initialState = {}) => internalCreateStore(
  reducer,
  initialState,
  enhancer,
);

const store = createStore({});

export default store;
