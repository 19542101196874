import React from "react";
import PropTypes from "prop-types";
import {
  Route,
  Switch,
  Redirect
} from "react-router-dom";

import { useCMSAPICollection } from "../../../utility/hooks";

import HostedNotebookContent from "./hosted_notebook_content";

import "./hosted_notebook.less";


export default function HostedNotebook({
  match: { path }
}) {
  const pageData = useCMSAPICollection(["hosted_notebooks"]);

  if (!pageData) {
    return null;
  }

  const {
    data: {
      hosted_notebooks: [
        {
          items
        }
      ]
    }
  } = pageData;


  return (
    <>
      <Switch>
        <Redirect
          exact
          from={path}
          to={`${path}/${items[0].key}`}
        />
        <Route path={`${path}/:slug`} component={HostedNotebookContent} />
      </Switch>
    </>
  );
}

HostedNotebook.propTypes = {
  match: PropTypes.shape({
    path: PropTypes.string.isRequired
  }).isRequired
};
