import React from "react";
import { parse } from "query-string";

import { getSortByPropSorter } from "../../../marlin/src/utility/helpers";
import { toBreakableString } from "./string";
import { toFuzzyRegExp } from "./search";

export const getSearchQuery = ({ location, withSearch, search }) => {
  if (search) {
    return search;
  }

  const searchParam = withSearch === true ? "search" : withSearch;

  if (!searchParam) {
    return null;
  }

  const urlParams = parse(location.search);
  const { [searchParam]: searchFromUrl } = urlParams;

  return searchFromUrl;
};

export const getSortRowsSorter = ({ sortBy, sortDir }) => {
  function transformer(value) {
    switch (typeof value) {
      case "boolean":
        return value ? 1 : 0;
      case "object":
        if (value instanceof Date) {
          return value;
        }

        // Don't sort
        return 0;
      default:
        return value;
    }
  }

  return getSortByPropSorter(sortBy, sortDir !== -1, transformer);
};

export const formatValue = (value, addWordBreaks) => {
  if (Array.isArray(value)) {
    return value.map(formatValue).join(", ");
  }

  switch (typeof value) {
    case "boolean":
      return value.toString();
    case "number":
      return value.toLocaleString("en-US");
    case "object":
      if (value instanceof Date) {
        return value.toLocaleString("en-US");
      }

      if (React.isValidElement(value)) {
        return value;
      }

      if (value === null) {
        return "";
      }

      return JSON.stringify(value);
    case "string":
      if (addWordBreaks) {
        return toBreakableString(value);
      }
      /* falls through */
    default:
      if (value && value.toString) {
        return value.toString();
      }

      return value;
  }
};

export const matchValue = (value, search) => {
  const formattedValue = formatValue(value, false);
  switch (typeof formattedValue) {
    case "object":
      return false;
    default:
      return formattedValue.match(toFuzzyRegExp(search));
  }
};

export const isSortable = headerCell => (
  typeof headerCell === "boolean"
    || typeof headerCell === "number"
    || typeof headerCell === "string"
    || headerCell instanceof Date
);
