import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

// Actions
import { fetchDataCategories } from "../../../actions/data_categories";

// Selectors
import { getDataCategories } from "../../../selectors/data_categories";

// Components
import DataCategoriesFilter from "../../../components/attribute_catalog/filters/data_categories_filter";

export default withRouter(connect(
  store => ({
    dataCategories: getDataCategories(store)
  }),
  {
    fetchDataCategories
  }
)(DataCategoriesFilter));
