import React, { useRef, useState } from "react";
import PropTypes from "prop-types";
import classNames from "merge-class-names";
import { UserAvatar } from "../ui/user_avatar";
import { isUser } from "../../utility/prop-types";
import { post } from "../../helpers/network";

import "./note_editor.less";

export default function NoteEditor({
  createNoteEvent,
  isDemystUserNotOnRDP,
  useCaseId,
  user
}) {
  const [note, setNote] = useState("");
  const [isInternalChecked, setIsInternalChecked] = useState(false);
  const [s3Response, setS3Response] = useState(null);
  const inputFileField = useRef();

  const onSubmit = async (e) => {
    e.preventDefault();
    e.stopPropagation();
    // Do not allow user to click button while the following actions are being performed
    // The button will reset whenever the note state changes
    e.target.disabled = true;

    if (!note && !s3Response) {
      return false;
    }

    if (s3Response) {
      const { file, s3ObjectId, s3Url } = s3Response;
      try {
        // Post file to s3
        await fetch(s3Url, {
          method: "PUT",
          body: file
        });
        // Tell manta that it was successful
        await post(`/edaas/${useCaseId}/upload_successful`, {
          body: {
            s3_object_id: s3ObjectId
          }
        });
        inputFileField.current.value = "";
        setS3Response(null);
      } catch (error) {
        console.log("[ERROR] - error in submitting forms", error.message);
      }
    }

    if (note) {
      try {
        createNoteEvent(
          {
            internal: isInternalChecked,
            name: note
          },
          useCaseId,
          s3Response?.s3ObjectId
        );
        setNote("");
      } catch (error) {
        console.log("[ERROR] - error creating note event", error.message);
      }
    }
  };

  const onChange = async (e) => {
    if (useCaseId) {
      const [file] = e.target.files;
      try {
        const s3UrlResp = await post(`/edaas/${useCaseId}/upload_url`, {
          body: {
            filename: file.name
          }
        });
        const { signed_url, s3_object_id } = s3UrlResp;
        setS3Response({
          file,
          s3ObjectId: s3_object_id,
          s3Url: signed_url
        });
      } catch (error) {
        console.log("[ERROR] - cannot fetch for some reason", error.message);
        return false;
      }
    }
  };

  return (
    <div className="NoteEditor">
      <div
        className={classNames(
          "NoteEditor__body",
          isInternalChecked && "NoteEditor__body--internal"
        )}
      >
        {user && <UserAvatar user={user} />}
        <textarea
          name="textarea"
          placeholder="Add a note..."
          value={note}
          onChange={e => setNote(e.target.value)}
        />
      </div>
      <div className="NoteEditor__footer">
        <input
          type="file"
          disabled={isDemystUserNotOnRDP}
          ref={inputFileField}
          onChange={onChange}
        />
        <label>
          <input
            type="checkbox"
            checked={isInternalChecked}
            onChange={e => setIsInternalChecked(e.target.checked)}
          />
          Internal
        </label>
        <button type="button" disabled={!Boolean(note)} onClick={onSubmit}>
          Submit
        </button>
      </div>
    </div>
  );
}

NoteEditor.propTypes = {
  createNoteEvent: PropTypes.func.isRequired,
  isDemystUserNotOnRDP: PropTypes.bool.isRequired,
  useCaseId: PropTypes.number,
  user: isUser
};
