import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import classNames from "merge-class-names";
import { withRouter } from "react-router-dom";
import isEqual from "lodash.isequal";

import Search from "./search";

import { highlightPattern, toFuzzyRegExp } from "../../utility/search";
import {
  formatValue, getSearchQuery, isSortable, getSortRowsSorter, matchValue
} from "../../utility/table";

const DISPLAY_NUMBERS_COLUMN_KEY = "ID";

const dataPropType = PropTypes.arrayOf(PropTypes.shape({}));

export class ComplexTable extends PureComponent {
  static propTypes = {
    /**
     * An Array of rows where each row is an object with column fields as keys and cells as values
     * [
     *   {                                ---------------------------------
     *     column1Header: "row1Cell1",    | column1Header | column2Header |
     *     column2Header: "row1Cell2"     --------------- | ---------------
     *   },                               | row1Cell1     | row1Cell2     |
     *   {                                --------------- | ---------------
     *     column1Header: "row2Cell1",    | row2Cell1     | row2Cell2     |
     *     column2Header: "row2Cell2"     ---------------------------------
     *   }
     * ]
     */
    data: dataPropType,
    displayHeaderOnEmptyMatches: PropTypes.bool, // Render header even if there is no matching data
    displayNumbers: PropTypes.bool, // Inserts a new column in the beginning with index positions
    filteredData: dataPropType, // if isFiltered is active, will use this instead of data to render the table
    hideSearch: PropTypes.bool, // useful if you want to implement your own search functionality with passing in the `withSearch` flag
    /**
     * Allows us to know if the table is empty due to there being no data passed in
     * or because the data was filtered **externally** and there are just no matching results
     * Note: DO NOT pass in the `isFiltered` flag for data filtered by search since this component already knows about it
    */
    isFiltered: PropTypes.bool,
    location: PropTypes.shape({
      search: PropTypes.string
    }).isRequired,
    onFilteredDataFromSearch: PropTypes.func, // Callback with filtered data from search in case you want to do any extra processing
    renderHeader: PropTypes.func,
    search: PropTypes.string, // If given, will be used instead of search query from URL (see docs for withSearch).
    searchPlaceholder: PropTypes.string.isRequired,
    /**
     * Given `true`: Will look for "search" param in the URL and use it as a query for row filtering and highlighting.
     * Given string: Will look for a given param in the URL and use it as a query for row filtering and highlighting.
     * Given falsy value (default): Search will be disabled in this table.
     * Note: Search will not work for non-stringifiable values, like React nodes.
     */
    withSearch: PropTypes.oneOfType([
      PropTypes.bool,
      PropTypes.string
    ])
  };

  static defaultProps = {
    searchPlaceholder: "Search…"
  };

  state = {
    sortBy: null,
    sortDir: null // 1 - asc, -1 - desc
  };

  componentDidUpdate(prevProps) {
    const { data, onFilteredDataFromSearch, withSearch } = this.props;
    const { search } = this;

    const prevSearchFromUrl = getSearchQuery({
      location: prevProps.location,
      withSearch: prevProps.withSearch,
      search: prevProps.search
    });

    // Gives components the ability to know the results of the search
    if (withSearch && onFilteredDataFromSearch && (!isEqual(prevSearchFromUrl, search) || !isEqual(prevProps.data, data))) {
      if (!search) {
        return onFilteredDataFromSearch(null);
      }
      const filteredData = data.filter(row => Object.values(row).some(value => matchValue(value, search)));
      return onFilteredDataFromSearch(filteredData);
    }
  }

  onClickHeader = (header) => {
    this.setState((prevState) => {
      if (prevState.sortBy === header) {
        return {
          sortDir: -prevState.sortDir
        };
      } else {
        return {
          sortBy: header,
          sortDir: 1
        };
      }
    });
  }

  get headerRow() {
    const { data } = this.props;
    return data && data[0];
  }

  get headers() {
    const { headerRow } = this;
    const { displayNumbers } = this.props;

    if (!headerRow) {
      return [];
    }
    return displayNumbers ? [DISPLAY_NUMBERS_COLUMN_KEY, ...Object.keys(headerRow)] : Object.keys(headerRow);
  }

  get search() {
    const { location, withSearch, search } = this.props;
    return getSearchQuery({
      location,
      withSearch,
      search
    });
  }

  get rows() {
    const { data, isFiltered, filteredData } = this.props;
    const rows = isFiltered ? filteredData : data;
    return this.renderRows(rows, this.renderRow);
  }

  get sortRowsSorter() {
    const { sortBy, sortDir } = this.state;
    return getSortRowsSorter({
      sortBy,
      sortDir
    });
  }

  isIndexColumn = (header) => {
    const { displayNumbers } = this.props;
    return displayNumbers && header === DISPLAY_NUMBERS_COLUMN_KEY;
  }

  renderHeaderContent(header) {
    const { headerRow } = this;
    const { renderHeader } = this.props;
    const { sortBy, sortDir } = this.state;

    const canBeSorted = isSortable(headerRow[header]);

    if (renderHeader && !this.isIndexColumn(header)) {
      return renderHeader({
        isSortable: canBeSorted,
        header,
        sortBy,
        sortDir,
        onSortClick: () => this.onClickHeader(header)
      });
    }

    if (!canBeSorted) {
      return header;
    }

    return (
      <button
        type="button"
        onClick={() => this.onClickHeader(header)}
        title={`Sort table by: ${header}`}
      >
        {header} {sortBy === header ? (sortDir === 1 ? "▲" : "▼") : ""}
      </button>
    );
  }

  renderHeader() {
    const { headers } = this;
    const { displayNumbers } = this.props;

    return (
      <thead>
        <tr>
          {headers.map((header, headerIndex) => {
            const shouldBeHidden = ["Actions"].includes(header);
            return (
              <th key={header} className={classNames(shouldBeHidden && "hidden-header", displayNumbers && headerIndex === 0 && "index")}>
                {this.renderHeaderContent(header)}
              </th>
            );
          })}
        </tr>
      </thead>
    );
  }

  renderCell = (value, cellIndex) => {
    const { search } = this;
    const { displayNumbers } = this.props;
    const formattedValue = formatValue(value, false);
    const highlightedValue = highlightPattern(formattedValue, toFuzzyRegExp(search));

    return (
      <td
        key={cellIndex}
        className={classNames(displayNumbers && cellIndex === 0 && "index")}
      >
        {highlightedValue}
      </td>
    );
  }

  renderCellValue = (row, header, rowIndex) => {
    if (this.isIndexColumn(header)) {
      return rowIndex + 1;
    }
    return row[header];
  }

  renderRow = (row, rowIndex) => {
    const { headers, search } = this;

    if (search) {
      const hasMatches = Object.values(row).some(value => matchValue(value, search));

      if (!hasMatches) {
        return null;
      }
    }

    return (
      <tr key={rowIndex}>
        {headers.map((header, cellIndex) => {
          const cellValue = this.renderCellValue(row, header, rowIndex);
          return this.renderCell(cellValue, cellIndex);
        })}
      </tr>
    );
  }

  renderRows = (rows, rowRenderer) => {
    const { displayNumbers, isFiltered } = this.props;

    if (!isFiltered && !rows.length) {
      return null;
    }

    const renderedRows = rows
      .sort(this.sortRowsSorter)
      .map(rowRenderer)
      .filter(Boolean);

    if (!renderedRows.length) {
      return null;
    }

    return displayNumbers ? renderedRows.map((row, i) => ({
      index: i + 1,
      ...row
    })) : renderedRows;
  };

  renderTable() {
    const { displayHeaderOnEmptyMatches } = this.props;
    const { rows } = this;

    if (displayHeaderOnEmptyMatches) {
      return (
        <div className="Table">
          <table>
            {this.renderHeader()}
            {
              rows && (
                <tbody>
                  {rows}
                </tbody>
              )
            }
          </table>
        </div>
      );
    }

    return (
      rows && (
        <div className="Table">
          <table>
            {this.renderHeader()}
            <tbody>
              {rows}
            </tbody>
          </table>
        </div>
      )
    );
  }

  renderSearch() {
    const { hideSearch, searchPlaceholder, withSearch } = this.props;
    return (
      !hideSearch && withSearch && (
        <Search placeholder={searchPlaceholder} />
      )
    );
  }

  render() {
    const { data, isFiltered } = this.props;

    if ((!data || !data.length) && !isFiltered) {
      return null;
    }

    return (
      <>
        {this.renderSearch()}
        {this.renderTable()}
      </>
    );
  }
}

export default withRouter(ComplexTable);
