import React, { useEffect, useState } from "react";
import { parse, stringify } from "query-string";

import Range from "rc-slider/lib/Range";
import "rc-slider/assets/index.css";

import "./quality_filter.less";

import infoIcon from "../../../static/icons/info.svg";

import { isHistory, isLocation } from "../../../utility/prop-types";
import { addToQuery } from "../../../../../stingray/frontend/utility/url";

const locationOptions = { arrayFormat: "bracket" };

const getLocation = (location, urlKey, value) => {
  // TODO: Refactor this shit
  const lowKey = `${urlKey}_low`;
  const highKey = `${urlKey}_high`;

  const {
    [lowKey]: previousLowValue = null,
    [highKey]: previousHighValue = null,
    ...otherValues
  } = parse(location.search, locationOptions);

  const [low, high] = value;

  let newLowValue = null;
  if (!previousLowValue || previousLowValue !== low) {
    newLowValue = low;
  }

  let newHighValue = null;
  if (!previousHighValue || previousHighValue !== high) {
    newHighValue = high;
  }

  const newLowQuery = addToQuery(location.search, lowKey, newLowValue);
  const newHighQuery = addToQuery(location.search, highKey, newHighValue);

  const lowRangeValues = parse(newLowQuery, locationOptions);
  const highRangeValues = parse(newHighQuery, locationOptions);

  const newQuery = {
    ...otherValues,
    [lowKey]: lowRangeValues[lowKey],
    [highKey]: highRangeValues[highKey]
  };

  return {
    ...location,
    search: stringify(newQuery, locationOptions)
  };
};

const replaceFilters = (history, location, urlKey, value) => {
  history.replace(getLocation(location, urlKey, value));
};

const defaultLowValue = 0;
const defaultHighValue = 1;
const defaultValues = [defaultLowValue, defaultHighValue];
const defaultLow = value => value || defaultLowValue;
const defaultHigh = value => value || defaultHighValue;

export default function QualityFilter({
  history,
  location
}) {
  const [fillRateRange, setFillRateRange] = useState(defaultValues);
  const [matchRateRange, setMatchRateRange] = useState(defaultValues);
  const [populatedRateRange, setPopulatedRateRange] = useState(defaultValues);

  useEffect(() => {
    const {
      fill_rate_low,
      fill_rate_high,
      match_rate_low,
      match_rate_high,
      populated_rate_low,
      populated_rate_high
    } = parse(location.search, locationOptions);
    setFillRateRange([defaultLow(fill_rate_low), defaultHigh(fill_rate_high)]);
    setMatchRateRange([defaultLow(match_rate_low), defaultHigh(match_rate_high)]);
    setPopulatedRateRange([defaultLow(populated_rate_low), defaultHigh(populated_rate_high)]);
  }, [location.search]);


  const onChange = (name, values) => {
    // values -> [0.2, 0.8]
    replaceFilters(history, location, name, values);
  };

  const onFillRateChange = (values) => {
    onChange("fill_rate", values);
  };

  const onMatchRateChange = (values) => {
    onChange("match_rate", values);
  };

  const onPopulatedRateChange = (values) => {
    onChange("populated_rate", values);
  };

  function updateFillRateRange(range) {
    setFillRateRange(range);
  }

  function updateMatchRateRange(range) {
    setMatchRateRange(range);
  }

  function updatePopulatedRateRange(range) {
    setPopulatedRateRange(range);
  }

  function formatRange(range) {
    const [low, high] = range;
    return `${(low * 100).toFixed()}% - ${(high * 100.0).toFixed()}%`;
  }

  const defaultRangeProps = {
    min: 0,
    max: 1,
    step: 0.01
  };

  return (
    <div className="QualityFilter">
      <div className="QualityFilter__range">
        <div className="QualityFilter__range__title">
          <span>
            Fill Rate
          </span>
          <img
            src={infoIcon}
            alt="Fill Rate"
            title="Given a specific column, the percentage of records where this column has data populated"
          />
        </div>
        <div className="QualityFilter__range__values">
          {formatRange(fillRateRange)}
        </div>
        <div className="QualityFilter__range__slider">
          <Range
            value={fillRateRange}
            onChange={updateFillRateRange}
            onAfterChange={onFillRateChange}
            {...defaultRangeProps}
          />
        </div>
      </div>
      <div className="QualityFilter__range">
        <div className="QualityFilter__range__title">
          <span>
            Match Rate
          </span>
          <img
            src={infoIcon}
            alt="Match Rate"
            title="The percentage of submitted records where a specific data provider achieved some level of enrichment"
          />
        </div>
        <div className="QualityFilter__range__values">
          {formatRange(matchRateRange)}
        </div>
        <div className="QualityFilter__range__slider">
          <Range
            value={matchRateRange}
            onChange={updateMatchRateRange}
            onAfterChange={onMatchRateChange}
            {...defaultRangeProps}
          />
        </div>
      </div>
      <div className="QualityFilter__range">
        <div className="QualityFilter__range__title">
          <span>
            % With Null Values
          </span>
          <img
            src={infoIcon}
            alt="% with null values"
            title="The percent of records among a specific column, where there is no data is available"
          />
        </div>
        <div className="QualityFilter__range__values">
          {formatRange(populatedRateRange)}
        </div>
        <div className="QualityFilter__range__slider">
          <Range
            value={populatedRateRange}
            onChange={updatePopulatedRateRange}
            onAfterChange={onPopulatedRateChange}
            {...defaultRangeProps}
          />
        </div>
      </div>
    </div>
  );
}

QualityFilter.propTypes = {
  history: isHistory.isRequired,
  location: isLocation.isRequired
};
