import { useEffect } from "react";
import PropTypes from "prop-types";

export default function ScrollToTop({ location: { pathname } }) {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0
      // no smooth scroll for now
    });
  }, [pathname]);

  return null;
}

ScrollToTop.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired
  })
};
