@import (reference) "../../styles/colors.less";
@import (reference) "../../helpers.less";

.ProductTableToolbar {
  @item-height: 52px;

  display: flex;
  flex-wrap: wrap;
  align-items: center;
  border-bottom: 2px solid @gray-medium;

  input[type="search"] {
    width: auto;
    height: @item-height;
    max-width: unset;
    flex-grow: 1;
    margin: 0;
    padding-left: 36px;
    display: inline-block;
    background: inherit;
    border-radius: 0;
    background-image: url("../../static/icons/search.svg");
    background-repeat: no-repeat;
    background-position: 9px 50%;
  }

  &__count, &__download {
    .border-divider();
    height: @item-height;
  }

  &__count {
    display: flex;
    align-items: center;
    font-size: 14px;
    min-width: max-content;
    padding: 0 2em;

    span {
      font-weight: bold;
      color: @light-blue-lighten-1;
      margin-right: 4px;

      &:nth-child(2) {
        color: @gray-medium;
      }
    }
  }

  &__download {
    display: flex;
    align-items: center;
    .Dropdown__label__label {
      .center-content();
      margin-left: 4px;
    }
    &__icon {
      display: flex;
      margin-left: 10px;
      svg {
        path {
          fill: @gray-dark;
        }
      }
    }
  }
}
