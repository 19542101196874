import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

// Actions

// Selectors

// Components
import AuthRoute from "../components/auth_route";

export default withRouter(connect(
  store => ({
    isFetched: Boolean(store.session.fetchedAt),
    user: store.session.data && store.session.data.user
  }),
)(AuthRoute));
