import React from "react";
import PropTypes from "prop-types";

import Placeholder from "../../placeholders/placeholder";
import ProductDetailsSection from "./product_details_section";
import Tags from "../../containers/product_details/tags";

import { isProduct } from "../../utility/prop-types";

export default function DataTypes({ isPlaceholder, product }) {
  return (
    <ProductDetailsSection
      id="data-types"
      header="Use Cases"
    >
      <Placeholder
        isEnabled={isPlaceholder}
        theme="dark"
      >
        <Tags
          isPlaceholder={isPlaceholder}
          tags={product.tags}
        />
      </Placeholder>
    </ProductDetailsSection>
  );
}

DataTypes.propTypes = {
  isPlaceholder: PropTypes.bool,
  product: isProduct.isRequired
};
