import React from "react";
import { HTML5Backend } from "react-dnd-html5-backend";
import { DndProvider } from "react-dnd";

import DropFile from "./drop_file";

export default function DragAndDrop(props) {
  return (
    <DndProvider backend={HTML5Backend}>
      <DropFile {...props} />
    </DndProvider>
  );
}
