import React from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";

import { isUser } from "../../utility/prop-types";

import "./footer.less";

export default function Footer({ user }) {
  const nowYear = new Date().getFullYear();
  return (
    <footer className="Footer">
      <div className="Footer__wrapper">
        <div className="Footer__foot-note">
          <div className="Footer__foot-note__links">
            <small className="Footer__foot-note__links__text">
              © {nowYear} Demyst Data. All rights reserved.
            </small>
            <Link
              className="Footer__foot-note__links__link"
              to="/terms-and-conditions"
            >
              Terms
            </Link>
            <Link
              className="Footer__foot-note__links__link"
              to="/privacy-policy"
            >
              Privacy
            </Link>
          </div>
        </div>
      </div>
    </footer>
  );
}

Footer.propTypes = {
  isFetching: PropTypes.bool,
  user: isUser,
};
