import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

import { buttonClickGAAction, GAEvent } from "../utility/google_analytics";
import { isGAConfig } from "../utility/prop-types";

// Extract text only from children - helpful in buttons and links
const extractTextFromChildren = (children) => {
  let label = "";

  React.Children.map(children, (child) => {
    if (typeof child === "string") {
      label += child;
    }
  });

  if (label) {
    label = label.toLowerCase().trim();
  } else {
    label = "N/A";
  }

  return label;
};

export const GALink = ({
  children,
  config = {},
  to,
  ...restProps
}) => {
  const handleClick = () => {
    const extendedConfig = {
      ...config,
      action: buttonClickGAAction(extractTextFromChildren(children))
    };
    GAEvent(extendedConfig);
  };

  const isExternal = /^https?:\/\//.test(to);

  if (isExternal) {
    return (
      <a {...restProps} href={to} onClick={handleClick}>
        {children}
      </a>
    );
  }

  return (
    <Link {...restProps} to={to} onClick={handleClick}>
      {children}
    </Link>
  );
};

GALink.propTypes = {
  children: PropTypes.node.isRequired,
  config: isGAConfig,
  onClick: PropTypes.func,
  to: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape({ pathname: PropTypes.string })
  ]).isRequired
};
