import {
  GET_DATA_SOURCES_REQUEST,
  GET_DATA_SOURCES_SUCCESS,
  GET_DATA_SOURCES_ERROR,
  GET_DATA_SOURCE_REQUEST,
  GET_DATA_SOURCE_SUCCESS,
  GET_DATA_SOURCE_ERROR
} from "../actions/data_sources";

import {
  updateEntities,
  updateResults,
  extractReadableError
} from "../utility/reducers";

const defaultState = {
  entities: {},
  results: {}
};

const createEntityMap = entitiesList =>
  entitiesList.map(entity => entity.id);

export default (state = defaultState, action) => {
  switch (action.type) {
    case GET_DATA_SOURCES_REQUEST: {
      const { params = {} } = action;

      const stringifiedParams = JSON.stringify(params);

      const statePiece = {
        ...state[stringifiedParams],
        isFetching: true,
        params
      };

      return {
        ...state,
        results: updateResults(state, params, statePiece)
      };
    }
    case GET_DATA_SOURCES_SUCCESS: {
      const { data, params = {} } = action;

      const statePiece = {
        isFetching: false,
        fetchedAt: new Date(),
        error: null,
        data: createEntityMap(data),
        params
      };

      return {
        ...state,
        entities: updateEntities(state, data),
        results: updateResults(state, params, statePiece)
      };
    }

    case GET_DATA_SOURCES_ERROR: {
      const { error, params = {} } = action;

      const statePiece = {
        isFetching: false,
        error: extractReadableError(error),
        params
      };

      return {
        ...state,
        results: updateResults(state, params, statePiece)
      };
    }

    case GET_DATA_SOURCE_REQUEST: {
      return state;
    }

    case GET_DATA_SOURCE_SUCCESS: {
      const { data } = action;

      return {
        ...state,
        entities: updateEntities(state, [data])
      };
    }

    case GET_DATA_SOURCE_ERROR: {
      return state;
    }

    default:
      return state;
  }
};
