import "core-js/stable";
import "regenerator-runtime/runtime";
import "isomorphic-fetch";

import React from "react";
import { render } from "react-dom";
import { Provider } from "react-redux";
import { BrowserRouter as Router } from "react-router-dom";

import TagManager from "react-gtm-module";
import ReactGA from "react-ga";

import store from "./store";

import "./index.less";

import Root from "./containers/root";

const tagManagerArgs = {
  gtmId: "GTM-KDMXC83"
};

const universalAnalyticsId = "UA-44063675-39";

// Send Google Tag Manager tracking code
TagManager.initialize(tagManagerArgs);
ReactGA.initialize(universalAnalyticsId, {
  debug: process.env.NODE_ENV === "development"
});

const root = document.getElementById("react-root");

render(
  <Provider store={store}>
    <Router>
      <Root />
    </Router>
  </Provider>,
  root,
);
