import React from "react";

import { isHistory, isLocation } from "../../../utility/prop-types";


const getLocation = location => ({
  ...location,
  search: null
});

const replaceFilters = (history, location) => {
  history.replace(getLocation(location));
};

export default function ResetFilter({
  history,
  location
}) {
  const resetFilters = () => replaceFilters(history, location);

  return (
    <button type="button" onClick={resetFilters}>
      Reset All Filters
    </button>
  );
}

ResetFilter.propTypes = {
  history: isHistory.isRequired,
  location: isLocation.isRequired
};
