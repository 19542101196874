import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

// Actions

// Selectors

// Components
import ProductCard from "../../components/catalog/product_card";

export default withRouter(connect(
  (store, ownProps) => {
    const { id: urlId } = ownProps.match.params;

    if (!urlId) {
      return {};
    }

    const id = urlId.split("-").pop();
    const activeProductId = parseInt(id, 10);

    return {
      activeProductId
    };
  }
)(ProductCard));
