import React from "react";

import "./filters.less";

import CostFilter from "../../containers/attribute_catalog/filters/cost_filter";
import QualityFilter from "../../containers/attribute_catalog/filters/quality_filter";
// For Phase 2 Release
// import SecurityFilter from "../../containers/attribute_catalog/filters/security_filter";
import DataCategoriesFilter from "../../containers/attribute_catalog/filters/data_categories_filter";
import DataProviderFilter from "../../containers/attribute_catalog/filters/data_provider_filter";
import DataTypeFilter from "../../containers/attribute_catalog/filters/data_type_filter";
import ResetFilter from "../../containers/attribute_catalog/filters/reset_filter";
import UseCasesFilter from "../../containers/attribute_catalog/filters/use_cases_filter";

export default function Filters() {
  return (
    <div className="Filters">
      <div className="Filters__title">
        Filters
      </div>
      <div className="Filters__section">
        <div className="Filters__section__title">
          Entities
        </div>
        <DataCategoriesFilter />
      </div>
      <div className="Filters__section">
        <div className="Filters__section__title">
          Use Cases
        </div>
        <UseCasesFilter />
      </div>
      <div className="Filters__section">
        <div className="Filters__section__title">
          Data Types
        </div>
        <DataTypeFilter />
      </div>
      <div className="Filters__section">
        <div className="Filters__section__title">
          Data Products
        </div>
        <DataProviderFilter />
      </div>
      <div className="Filters__section">
        <div className="Filters__section__title">
          Price
        </div>
        <CostFilter />
      </div>
      <div className="Filters__section">
        <div className="Filters__section__title">
          Quality
        </div>
        <QualityFilter />
      </div>
      {/* For Phase 2 Release */}
      {/* <div className="Filters__section">
        <div className="Filters__section__title">
          Security
        </div>
        <SecurityFilter />
      </div> */}
      <div className="Filters__section">
        <ResetFilter />
      </div>
    </div>
  );
}
