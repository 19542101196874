import { withRouter } from "react-router-dom";

// Actions

// Selectors

// Components
import FilterChips from "../../../components/catalog/filters/filter_chips";

export default withRouter(FilterChips);
