import React from "react";

import "./spinner.less";

function Spinner() {
  return (
    <div className="spinner-ring">
      <div/>
      <div/>
      <div/>
      <div/>
    </div>
  );
}

export default Spinner;
