import React, { forwardRef } from "react";
import PropTypes from "prop-types";
import classNames from "merge-class-names";

import "./cms_section.less";

const CMSSection = forwardRef(({
  children, className, col, footer, ...otherProps
}, ref) => (
  <section {...otherProps} className={classNames("CMSSection", className)} ref={ref}>
    <div
      className={classNames(
        "CMSSection__container",
        col && "CMSSection__container--col"
      )}
    >
      {children}
    </div>
    {footer && <div className={`${className}__footer`}>{footer}</div>}
  </section>
));

CMSSection.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  col: PropTypes.bool,
  footer: PropTypes.node
};

export default CMSSection;
