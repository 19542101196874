import { withRouter } from "react-router-dom";

// Actions

// Selectors

// Components
import SortDropdown from "../../../components/catalog/filters/sort_dropdown";

export default withRouter(SortDropdown);
