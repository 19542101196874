import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { parse } from "query-string";

// Actions

// Selectors
import { getCategories } from "../../../selectors/products";

// Components
import CategoriesDropdown from "../../../components/catalog/filters/categories_dropdown";

export default withRouter(connect(
  (store, ownProps) => {
    const { search } = parse(ownProps.location.search);
    const params = { search };

    return {
      categories: getCategories(store, params)
    };
  }
)(CategoriesDropdown));
