import React, { useEffect } from "react";

import { useCMSAPISinglePage } from "../../../utility/hooks";
import { GAPageView } from "../../../utility/google_analytics";

import Head from "../../head";
import StaticPage from "../static_page/static_page";

export default function PrivacyPolicy() {
  const pageData = useCMSAPISinglePage("privacy-policy");

  useEffect(() => {
    pageData && GAPageView(pageData?.data?.fields?.metatitle);
  }, [pageData]);

  if (!pageData) {
    return null;
  }

  const {
    data: {
      fields: {
        body,
        metadescription,
        metatitle,
        title
      }
    }
  } = pageData;

  return (
    <StaticPage body={body} title={title}>
      <Head description={metadescription} title={metatitle} />
    </StaticPage>
  );
}
