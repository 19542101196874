import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

import "./product_table.less";

import TableViewer from "../../../../stingray/frontend/components/ui/table_viewer";
import ProductTableHeader from "./product_table_header";
import ProductTableToolbar from "./product_table_toolbar";

import { createAndAppendDownloadLink, removeChildElement } from "../../../../stingray/frontend/utility/dom";
import { createCSV, createBlob, getBlobUrl } from "../../utility/csv";
import { getProductAliasOrName, isEmptyObject, removeEmptyObjectPair } from "../../utility/helpers";
import { isProduct } from "../../utility/prop-types";
import { GAPageView } from "../../utility/google_analytics";


const ProductTable = ({
  customDownloadUrl, // TODO: Test download functionality with E2E/integration test
  downloadFileName,
  noDataMessage,
  product,
  renderNoDataMessage,
  tableData
}) => {
  const [activeColumnFilters, setActiveColumnFilters] = useState({});
  const [filteredDataFromSearch, setFilteredDataFromSearch] = useState(null);

  useEffect(() => {
    // send a page view for data viewer
    product && GAPageView("Data Catalog");
  }, [product]);

  if (!product || !tableData) {
    return null;
  }

  if (renderNoDataMessage) {
    return (
      <div className="ProductTable__no-data">
        {noDataMessage || (
          <span className="ProductTable__no-data__message">
            There is no data for {getProductAliasOrName(product)}
          </span>
        )}
      </div>
    );
  }

  const isItemChecked = (item, header) => (
    activeColumnFilters[header] && activeColumnFilters[header].includes(item)
  );

  const onFilterSelect = (selectedValues, header) => {
    const newFilters = removeEmptyObjectPair({
      ...activeColumnFilters,
      [header]: selectedValues
    });
    setActiveColumnFilters(newFilters);
  };

  const areFiltersSelected = !isEmptyObject(activeColumnFilters);
  const isSearchFromUrlActive = !!filteredDataFromSearch;
  const isFiltered = areFiltersSelected || isSearchFromUrlActive;
  const filteredData = (() => {
    if (!isFiltered) {
      return tableData;
    }
    const data = isSearchFromUrlActive ? filteredDataFromSearch : tableData;

    return data.filter(row => Object.keys(activeColumnFilters).every(key => activeColumnFilters[key].includes(row[key])));
  })();

  const downloadData = (data, prefix = "full") => {
    const csv = createCSV(data);
    const csvBlob = createBlob(csv);
    const downloadUrl = customDownloadUrl || getBlobUrl(csvBlob);
    const fileName = `${product.name}_${prefix}_${downloadFileName ? downloadFileName : "data"}.csv`;

    if (navigator.msSaveBlob) {
      // IE Compatibility
      navigator.msSaveBlob(csvBlob, fileName);
    } else {
      const link = createAndAppendDownloadLink({
        href: downloadUrl,
        download: fileName
      });
      link.click();
      removeChildElement(link);
    }
  };

  const onDownloadFilteredDataset = () => {
    if (!isFiltered || filteredData.length === 0) {
      return null;
    }

    downloadData(filteredData, "filtered");
  };

  const onDownloadFullDataset = () => {
    if (tableData.length === 0) {
      return null;
    }

    downloadData(tableData);
  };

  const onFilteredDataFromSearch = (filteredData) => {
    setFilteredDataFromSearch(filteredData);
  };

  return (
    <div className="ProductTable">
      <ProductTableToolbar
        areFiltersSelected={isFiltered}
        dataLength={tableData.length}
        filteredDataLength={filteredData.length}
        onDownloadFilteredDataset={onDownloadFilteredDataset}
        onDownloadFullDataset={onDownloadFullDataset}
      />
      <TableViewer
        data={tableData}
        displayHeaderOnEmptyMatches
        displayNumbers
        hideSearch
        isFiltered={areFiltersSelected}
        filteredData={filteredData}
        onFilteredDataFromSearch={onFilteredDataFromSearch}
        renderHeader={(data) => {
          const { header } = data;
          return (
            <ProductTableHeader
              {...data}
              activeColumn={!!activeColumnFilters[header]}
              currentColumn={tableData.map(row => row[header])}
              isItemChecked={isItemChecked}
              onFilterSelect={onFilterSelect}
            />
          );
        }}
        withSearch
      />
    </div>
  );
};

ProductTable.propTypes = {
  customDownloadUrl: PropTypes.string,
  downloadFileName: PropTypes.string,
  noDataMessage: PropTypes.node,
  product: isProduct,
  renderNoDataMessage: PropTypes.bool,
  tableData: PropTypes.array
};

export default ProductTable;
