import {
  OPEN_SUBSCRIBE_FORM,
  CLOSE_SUBSCRIBE_FORM
} from "../actions/subscribe_form";

const defaultState = {
  isOpen: true
};

export default (state = defaultState, action) => {
  switch (action.type) {
    case OPEN_SUBSCRIBE_FORM: {
      return {
        ...state,
        isOpen: true
      };
    }
    case CLOSE_SUBSCRIBE_FORM: {
      return {
        ...state,
        isOpen: false
      };
    }
    default: return state;
  }
};
