import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { parse } from "query-string";

// Actions
import { fetchProducts, fetchProductsSearch } from "../../actions/products";

// Selectors
import { getFilteredSortedProducts } from "../../selectors/products";

// Components
import ProductCards from "../../components/catalog/product_cards";

export default withRouter(connect(
  (store, ownProps) => {
    const {
      categories,
      regions,
      search,
      sort_by,
      sort
    } = parse(ownProps.location.search, { arrayFormat: "bracket" });
    const params = { search };
    const filterOptions = {
      categories,
      regions,
      sort_by,
      sort
    };

    return {
      products: getFilteredSortedProducts(store, params, filterOptions)
    };
  },
  {
    fetchProducts,
    fetchProductsSearch
  }
)(ProductCards));
