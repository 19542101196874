import React from "react";
import PropTypes from "prop-types";

import "./header_with_filters.less";

import { FILTER_TYPES } from "../../utility/constants";
import { isHistory, isLocation } from "../../utility/prop-types";
import { addToQuery } from "../../../../stingray/frontend/utility/url";

const urlParamsToClear = ["sort", "sort_by", FILTER_TYPES.CATEGORIES, FILTER_TYPES.REGIONS];

const getLocation = (location) => {
  let newQuery = location.search;
  urlParamsToClear.forEach((urlKey) => {
    newQuery = addToQuery(newQuery, urlKey, []);
  });

  return {
    ...location,
    search: newQuery
  };
};

const clearFilters = (history, location) => {
  history.replace(getLocation(location));
};

export default function ClearFilterButton({
  areFiltersEnabled,
  history,
  location
}) {
  return areFiltersEnabled ? (
    <button
      type="button"
      className="HeaderWithFilters__clearButton"
      onClick={() => clearFilters(history, location)}
    >
      Clear Filter
    </button>
  ) : null;
}

ClearFilterButton.propTypes = {
  areFiltersEnabled: PropTypes.bool,
  history: isHistory.isRequired,
  location: isLocation.isRequired
};
