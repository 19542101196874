import React from "react";
import PropTypes from "prop-types";

import Popup from "../../ui/popup";

const RequestSampleDataPopup = ({ isOpen, onClose }) => (
  <Popup
    className="RequestSampleDataPopup"
    isOpen={isOpen}
    onClose={onClose}
    title="Success!"
  >
    <p>Your request is being reviewed</p>
    <p>
      You will receive an email shortly with further instructions on how to access sample data for this data product.
      For additional questions, contact:{" "}<a href="mailto:client@demystdata.com">client@demystdata.com</a>
    </p>
    <button type="button" onClick={onClose}>
      CONTINUE TO PLATFORM
    </button>
  </Popup>
);

RequestSampleDataPopup.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func.isRequired
};

export default RequestSampleDataPopup;
