import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

import Placeholder from "../../placeholders/placeholder";
import DownloadIcon from "../../static/icons/components/download";
import UseCaseButton from "./use_case_button";

import { humanizedDate, humanizedTime } from "../../utility/date";
import { toNaturalSentence } from "../../utility/string";

import "./attached_file_button.less";

export default function AttachedFileButton({
  disabled,
  file,
  isPlaceholder,
  type
}) {
  const generateDate = () => {
    const createdAt = file?.created_at;
    if (isPlaceholder) {
      // Don't do any calculations on a placeholder value since its not a valid date
      return createdAt;
    }
    return `${humanizedDate(createdAt)} ${humanizedTime(createdAt)}`;
  };

  const content = () => {
    const excludeFromDownloadables = ["AegeanBatchRun"];
    const isDownloadable = !disabled
          && !excludeFromDownloadables.includes(type)
          && file?.presigned_get_url
          && file?.expires_in_the_future
          && !file?.perished;

    const getFileName = () => {
      switch (type) {
        case "AegeanBatchExport":
          return `Export #${file?.id}`;
        case "note":
        case "S3Object":
          return file?.display_key;
        default:
          return file?.name;
      }
    };

    const renderIcon = () => {
      if (!isDownloadable) {
        return null;
      }
      if (isPlaceholder) {
        return <DownloadIcon />;
      }
      return (
        <a href={file?.presigned_get_url} download>
          <DownloadIcon />
        </a>
      );
    };

    const renderTitle = () => {
      const title = toNaturalSentence(getFileName());
      // Do not make an active link when its a placeholder
      if (isPlaceholder) {
        return title;
      }
      return (
        <Link to={`/files/${file?.id}`}>
          {title}
        </Link>
      );
    };

    const body = (
      <UseCaseButton
        title={renderTitle()}
        subtitle={generateDate()}
        icon={renderIcon()}
        iconClass="AttachedFileButton__icon"
        iconPosition="right"
        disabled={disabled}
      />
    );

    return <div className="AttachedFileButton">{body}</div>;
  };

  return (
    <Placeholder isEnabled={isPlaceholder} theme="dark">
      {content()}
    </Placeholder>
  );
}

AttachedFileButton.propTypes = {
  disabled: PropTypes.bool, // Allow override to disable downloads
  file: PropTypes.shape({
    created_at: PropTypes.string.isRequired, // "2020-03-11T14:38:01.000Z"
    display_key: PropTypes.string,
    id: PropTypes.number.isRequired,
    name: PropTypes.string,
    s3_endpoint: PropTypes.string
  }),
  isPlaceholder: PropTypes.bool,
  type: PropTypes.string.isRequired
};
