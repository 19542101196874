import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { Slide, ToastContainer } from "react-toastify";

import "react-toastify/dist/ReactToastify.css";
import "./root.less";

import Main from "./main";
import ReactGA from "react-ga";

import { useMountEffect } from "../utility/hooks";

export default function Root({
  fetchPublicSessionHash,
  fetchSession,
  isDemystUser,
  isFetched,
  isLoggedIn,
  sessionHash,
  userId,
}) {
  useMountEffect(() => {
    fetchSession().catch(() => {
      // That's okay
    });
  });

  useMountEffect(() => {
    fetchPublicSessionHash().catch(() => {
      // That's okay
    });
  });

  useEffect(() => {
    if (isFetched) {
      // Only perform AFTER we have data - to avoid setting this twice when user is logged in/out
      ReactGA.set({
        dimension2: isLoggedIn.toString(),
        dimension3: userId || "none",
        dimension4: isDemystUser.toString(),
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFetched]);

  useEffect(() => {
    if (sessionHash) {
      ReactGA.set({
        dimension5: sessionHash,
      });
    }
  }, [sessionHash]);

  return (
    <div className="Root">
      <ToastContainer hideProgressBar newestOnTop transition={Slide} />
      <Main />
    </div>
  );
}

Root.propTypes = {
  fetchPublicSessionHash: PropTypes.func.isRequired,
  fetchSession: PropTypes.func.isRequired,
  // Need these to be strings for React-GA
  isDemystUser: PropTypes.bool,
  isFetched: PropTypes.bool,
  isLoggedIn: PropTypes.bool,
  sessionHash: PropTypes.string,
  userId: PropTypes.string,
};
