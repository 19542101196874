import React, { Component } from "react";
import PropTypes from "prop-types";

import { Consumer } from "./context";

export class Submit extends Component {
  static propTypes = {
    children: PropTypes.node,
    className: PropTypes.string,
    disabled: PropTypes.bool,
    formState: PropTypes.shape({
      isSubmitting: PropTypes.bool.isRequired
    }).isRequired
  };

  static defaultProps = {
    children: "Submit"
  }

  render() {
    const {
      children,
      className,
      disabled,
      formState
    } = this.props;
    const { isSubmitting } = formState;

    return (
      <button
        type="submit"
        className={className}
        disabled={disabled || isSubmitting}
      >
        {isSubmitting ? "Submitting…" : children}
      </button>
    );
  }
}

export default props => (
  <Consumer>
    {formState => (
      <Submit formState={formState} {...props} />
    )}
  </Consumer>
);
