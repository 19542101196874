import React, { useEffect } from "react";
import PropTypes from "prop-types";
import classNames from "merge-class-names";

import InfiniteScroll from "./infinite_scroll";
import ProductCard from "../../containers/catalog/product_card";
import ProductCardPlaceholder from "../../placeholders/product_card";
import Head from "../head";
import Search from "./search";
import SimpleHeaderWithNoFilters from "./simple_header_with_no_filters";

import { getProductUrlId } from "../../utility/helpers";
import { isDataSource, isLocation } from "../../utility/prop-types";
import { GAPageView } from "../../utility/google_analytics";

import "./product_data_source.less";
import "./product_cards.less";

export default function ProductDataSource({
  fetchDataSourceByName,
  dataSource,
  dataSourceName,
  location
}) {
  useEffect(() => {
    dataSourceName && fetchDataSourceByName(dataSourceName);
  }, [fetchDataSourceByName, dataSourceName]);

  useEffect(() => {
    const title = dataSourceName ? `Data Source - ${String(dataSourceName)}` : "Data Source";
    location && GAPageView(title);
  }, [location, dataSourceName]);

  return (
    <>
      {/* Only change title and description if we have data. Else default to head higher up the tree */}
      {dataSource?.name && (
        <Head
          title={`${dataSource.name} - Data Source`}
          description={dataSource.description ?? ""}
        />
      )}
      <main
        className={classNames(
          "ProductDataSource",
          `ProductDataSource--${
            /\/data-sources\/[\w-]+\/.+/i.test(location.pathname)
              ? "shared"
              : "full"
          }`
        )}
      >
        <div className="wrapper">
          <h1>Data Catalog</h1>
          <Search />
          <SimpleHeaderWithNoFilters
            numOfResults={dataSource?.table_providers?.length}
          />
          <h1 className="ProductDataSource__title">{dataSource?.name}</h1>
          {dataSource?.description && (
            <p className="ProductDataSource__subtitle">
              {dataSource.description}
            </p>
          )}
          <div className="ProductCards">
            <ul>
              {dataSource?.table_providers ? (
                <InfiniteScroll>
                  {dataSource.table_providers.map(product => (
                    <li key={product.id}>
                      <ProductCard
                        product={product}
                        customLink={`/data-sources/${dataSource.name.toLowerCase()}/${getProductUrlId(
                          product
                        )}`}
                      />
                    </li>
                  ))}
                </InfiniteScroll>
              ) : (
                Array.from(new Array(5)).map((element, index) => (
                  <li key={index}>
                    <ProductCardPlaceholder index={index} />
                  </li>
                ))
              )}
            </ul>
          </div>
        </div>
      </main>
    </>
  );
}

ProductDataSource.propTypes = {
  dataSource: isDataSource,
  dataSourceName: PropTypes.string.isRequired,
  fetchDataSourceByName: PropTypes.func.isRequired,
  location: isLocation
};
