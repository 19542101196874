import React from "react";
import PropTypes from "prop-types";

import Placeholder from "../../placeholders/placeholder";
import UseCaseButton from "./use_case_button";

import { humanizedDate, humanizedTime } from "../../utility/date";
import { isDataServiceJob } from "../../utility/prop-types";

export default function DataServiceJobButton({ job, isPlaceholder }) {
  const generateDate = () => {
    const createdAt = job.created_at;
    if (isPlaceholder) {
      // Don't do any calculations on a placeholder value since its not a valid date
      return createdAt;
    }
    return `${humanizedDate(createdAt)} ${humanizedTime(createdAt)}`;
  };

  const content = () => {
    const body = (
      <UseCaseButton
        title={job.name || `Data Service Job ${job.id}`}
        subtitle={generateDate()}
      />
    );

    if (isPlaceholder || !Boolean(job?.summary_link)) {
      // Do not make an active link when its a placeholder or when there is nothing to download
      return <div className="DataServiceJobButton">{body}</div>;
    }

    return (
      <a className="DataServiceJobButton" target="_blank" href={job.summary_link}>
        {body}
      </a>
    );
  };

  return (
    <Placeholder isEnabled={isPlaceholder} theme="dark">
      {content()}
    </Placeholder>
  );
}

DataServiceJobButton.propTypes = {
  isPlaceholder: PropTypes.bool,
  job: isDataServiceJob
};
