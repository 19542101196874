import { combineReducers } from "redux";

import attributes from "./attributes";
import data_categories from "./data_categories";
import data_sources from "./data_sources";
import env_variables from "./env_variables";
import products from "./products";
import public_session_hash from "./public_session_hash";
import public_types from "./public_types";
import file_transfers from "./file_transfers";
import sample_data from "./sample_data";
import session from "./session";
import single_lookup from "./single_lookup";
import subscribe_form from "./subscribe_form";
import tags from "./tags";
import types from "./types";
import use_cases from "./use_cases";
import use_case_data_products from "./use_case_data_products";
import use_case_data_service_jobs from "./use_case_data_service_jobs";
import use_case_events from "./use_case_events";
import use_case_models from "./use_case_models";
import use_case_users from "./use_case_users";

export default combineReducers({
  attributes,
  data_categories,
  data_sources,
  env_variables,
  products,
  public_session_hash,
  public_types,
  file_transfers,
  sample_data,
  session,
  single_lookup,
  subscribe_form,
  tags,
  types,
  use_cases,
  use_case_data_products,
  use_case_data_service_jobs,
  use_case_events,
  use_case_models,
  use_case_users
});
