import React from "react";
import PropTypes from "prop-types";

import SingleselectFilter from "../../../containers/attribute_catalog/filters/singleselect_filter";

import { getSortByPropSorter } from "../../../utility/helpers";
import { useMountEffect } from "../../../utility/hooks";
import { isPublicType } from "../../../utility/prop-types";

export default function DataTypeFilter({
  fetchPublicTypes,
  publicTypes
}) {
  useMountEffect(() => {
    fetchPublicTypes();
  });

  const formattedTypes = publicTypes?.map(({ name }) => ({
    name,
    value: name
  }));

  const data = formattedTypes?.sort(getSortByPropSorter("name", true, name => name.toUpperCase()));

  const urlKey = "demyst_type";

  return (
    <SingleselectFilter
      items={data}
      label="Filter by Data Type"
      name={urlKey}
      urlKey={urlKey}
    />
  );
}

DataTypeFilter.propTypes = {
  fetchPublicTypes: PropTypes.func.isRequired,
  publicTypes: PropTypes.arrayOf(isPublicType)
};
