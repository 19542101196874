import React from "react";

import "./use_cases_item_placeholder.less";

export default function UseCasesItemPlaceholder() {
  return (
    <div className="UseCasesItemPlaceholder">
      <div className="UseCasesItemPlaceholder__title">
        Start a New Use Case
      </div>
      <p className="UseCasesItemPlaceholder__body">
        Contact a data expert at Demyst and tell us your business problem
      </p>
    </div>
  );
}
