import { stringify } from "query-string";
import { get } from "../helpers/network";

export const GET_PRODUCTS_REQUEST = "GET_PRODUCTS_REQUEST";
export const GET_PRODUCTS_SUCCESS = "GET_PRODUCTS_SUCCESS";
export const GET_PRODUCTS_ERROR = "GET_PRODUCTS_ERROR";
export const GET_PRODUCT_REQUEST = "GET_PRODUCT_REQUEST";
export const GET_PRODUCT_SUCCESS = "GET_PRODUCT_SUCCESS";
export const GET_PRODUCT_ERROR = "GET_PRODUCT_ERROR";

const shouldFetchData = results => !results || !(results.isFetching || results.data);

export const fetchProducts = () => async (dispatch, getState) => {
  const store = getState();

  const stringifiedParams = JSON.stringify({});

  if (!shouldFetchData(store.products.results[stringifiedParams])) {
    // Request for (somewhat) static data - no need for multiple requests
    return store.products.results[stringifiedParams].data;
  }

  dispatch({ type: GET_PRODUCTS_REQUEST });

  try {
    const data = await get("/public/providers");
    dispatch({
      type: GET_PRODUCTS_SUCCESS,
      data
    });
    return data;
  } catch (error) {
    dispatch({
      type: GET_PRODUCTS_ERROR,
      error
    });
    throw error;
  }
};

export const fetchProductsSearch = params => async (dispatch, getState) => {
  const store = getState();

  const stringifiedParams = JSON.stringify(params);

  if (!shouldFetchData(store.products.results[stringifiedParams])) {
    // Request for (somewhat) static data - no need for multiple requests
    return Promise.resolve();
  }

  dispatch({
    type: GET_PRODUCTS_REQUEST,
    params
  });

  try {
    const products = await get(`/public/search?${stringify(params)}`);
    dispatch({
      type: GET_PRODUCTS_SUCCESS,
      data: products,
      params
    });
    return products;
  } catch (error) {
    dispatch({
      type: GET_PRODUCTS_ERROR,
      error,
      params
    });
    throw error;
  }
};

const isProductFullyLoaded = product => product && product.latest_version && product.tags;

export const fetchProduct = productId => (dispatch, getState) => {
  const store = getState();

  if (isProductFullyLoaded(store.products.entities[productId])) {
    // Request for (somewhat) static data - no need for multiple requests
    return Promise.resolve();
  }

  dispatch({ type: GET_PRODUCT_REQUEST });

  return get(`/public/providers/${productId}`)
    .then((product) => {
      dispatch({
        type: GET_PRODUCT_SUCCESS,
        data: product
      });
    })
    .catch((error) => {
      dispatch({
        type: GET_PRODUCT_ERROR,
        error
      });
    });
};
