import React from "react";
import PropTypes from "prop-types";
import { parse } from "query-string";

import SingleselectDropdown from "../../../../../stingray/frontend/components/ui/singleselect_dropdown/singleselect_dropdown";

import { addToQuery } from "../../../../../stingray/frontend/utility/url";
import { isHistory, isLocation } from "../../../utility/prop-types";

const getLocation = (location, urlKey, value) => {
  const newValue = value ? value : null;

  const newQuery = addToQuery(location.search, urlKey, newValue);

  return {
    ...location,
    search: newQuery
  };
};

const replaceFilters = (history, location, urlKey, value) => {
  history.replace(getLocation(location, urlKey, value));
};

export default function SingleselectFilter({
  history,
  items,
  label,
  location,
  name,
  urlKey
}) {
  const {
    [urlKey]: value = null
  } = parse(location.search, { arrayFormat: "bracket" });

  return (
    <SingleselectDropdown
      disabled={!items}
      items={items && items.map(({ name: itemName, value: itemValue }) => {
        const itemVal = itemValue || itemName;
        return {
          checked: value === itemVal,
          label: itemName,
          value: itemVal,
          onItemChecked: (event) => {
            const { value } = event.target;
            replaceFilters(history, location, urlKey, value.toString());
          }
        };
      })}
      label={label}
      name={name}
      value={value}
    />
  );
}

SingleselectFilter.propTypes = {
  history: isHistory.isRequired,
  items: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string
  })),
  label: PropTypes.string.isRequired,
  location: isLocation.isRequired,
  name: PropTypes.string.isRequired,
  urlKey: PropTypes.string.isRequired
};
