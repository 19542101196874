import React from "react";
import PropTypes from "prop-types";
import { sanitize } from "dompurify";

export default function SafeContent({ children, ...otherProps }) {
  const sanitizedBody = () => ({ __html: sanitize(children) });

  return (
    <div {...otherProps} dangerouslySetInnerHTML={sanitizedBody()} />
  );
}

SafeContent.propTypes = {
  children: PropTypes.node.isRequired
};
