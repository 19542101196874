import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

// Selectors
import { getPaginationInfo } from "../../selectors/attributes";

// Components
import Paginate from "../../components/attribute_catalog/paginate";

export default withRouter(connect(
  store => ({
    paginationInfo: getPaginationInfo(store)
  })
)(Paginate));
