import {
  GET_SINGLE_LOOKUP_CREDITS_REQUEST,
  GET_SINGLE_LOOKUP_CREDITS_SUCCESS,
  GET_SINGLE_LOOKUP_CREDITS_ERROR,
  USE_SINGLE_LOOKUP_CREDIT_REQUEST,
  USE_SINGLE_LOOKUP_CREDIT_SUCCESS,
  USE_SINGLE_LOOKUP_CREDIT_ERROR
} from "../actions/single_lookup";

const defaultState = {
  isFetching: false,
  fetchedAt: null,
  error: null,
  data: null
};

export default (state = defaultState, action) => {
  switch (action.type) {
    case USE_SINGLE_LOOKUP_CREDIT_REQUEST:
    case GET_SINGLE_LOOKUP_CREDITS_REQUEST: {
      return {
        ...state,
        isFetching: true
      };
    }
    case USE_SINGLE_LOOKUP_CREDIT_SUCCESS:
    case GET_SINGLE_LOOKUP_CREDITS_SUCCESS: {
      const { data } = action;

      return {
        ...state,
        isFetching: false,
        fetchedAt: new Date(),
        error: null,
        data
      };
    }
    case USE_SINGLE_LOOKUP_CREDIT_ERROR:
    case GET_SINGLE_LOOKUP_CREDITS_ERROR: {
      const { error } = action;

      return {
        ...state,
        isFetching: false,
        error
      };
    }
    default: return state;
  }
};
