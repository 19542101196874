import React, { useState } from "react";
import { stringify } from "query-string";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";

import OrganizationSelect from "./organization_select";
import DragAndDrop from "./drag_and_drop";
import OrganizationUsersSelect from "./organization_users_select";
import RegionSelect from "./region_select";

import { get, put } from "../../helpers/network";
import { registerFileTransferWithManta } from "../../actions/file_transfers";
import { isDemystUser } from "../../selectors/session";

import CloseIcon from "../../static/icons/components/close";

import "./upload_file.less";

export default function UploadFile() {
  const [attachedFile, setAttachedFile] = useState(null);
  const [selectedOrgId, setSelectedOrgId] = useState(null);
  const [selectedUserIds, setSelectedUserIds] = useState(null);
  const [selectedRegionCode, setSelectedRegionCode] = useState(null);
  const isUserFromDemystOrg = useSelector(store => isDemystUser(store));
  const dispatch = useDispatch();

  const clearAttachedFile = () => {
    setAttachedFile(null);

    // For now, reset everything else also
    // Might be better ux, to autopopulate inputs again with these values
    // even in the file gets erased
    setSelectedOrgId(null);
    setSelectedUserIds(null);
    setSelectedRegionCode(null);
  };

  const onFileAttached = (newFile) => {
    setAttachedFile(newFile);
  };

  const onOrgSelect = (id) => {
    setSelectedOrgId(id);
  };

  const onOrgUsersSelect = (userIds) => {
    setSelectedUserIds(userIds);
  };

  const onRegionSelect = (regionCode) => {
    setSelectedRegionCode(regionCode);
  };

  const onSubmit = async (e) => {
    e.preventDefault();

    if (!attachedFile) {
      return;
    }

    const regionCode = selectedRegionCode || "us"; // fall back to us-east-1

    const params = {
      filename: attachedFile.name,
      region_code: regionCode
    };

    if (selectedOrgId) {
      params.organization_id = Number(selectedOrgId);
    }

    try {
      const { signed_url, s3_object_id } = await get(
        `/upload/get_url?${stringify(params)}`
      );

      params.s3_object_id = s3_object_id;

      if (selectedUserIds) {
        params.permitted_users = selectedUserIds;
      }

      // Need manual fetch here because of S3 signed headers
      await fetch(signed_url, {
        method: "PUT",
        body: attachedFile
      });

      dispatch(registerFileTransferWithManta(params));

      toast.success("File uploaded successfully", { autoClose: false });
    } catch (error) {
      toast.error(`Failed to upload a file - ${error.message}`, { autoClose: false });
    }
  };

  const isDisabled = (function () {
    if (isUserFromDemystOrg) {
      return !selectedOrgId || !selectedRegionCode;
    }
    return !selectedRegionCode;
  })();

  return (
    <form className="UploadFile" onSubmit={onSubmit}>
      {!attachedFile && <DragAndDrop onFileAttached={onFileAttached} />}
      {attachedFile && (
        <div className="UploadFile__options">
          <div className="UploadFile__selected-file">
            <span className="UploadFile__selected-file__name">
              {attachedFile.name}
            </span>
            <button
              type="button"
              className="UploadFile__selected-file__close"
              title="Clear selected file"
              onClick={clearAttachedFile}
            >
              <CloseIcon />
            </button>
          </div>
          <OrganizationSelect onChange={onOrgSelect} />
          <OrganizationUsersSelect
            orgId={selectedOrgId}
            onChange={onOrgUsersSelect}
          />
          <RegionSelect onChange={onRegionSelect} />
          <input
            className="cyan"
            type="submit"
            disabled={isDisabled}
            value="Upload File"
          />
        </div>
      )}
    </form>
  );
}
