.ResendInstructions {
  h1 {
    text-align: center;
    margin-top: 0;
  }

  button[type="submit"] {
    float: none;
    margin-top: 2rem;
  }

  &__captcha {
    margin-bottom: 1.5em;
  }
}
