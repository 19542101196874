import { createSelector } from "reselect";

import { SORT_PARAMS, SORT_DIRECTIONS } from "../utility/constants";
import { getSortByPropSorter, uniq } from "../utility/helpers";

const getEntities = store => store.products.entities;
const getResults = store => store.products.results;
const getSampleData = store => store.sample_data;

const safeToLowercase = str => `${str}`.toLowerCase();
const sortLowercase = arr => arr.sort((a, b) => safeToLowercase(a).localeCompare(safeToLowercase(b)));

const filterByAttribute = (filterAttributes, attribute) => (
  !filterAttributes
  || !filterAttributes.length
  || (attribute && filterAttributes.includes(attribute.name))
);

export const getProduct = createSelector(
  getEntities,
  (store, productId) => productId,
  (entities, productId) => entities[productId]
);

export const getProducts = createSelector(
  getEntities,
  getResults,
  (store, params) => JSON.stringify(params),
  (entities, results, stringifiedParams) => (
    results[stringifiedParams]
    && results[stringifiedParams].data
    && results[stringifiedParams].data.map(productId => entities[productId])
  )
);

export const getFilteredSortedProducts = createSelector(
  getProducts,
  (store, params, filterOptions) => filterOptions,
  (products, filterOptions) => {
    if (!products) {
      return null;
    }

    const { categories, sort } = filterOptions;

    const { PUBLIC_COUNT, NUMBER_OF_ATTRIBUTES } = SORT_PARAMS;

    const defaultSortBy = PUBLIC_COUNT;
    const sortBy = filterOptions.sort_by || defaultSortBy;

    const numberFields = [PUBLIC_COUNT, NUMBER_OF_ATTRIBUTES];

    const transformer = numberFields.includes(sortBy)
      // We're comparing numbers here, so we shouldn't sort alphabetically
      ? null
      : safeToLowercase;

    const filteredProducts = products
      .filter(product => (
        filterByAttribute(categories, product.data_category)
      ));

    const filteredSortedProducts = filteredProducts
      .sort(
        getSortByPropSorter(
          sortBy,
          sort ? sort === SORT_DIRECTIONS.ASC : true,
          transformer
        )
      );

    return filteredSortedProducts;
  }
);

export const getCategories = createSelector(
  getProducts,
  (products) => {
    if (!products) {
      return null;
    }

    return sortLowercase(
      uniq(
        products
          .filter(product => product.data_category)
          .map(product => product.data_category.name)
      )
    )
      .reduce((products, product) => {
        products.push({
          name: product
        });
        return products;
      }, []);
  }
);

export const getProductSampleData = createSelector(
  getSampleData,
  (store, productId) => productId,
  (sample_data, productId) => sample_data[productId]
);
