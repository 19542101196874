import React, { useEffect } from "react";
import PropTypes from "prop-types";

import { useCMSAPISinglePage } from "../../../utility/hooks";
import { GAPageView } from "../../../utility/google_analytics";

import Head from "../../head";

import "./hosted_notebook.less";


export default function HostedNotebookContent({
  match: {
    params: { slug }
  }
}) {
  const pageSlug = `hosted-notebook-${slug}`;
  const pageData = useCMSAPISinglePage(pageSlug);


  useEffect(() => {
    pageData && GAPageView(pageData?.data?.fields?.metatitle);
  }, [pageData]);

  useEffect(() => {
    if (pageData) {
      const iframeDoc = document.querySelector("#HostedNotebook").contentWindow.document;
      iframeDoc.open("text/html", "replace");
      iframeDoc.write(pageData?.data?.fields?.notebook_html);
      iframeDoc.close();
    }
  }, [pageData]);

  if (!pageData) {
    return null;
  }

  const {
    data: {
      fields: {
        metatitle
      }
    }
  } = pageData;


  return (
    <div>
      <Head title={metatitle} />
      <iframe
        className="HostedNoteBook"
        id="HostedNotebook"
      />
    </div>
  );
}

HostedNotebookContent.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      slug: PropTypes.string.isRequired
    }).isRequired
  }).isRequired
};
