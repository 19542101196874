import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

// Components
import Description from "../../components/product_details/description";

// Helpers
import { generateProductPlaceholder } from "../../utility/placeholders";

const product = generateProductPlaceholder();

export default withRouter(connect(
  () => ({
    isPlaceholder: true,
    product,
    productId: product.id
  })
)(Description));
