import React from "react";

export default function BackIcon({ color = "white" }) {
  return (
    <svg height="22" width="16" viewBox="0 0 16 22">
      <g stroke={color} strokeWidth="2" fill="none">
        <polyline points="11,3 3,11 11,19" />
      </g>
    </svg>
  );
}
