import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import classNames from "merge-class-names";

import "./attribute_card.less";

import AttributeStat from "./attribute_stat";
import Placeholder from "../../placeholders/placeholder";
import ProviderLogo from "../ui/provider_logo";
import SecurityPill from "./security_pill";

import CaretDown from "../../static/icons/caret_down.svg";

import { isAttribute, isLocation } from "../../utility/prop-types";
import {
  formatAsPercent,
  getAttributeUrlId,
  getProductAliasOrName,
  isEmptyObject,
  isValidAttributeStat
} from "../../utility/helpers";
import { toNaturalSentence } from "../../utility/string";

import Table from "../../../../stingray/frontend/components/ui/table";

const removeEmpty = obj => Object.entries(obj).reduce((acc, [key, value]) => {
  if (value === null || value === undefined) {
    return acc;
  }
  return {
    ...acc,
    [key]: value
  };
}, {});

export default function AttributeCard({
  attribute,
  activeAttributeId,
  isPlaceholder,
  location
}) {
  const {
    description,
    id,
    fcra,
    field_is_populated_rate,
    fill_rate,
    match_rate,
    max_val,
    mean,
    median,
    min_val,
    most_common_values,
    name,
    num_distinct_values,
    standard_deviation,
    table_provider: {
      alias,
      data_source_name,
      display_cost,
      id: productId,
      logo,
      name: productName
    },
    type,
    variance
  } = attribute;

  const product = {
    alias,
    name: productName,
    logo,
    data_source: {
      name: data_source_name
    }
  };

  const isActive = id === activeAttributeId;

  const infoStats = {
    Type: type,
    Description: description,
    Source: data_source_name
  };

  const extraInfoStats = {
    Median: median,
    Variance: variance,
    "Std Dev": standard_deviation,
    Min: min_val,
    Max: max_val,
    Mean: mean
  };

  const attributeStats = {
    "Fill Rate": fill_rate && formatAsPercent(fill_rate * 100),
    "Match Rate": match_rate && formatAsPercent(match_rate * 100),
    "Null Values": field_is_populated_rate && formatAsPercent(100 - (field_is_populated_rate * 100)),
    "Unique Values": num_distinct_values
  };

  function renderAttributeDescription() {
    if (!Object.values(attributeStats).some(isValidAttributeStat)) {
      const subject = encodeURIComponent("Unavailable Quality Statistics");
      return (
        <div className="AttributeCard__content__description__empty">
          Quality statistics are not yet available for this attribute.
          {" "}
          <a href={`mailto:help@demystdata.com?subject=${subject}`}>
            Contact Us
          </a>
          {" "}
          for more information
        </div>
      );
    }
    return Object.entries(attributeStats).map(([title, value]) => (
      <AttributeStat key={title} value={value} title={title} />
    ));
  }

  const showTable = !["Number", "Percentage"].includes(type)
    && most_common_values
    && !isEmptyObject(most_common_values);

  return (
    <div
      className={classNames(
        "AttributeCard",
        isActive && "AttributeCard--active"
      )}
    >
      <Placeholder isEnabled={isPlaceholder} theme="dark">
        <div className="AttributeCard__header">
          {logo && (
            <div className="AttributeCard__logo">
              <Link to={`/products/${productId}/info`}>
                <ProviderLogo product={product} />
              </Link>
            </div>
          )}
          <div className="AttributeCard__header__content">
            <div className="AttributeCard__header__content__title">
              <div className="AttributeCard__header__content__title__attribute-name">
                {toNaturalSentence(name)}
              </div>
              <SecurityPill>{type}</SecurityPill>
              {fcra && <SecurityPill type="fcra">FCRA</SecurityPill>}
              {/* <SecurityPill type="pii">
                PII
              </SecurityPill> */}
            </div>
            <div className="AttributeCard__header__content__title__product-name">
              <Link to={`/products/${productId}/info`} target="_blank">
                {getProductAliasOrName(product)}
              </Link>
            </div>
          </div>
          <div className="AttributeCard__header__info">
            <Link className="button mdb" to={`/products/${productId}/info`}>
              View
            </Link>
            <div className="AttributeCard__header__info__price">
              {display_cost}
              <span className="AttributeCard__header__info__price__leftover">
                {Array.from(
                  { length: 4 - display_cost.split("").length },
                  () => "$"
                ).join("")}
              </span>
            </div>
          </div>
        </div>
        <div className="AttributeCard__content">
          <div className="AttributeCard__content__description">
            {renderAttributeDescription()}
          </div>
        </div>
        <div className="AttributeCard__footer">
          <div className="AttributeCard__footer__trigger">
            <Link
              to={{
                ...location,
                pathname: isActive
                  ? "/attributes/"
                  : `/attributes/${getAttributeUrlId(attribute)}`
              }}
            >
              DETAILS
              <img
                src={CaretDown}
                alt="Toggle card"
                className={classNames(isActive && "rotate")}
              />
            </Link>
          </div>
          {isActive && (
            <div className="AttributeCard__footer__content">
              <div className="AttributeCard__footer__content__body">
                <div className="AttributeCard__footer__content__body__info">
                  <Table data={removeEmpty(infoStats)} />
                  {Object.values(extraInfoStats).some(isValidAttributeStat) && (
                    <>
                      <h4 className="AttributeCard__footer__content__body__info__title">
                        Additional Stats
                      </h4>
                      <Table data={removeEmpty(extraInfoStats)} />
                    </>
                  )}
                </div>
                {showTable && (
                  <div className="AttributeCard__footer__content__body__table">
                    <div className="AttributeCard__footer__content__body__table__title">
                      Most Common Values
                    </div>
                    <table>
                      <thead>
                        <tr>
                          <th>Value</th>
                          <th>Occurance</th>
                        </tr>
                      </thead>
                      <tbody>
                        {Object.entries(most_common_values)
                          .sort(([, valA], [, valB]) => valB - valA)
                          .slice(0, 10)
                          .map(([key, val]) =>
                            // TODO: Use num_per_month to get percentage for val
                            (
                              <tr key={key}>
                                <td>{key}</td>
                                <td>{val}</td>
                              </tr>
                            ))}
                      </tbody>
                    </table>
                  </div>
                )}
              </div>
            </div>
          )}
        </div>
      </Placeholder>
    </div>
  );
}

AttributeCard.propTypes = {
  activeAttributeId: PropTypes.number,
  attribute: isAttribute.isRequired,
  isPlaceholder: PropTypes.bool,
  location: isLocation
};
