import React from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";

import Submit from "../../../../stingray/frontend/components/ui/form/submit";
import ApiIcon from "../../static/icons/api_blue.svg";
import BeakerIcon from "../../static/icons/beaker_blue.svg";
import DataDictionaryIcon from "../../static/icons/data_dict_blue.svg";
import SearchStatsIcon from "../../static/icons/search_stats_blue.svg";
import CloseIcon from "../../static/icons/components/close";

import { getProductAliasOrName } from "../../utility/helpers";
import { isProduct, isHistory, isLocation } from "../../utility/prop-types";
import {
  GAEvent,
  GA_CATEGORY,
  GA_LABEL_TITLE
} from "../../utility/google_analytics";

import "./product_details_anonymous.less";

export default function ProductDetailsAnonymous({
  history,
  location,
  onCloseLink,
  product
}) {
  const onSubmit = () => {
    GAEvent({
      category: GA_CATEGORY.LEAD_FLOW,
      action: "submit request data form",
      labelTitle: GA_LABEL_TITLE.SIGN_UP,
      labelInfo: `(from ${window.location.pathname})`
    });
  };

  const renderProductName = () => product ? <span>{getProductAliasOrName(product)}</span> : "a specific data product";

  return (
    <div className="ProductDetailsAnonymous">
      <button
        type="button"
        className="ProductDetailsAnonymous__close"
        title="Close Product Details"
        onClick={() =>
          history.push({
            ...location,
            pathname: onCloseLink || "/products"
          })
        }
      >
        <CloseIcon />
      </button>
      <section>
        <header className="ProductDetailsAnonymous__title">
          Interested in learning more?
        </header>
        <h3 className="ProductDetailsAnonymous__body">
          Want to see the data dictionary or sample data for{" "}
          {renderProductName()}? Share your email and what you're working on
          with us and we can help you.
        </h3>
      </section>
      <section>
        <form
          method="POST"
          name="fa-form-1"
          action="https://webhook.frontapp.com/forms/dm_data/iPDqydjesVgaiUFcor-aEhtE4R7Hrj42HtrnIEQHaU55XOfb03M1je2rkmbmPoBKDAgDKEwF0KbXu4weFOKlxOmzR8xzDVrN7qRqIo7NYQ"
          encType="multipart/form-data"
          acceptCharset="utf-8"
          onSubmit={onSubmit}
        >
          <label htmlFor="email">Email</label>
          <input type="email" name="email" required />
          <input
            type="hidden"
            name="pathname"
            value={window.location.pathname}
          />
          <label htmlFor="body">Tell us about your data needs</label>
          <textarea name="body" required />
          <div className="ProductDetailsAnonymous__privacy-message">
            <small>
              By submitting this form, you agree to DemystData's{" "}
              <Link to="/privacy-policy">Privacy Policy</Link> and{" "}
              <Link to="/terms-and-conditions">Terms and Conditions</Link>.
            </small>
            <Submit className="button cyan">Send</Submit>
          </div>
        </form>
      </section>
      <section>
        <div className="ProductDetailsAnonymous__icons">
          <figure>
            <img src={DataDictionaryIcon} alt="Data Dictionary" />
            <figcaption>Data Dictionary</figcaption>
          </figure>
          <figure>
            <img src={BeakerIcon} alt="Beaker" />
            <figcaption>Sample Data</figcaption>
          </figure>
          <figure>
            <img src={SearchStatsIcon} alt="Search Stats" />
            <figcaption>Match/Fill Rates</figcaption>
          </figure>
          <figure>
            <img src={ApiIcon} alt="Api" />
            <figcaption>Python API</figcaption>
          </figure>
        </div>
      </section>
    </div>
  );
}

ProductDetailsAnonymous.propTypes = {
  history: isHistory,
  location: isLocation,
  onCloseLink: PropTypes.string,
  product: isProduct
};
