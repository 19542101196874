import React from "react";
import PropTypes from "prop-types";
import { NavLink } from "react-router-dom";

import "./product_navigation.less";

import BackIcon from "../../../../stingray/frontend/assets/svgs/backIcon";
import logo from "../../static/demyst_logo_short.svg";

import { isHistory } from "../../utility/prop-types";

const isActive = (path) => (match, location) => {
  const foundPath = new RegExp(`/products/.+/${path}`).test(location.pathname);
  return !!(match || foundPath);
};

export default function ProductNavigation({
  history: { goBack, location, push },
  isDemystUser,
}) {
  function goBackOrRoot() {
    if (!goBack()) {
      // If there is no history, simply go to the the root /attributes page
      push({
        ...location,
        pathname: "/attributes",
      });
    }
  }
  return (
    <nav className="ProductNavigation">
      <div className="ProductNavigation__controls">
        <button
          type="button"
          className="ProductNavigation__controls__back-button link-button"
          onClick={goBackOrRoot}
        >
          <BackIcon />
          Back
        </button>
        <div className="ProductNavigation__controls__tabs">
          <NavLink
            to="info"
            activeClassName="active"
            isActive={isActive("info")}
          >
            Info
          </NavLink>
          <NavLink
            to="data_dictionary"
            activeClassName="active"
            isActive={isActive("data_dictionary")}
          >
            Data Dictionary
          </NavLink>

          <NavLink
            to="sample_data"
            activeClassName="active"
            isActive={isActive("sample_data")}
          >
            Sample Data
          </NavLink>
          {isDemystUser && (
            <NavLink
              to="single_lookup"
              activeClassName="active"
              isActive={isActive("single_lookup")}
            >
              Single Lookup
            </NavLink>
          )}
        </div>
      </div>
      <div className="ProductNavigation__demyst-logo">
        <a className="logo-link" href="/">
          <img src={logo} alt="Demyst Data" />
        </a>
      </div>
    </nav>
  );
}

ProductNavigation.propTypes = {
  history: isHistory.isRequired,
  isDemystUser: PropTypes.bool.isRequired,
};
