import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { parse } from "query-string";

// Selectors
import { getAttributeCount, getPaginationInfo } from "../../selectors/attributes";

// Components
import HeaderWithSort from "../../components/attribute_catalog/header_with_sort";

export default withRouter(connect(
  (store, ownProps) => {
    const params = parse(ownProps.location.search, { arrayFormat: "bracket" });

    return ({
      attributeCount: getAttributeCount(store, params),
      paginationInfo: getPaginationInfo(store)
    });
  }
)(HeaderWithSort));
