export const FILTER_TYPES = {
  CATEGORIES: "categories",
  DATA_TYPES: "data_types",
  REGIONS: "regions",
  SEARCH: "search",
  SORT_BY: "sort_by",
  SORT_DIRECTION: "sort_direction",
  SORT: "sort"
};

export const SORT_DIRECTIONS = {
  ASC: "asc",
  DESC: "desc"
};

export const SORT_PARAMS = {
  CREATED_AT: "created_at",
  ERROR_RATE: "error_rate",
  FILL_RATE: "fill_rate",
  MATCH_RATE: "match_rate",
  NAME: "name",
  NUMBER_OF_ATTRIBUTES: "output_fields_count",
  PRICE: "price",
  PUBLIC_COUNT: "public_count"
};

export const KEYBOARD_KEYS = {
  ESCAPE: "Escape"
};
