import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
// TODO: Copy DD from stingray into marlin and fix styles
import MultiselectDropdown from "../../../../stingray/frontend/components/ui/multiselect_dropdown";

import { get } from "../../helpers/network";

export default function OrganizationUsersSelect({ orgId, onChange }) {
  const [organizationUsers, setOrganizationUsers] = useState(null);

  useEffect(() => {
    if (orgId) {
      const getOrganizationUsers = async () => {
        try {
          const users = await get(
            `/users_within_organization?organization_id=${orgId}`
          );
          setOrganizationUsers(users);
        } catch (error) {
          // Pass
          console.log("ERROR getting organization users - ", error);
        }
      };
      getOrganizationUsers();
    }
  }, [orgId]);

  if (!orgId || !organizationUsers) {
    return null;
  }

  const onUserSelect = (event) => {
    const userSelected = event.currentTarget.value;
    const updatedOrganizationUsers = organizationUsers.map((user) => {
      if (user.name === userSelected) {
        user.checked = !user.checked;
      }
      return user;
    });
    setOrganizationUsers(updatedOrganizationUsers);
    onChange(organizationUsers?.filter(user => user.checked).map(user => user.id));
  };

  const selectedUsers = () => (
    organizationUsers?.filter(user => user.checked).map(user => user.name || user.email)
  );

  const getUserLabel = user =>
    user.name ? `${user.name} (${user.email})` : user.email;

  return (
    <label>
      Users
      <MultiselectDropdown
        disabled={!organizationUsers}
        items={organizationUsers?.map(user => ({
          label: getUserLabel(user),
          value: user.name || user.email,
          checked: user.checked || false,
          onItemChecked: onUserSelect
        }))}
        name="users"
        value={selectedUsers()}
        placeholderLabel="Choose users"
      />
    </label>
  );
}

OrganizationUsersSelect.propTypes = {
  onChange: PropTypes.func.isRequired,
  orgId: PropTypes.string.isRequired
};
