import React from "react";

// Components
import AttributeCard from "../components/attribute_catalog/attribute_card";

// Helpers
import { generateAttributePlaceholder } from "../utility/placeholders";

const attribute = generateAttributePlaceholder();

export default () => (
  <AttributeCard
    isPlaceholder
    attribute={attribute}
  />
);
