import React, { useEffect } from "react";
import PropTypes from "prop-types";

import TeamMember from "./team_member";
import TeamMemberPlaceholder from "../../placeholders/use_case/team_member";
import UseCaseSection from "./use_case_section";

import "./team_members.less";

export default function TeamMembers({
  fetchUseCaseUsers,
  isFetching,
  teamMembers,
  useCaseId
}) {
  useEffect(() => {
    useCaseId && fetchUseCaseUsers(useCaseId);
  }, [fetchUseCaseUsers, useCaseId]);

  if ((!isFetching && !teamMembers) || teamMembers?.length === 0) {
    return null;
  }

  const content = () => {
    if (isFetching) {
      return Array.from(new Array(5)).map((el, i) => (
        <TeamMemberPlaceholder key={i} />
      ));
    }
    return (
      <>
        {teamMembers.map((teamMember, i) => (
          <TeamMember key={i} {...teamMember} />
        ))}
      </>
    );
  };

  return (
    <UseCaseSection title="Team Members">
      <ul className="TeamMembers">{content()}</ul>
    </UseCaseSection>
  );
}

TeamMembers.propTypes = {
  fetchUseCaseUsers: PropTypes.func.isRequired,
  isFetching: PropTypes.bool.isRequired,
  teamMembers: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired
    }).isRequired
  ),
  useCaseId: PropTypes.number
};
