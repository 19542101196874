import React, { useState } from "react";

import { getEnvUrl } from "../../helpers/network";

import "./gpg_key.less";

export default function GPGKey() {
  const [fingerprintVisible, setFingerprintVisible] = useState(false);
  const toggleFingerprint = () => {
    setFingerprintVisible(!fingerprintVisible);
  };

  return (
    <div className="GPGKey">
      <div className="GPGKey__title">GPG Key</div>
      <p>
        Additionally, you may choose to further encrypt a file with GPG before
        sending it to us.
      </p>
      <p>
        For instructions on how to achieve this,{" "}
        <a href="http://www.madboa.com/geek/gpg-quickstart/#tosomeone">
          click here.
        </a>
      </p>
      <button type="button" onClick={toggleFingerprint}>
        {fingerprintVisible ? "Hide" : "View"} Fingerprint
      </button>
      {fingerprintVisible && (
        <div className="GPGKey__fingerprint">
          <div className="GPGKey__fingerprint__code">
            118D 6FA4 7AE8 21D9 A5A5 9A1D 1421 6CCA 440B DCA8
          </div>
        </div>
      )}
      <a
        className="button"
        href={`${getEnvUrl()}/440BDCA8.asc`}
        download
        title="Download GPG key"
      >
        Download key
      </a>
    </div>
  );
}
