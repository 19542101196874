export const OPEN_SUBSCRIBE_FORM = "OPEN_SUBSCRIBE_FORM";
export const CLOSE_SUBSCRIBE_FORM = "CLOSE_SUBSCRIBE_FORM";

export const openSubscribeForm = () => async (dispatch, getState) => {
  const store = getState();

  const { isOpen } = store.subscribe_form;

  if (isOpen) {
    return true;
  }

  dispatch({ type: OPEN_SUBSCRIBE_FORM });
};

export const closeSubscribeForm = () => async (dispatch, getState) => {
  const store = getState();

  const { isOpen } = store.subscribe_form;

  if (!isOpen) {
    return false;
  }

  dispatch({ type: CLOSE_SUBSCRIBE_FORM });
};
