import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

// Actions
import { fetchTags } from "../../../actions/tags";

// Selectors
import { getTags } from "../../../selectors/tags";

// Components
import UseCasesFilter from "../../../components/attribute_catalog/filters/use_cases_filter";

export default withRouter(connect(
  store => ({
    tags: getTags(store)
  }),
  {
    fetchTags
  }
)(UseCasesFilter));
