import { parse } from "query-string";
import { toNaturalSentence } from "./string";
import {
  removeFromQuery,
  replaceInQuery
} from "../../../stingray/frontend/utility/url";

export const flat = arr => arr.reduce((acc, val) => acc.concat(val), []);

export const deepFlat = arr => arr.reduce((acc, val) => Array.isArray(val) ? acc.concat(deepFlat(val)) : acc.concat(val), []);

export const uniq = nonUniqArray => Array.from(new Set(nonUniqArray));

export const uniqBy = (arr, property) => {
  const uniqProperties = uniq(arr.map(el => el[property]));

  return uniqProperties.map(uniqProperty => arr.find(el => el[property] === uniqProperty));
};

export const getSortByPropSorter = (prop, ascending = true, transformer) => {
  const getTransformedValue = obj => (transformer && prop in obj) ? transformer(obj[prop]) : obj[prop];

  const direction = ascending ? 1 : -1;

  return (rawA, rawB) => {
    const a = getTransformedValue(rawA);
    const b = getTransformedValue(rawB);

    if (a < b || (prop in rawA && !(prop in rawB))) {
      return -1 * direction;
    }

    if (a > b || (prop in rawB && !(prop in rawA))) {
      return 1 * direction;
    }

    return 0;
  };
};

export const isEmptyObject = (obj) => {
  if (obj === null || obj === undefined) {
    return true;
  }
  // Arrays are objects
  if (Array.isArray(obj)) {
    return obj.length === 0;
  }
  if (typeof obj !== "object") {
    return false;
  }
  for (const key in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, key)) {
      return false;
    }
  }
  return true;
};

export const removeEmptyObjectPair = (obj) => {
  for (const key in obj) {
    if (obj.hasOwnProperty(key) && isEmptyObject(obj[key])) {
      delete obj[key];
    }
  }
  return obj;
};

export const getIdFromParams = (params, customId) => {
  let { id } = params || {};
  if (customId) {
    // allow to override the `id` property from the params
    id = params[customId];
  }

  if (!id) {
    return null;
  }

  const idFromUrl = id.split("-").pop();
  const parsedId = parseInt(idFromUrl, 10);

  if (Number.isNaN(parsedId)) {
    return null;
  }

  return parsedId;
};

export const toUrlSafeName = name => name && name.replace(/( |_)/g, "-").toLowerCase();

export const getProductUrlId = product => [
  toUrlSafeName(product.data_source && product.data_source.name),
  toUrlSafeName(product.alias || product.name),
  product.id
].join("-");

export const getAttributeUrlId = attribute => [
  toUrlSafeName(attribute.table_provider_name),
  toUrlSafeName(attribute.name),
  attribute.id
].join("-");

export const downloadFile = (url, filename) => {
  if (!url) {
    return false;
  }

  const a = document.createElement("a");
  a.href = url;
  a.download = filename;
  document.body.appendChild(a);

  a.click();
  a.parentElement.removeChild(a);

  return true;
};

export const getProductAliasOrName = (product) => {
  if (product.alias) {
    return product.alias;
  } else {
    return toNaturalSentence(product.name);
  }
};

export const formatAsPercent = number => `${number.toFixed(2)}%`;

// Avoid doing !statVal since the number 0 can be falsey
export const isValidAttributeStat = statVal => !(statVal === null || statVal === undefined);

export const clipText = (text, length = 150) => {
  if (text.length > length) {
    return `${text.substr(0, length - 1)}…`;
  }
  return text;
};

// /products/matchdeck-matchdeck-company-details-1725 => products
export const getRootPathName = pathname => pathname.split("/").filter(Boolean)[0];

export const syncSearchWithUrl = (history, location, key, value) => {
  // Updates history
  const oldSearchParsed = parse(location.search, { arrayFormat: "bracket" });
  let newSearch = replaceInQuery(location.search, key, value);
  const newSearchParsed = parse(newSearch, { arrayFormat: "bracket" });

  // Always redirect to the "Catalog" A.K.A products page for searching
  // Ex: If on a show page like /data-sources/mck instead of appending the search
  // so it can be /data-sources/mck?search=something make sure to point it to the products page
  // so it can be /products?search=something

  const locationCopy = {
    ...location
  };

  if (!/products\//i.test(location.pathname)) {
    locationCopy.pathname = "/products";
  }

  // If we are searching something new from a different page, reset the page back to default
  // Ex: If we are in page 42 and search for Acxiom, reset the page back to 1 for the new search query
  if (
    oldSearchParsed.search !== newSearchParsed.search
    && newSearchParsed.page
  ) {
    newSearch = removeFromQuery(newSearch, "page"); // Remove page
    // TODO: Might be worth setting the page default explicitly to 1
    // newSearch = replaceInQuery(newSearch, "page", 1); // Explicitly set to page 1
  }

  history.replace({
    ...locationCopy,
    search: newSearch
  });
};

export const isFile = input => "File" in window && input instanceof File;
