import PropTypes from "prop-types";

export const isAttribute = PropTypes.shape({
  consistency_rate: PropTypes.number,
  cost: PropTypes.number.isRequired,
  description: PropTypes.string,
  error_rate: PropTypes.number,
  fcra: PropTypes.bool,
  field_is_populated_rate: PropTypes.number,
  fill_rate: PropTypes.number,
  hit_rate: PropTypes.number,
  id: PropTypes.number.isRequired,
  json_type: PropTypes.string.isRequired,
  log_flattened_name: PropTypes.string,
  match_rate: PropTypes.number,
  max_val: PropTypes.number,
  mean: PropTypes.number,
  median: PropTypes.number,
  min_val: PropTypes.number,
  most_common_values: PropTypes.shape({}),
  name: PropTypes.string.isRequired,
  num_distinct_values: PropTypes.number,
  standard_deviation: PropTypes.number,
  table_provider: PropTypes.shape({
    cost: PropTypes.number.isRequired,
    data_source_name: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    fcra: PropTypes.bool,
    id: PropTypes.number.isRequired,
    logo: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired
  }).isRequired,
  type: PropTypes.string.isRequired,
  variance: PropTypes.number
});

export const isDataCategory = PropTypes.shape({
  id: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired
});

export const isDataServiceJob = PropTypes.shape({
  client_satisfied: PropTypes.bool,
  created_at: PropTypes.string.isRequired,
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired, // Allows placeholder to show a string
  name: PropTypes.string.isRequired,
  num_cols: PropTypes.number,
  num_rows: PropTypes.number,
  summary_link: PropTypes.string
});

export const isError = PropTypes.shape({
  code: PropTypes.number.isRequired,
  message: PropTypes.string.isRequired
});

export const isHistory = PropTypes.shape({
  push: PropTypes.func.isRequired,
  replace: PropTypes.func.isRequired
});

export const isLocation = PropTypes.shape({
  hash: PropTypes.string,
  pathname: PropTypes.string.isRequired,
  search: PropTypes.string.isRequired
});

export const isPagination = PropTypes.shape({
  current_page: PropTypes.number.isRequired,
  per_page: PropTypes.number.isRequired,
  total_entries: PropTypes.number.isRequired,
  total_pages: PropTypes.number.isRequired
});

export const isProduct = PropTypes.shape({
  created_at: PropTypes.string,
  data_category: PropTypes.shape({
    name: PropTypes.string.isRequired
  }),
  data_regions: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string.isRequired
  })).isRequired,
  data_source: PropTypes.shape({
    name: PropTypes.string.isRequired
  }),
  description: PropTypes.string,
  id: PropTypes.number.isRequired,
  latest_version: PropTypes.shape({}),
  logo: PropTypes.string,
  name: PropTypes.string.isRequired,
  sample_data_preview: PropTypes.bool,
  tags: PropTypes.arrayOf(PropTypes.string)
});

export const isUser = PropTypes.shape({
  id: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  permissions: PropTypes.objectOf(PropTypes.bool)
});

export const isSampleData = PropTypes.shape({
  name: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired
});

export const isProductInput = PropTypes.shape({
  description: PropTypes.string,
  name: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired
});

export const isProductOutput = isProductInput;

export const isSchema = PropTypes.arrayOf(
  PropTypes.shape({
    input: PropTypes.shape({
      optional: PropTypes.arrayOf(isProductInput).isRequired,
      required: PropTypes.arrayOf(
        PropTypes.arrayOf(isProductInput).isRequired
      ).isRequired
    }).isRequired,
    output: PropTypes.arrayOf(isProductOutput).isRequired
  }).isRequired
);

const isInputType = PropTypes.oneOf([
  "string",
  "object",
  "number",
  "array",
  "boolean"
]);

export const isPublicType = PropTypes.shape({
  id: PropTypes.number.isRequired,
  json_type: isInputType.isRequired,
  name: PropTypes.string.isRequired
});

export const isTag = PropTypes.shape({
  created_by_user: PropTypes.bool.isRequired,
  id: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired
});

export const isType = PropTypes.shape({
  description: PropTypes.string,
  example_input: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
    PropTypes.number,
    PropTypes.array,
    PropTypes.bool
  ]).isRequired,
  json_type: isInputType.isRequired,
  name: PropTypes.string.isRequired
});

export const isTrialPeriod = PropTypes.shape({
  end_date: PropTypes.string.isRequired,
  expired: PropTypes.bool.isRequired
});

export const isUseCase = PropTypes.shape({
  description: PropTypes.string.isRequired,
  edaas_tags: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired
    }).isRequired
  ).isRequired,
  id: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired
});

export const isGAConfig = PropTypes.shape({
  action: PropTypes.string,
  category: PropTypes.string,
  labelInfo: PropTypes.string,
  labelTitle: PropTypes.string
});

export const isFileTransfer = PropTypes.shape({
  bucket: PropTypes.string.isRequired,
  created_at: PropTypes.string.isRequired,
  id: PropTypes.number.isRequired,
  key: PropTypes.string.isRequired,
  lovable_id: PropTypes.number,
  lovable_type: PropTypes.string,
  note: PropTypes.string,
  organization_id: PropTypes.number.isRequired,
  region: PropTypes.string.isRequired,
  updated_at: PropTypes.string.isRequired,
  uploader_id: PropTypes.number.isRequired
});

export const isDataSource = PropTypes.shape({
  icon: PropTypes.shape({ url: PropTypes.string }).isRequired,
  id: PropTypes.number.isRequired,
  image_url: PropTypes.string.isRequired,
  logo: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  table_providers: PropTypes.arrayOf(isProduct).isRequired,
  website: PropTypes.string.isRequired
});
