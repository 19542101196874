const alwaysLowercase = ["of", "the", "to"];
const alwaysUppercase = ["API", "ID", "JSON", "UK", "US", "USA", "XML"];

const isString = string => string && typeof string === "string";

const upperCaseFirstLetter = string => string.charAt(0).toUpperCase() + string.slice(1);

/**
 * Formats words to natural-cased word.
 * Examples: customer -> Customer, id => ID, the => the
 *
 * @param {string} string Source string.
 * @returns {String} Natural-cased string.
 */
export const toNaturalWord = (string) => {
  if (!isString(string)) {
    return string;
  }

  if (alwaysLowercase.includes(string.toLowerCase()))
    return string.toLowerCase();
  if (alwaysUppercase.includes(string.toUpperCase()))
    return string.toUpperCase();

  return upperCaseFirstLetter(string);
};

/**
 * Formats sentences or some_underscored_ids to natural-cased sentences.
 * Example: customer_id_to_username -> Customer ID to Username
 *
 * @param {string} string Source string.
 * @returns {String} Natural-cased string.
 */
export const toNaturalSentence = (string) => {
  if (!isString(string)) {
    return string;
  }

  return string.split(/[\s_]/).map((word, index) => index ? toNaturalWord(word) : upperCaseFirstLetter(toNaturalWord(word))).join(" ");
};

export const getInitials = (name = "") => name && name.split(/\s/).map(w => w[0]).join("");
