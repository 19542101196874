import React, { Component } from "react";
import PropTypes from "prop-types";

import classNames from "merge-class-names";

import "./typed_suggestions.less";

const getRandomArrayElement = items => items[Math.floor(Math.random() * items.length)];

export default class TypedSuggestions extends Component {
  static propTypes = {
    dark: PropTypes.bool,
    suggestions: PropTypes.arrayOf(PropTypes.string)
  };

  state = {
    suggestion: null
  };

  componentDidMount() {
    this.pickSuggestion();
    this.expand();
  }

  componentWillUnmount() {
    clearTimeout(this.timeout);
  }

  getNextSuggestion = () => {
    const { suggestions } = this.props;

    return {
      suggestion: getRandomArrayElement(suggestions),
      slice: 0
    };
  };

  pickSuggestion = () => {
    this.setState(this.getNextSuggestion());
  };

  expand = () => {
    this.setState((prevState) => {
      const { suggestion, slice } = prevState;

      if (slice === suggestion.length) {
        // reached full expansion
        this.timeout = setTimeout(this.collapse, 500);
        return null;
      }

      this.timeout = setTimeout(this.expand, 100);
      return { slice: slice + 1 };
    });
  }

  collapse = () => {
    this.setState((prevState) => {
      const { slice } = prevState;

      // expanding
      if (slice === 0) {
        // reached full collapse
        this.timeout = setTimeout(this.expand, 500);
        return this.getNextSuggestion();
      }

      this.timeout = setTimeout(this.collapse, 50);
      return { slice: slice - 1 };
    });
  }

  renderSuggestion() {
    const { suggestion, slice } = this.state;

    if (!suggestion) {
      return null;
    }

    return (
      <div className="TypedSuggestions__suggestion">
        <div className="TypedSuggestions__suggestionText">
          {suggestion.slice(0, slice)}
        </div>
      </div>
    );
  }

  render() {
    const { dark } = this.props;

    return (
      <div className={classNames("TypedSuggestions", dark && "TypedSuggestions--dark")}>
        I'm looking for…
        {" "}
        {this.renderSuggestion()}
      </div>
    );
  }
}
