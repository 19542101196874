import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";

import { get } from "../../helpers/network";

export default function RegionSelect({ onChange }) {
  const [awsRegions, setAwsRegions] = useState(null);

  useEffect(() => {
    const getAwsRegions = async () => {
      try {
        const regions = await get(
          "/workspaces/get_all_current_organization_aws_regions"
        );
        setAwsRegions(regions);
      } catch (error) {
        // Pass
        console.log("ERROR getting aws regions - ", error);
      }
    };
    getAwsRegions();
  }, []);

  const onChangeSelect = (event) => {
    const {
      target: { value }
    } = event;

    if (value) {
      onChange(value);
    }
  };

  return (
    <>
      <label htmlFor="region_id">
        Region
      </label>
      <select
        id="region_id"
        name="region_id"
        disabled={awsRegions === null}
        onChange={onChangeSelect}
        required
      >
        <option hidden value="">
          Select region
        </option>
        {awsRegions?.map(item => (
          <option key={item.id} value={item.code}>
            {item.name}
          </option>
        ))}
      </select>
    </>
  );
}

RegionSelect.propTypes = {
  onChange: PropTypes.func.isRequired
};
