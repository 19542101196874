import React from "react";
import PropTypes from "prop-types";

import { formatValue } from "../../../utility/table";
import { highlightPattern, toFuzzyRegExp } from "../../../utility/search";

export default function Cell({ search, value }) {
  const formattedValue = formatValue(value, true);
  const highlightedValue = highlightPattern(formattedValue, toFuzzyRegExp(search));

  return (
    <td>{highlightedValue}</td>
  );
}

Cell.propTypes = {
  search: PropTypes.string,
  value: PropTypes.any
};
