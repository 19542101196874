import { toast } from "react-toastify";

/**
 * Displays toast notification(s).
 *
 * @param {String|String[]} msg Message or an array of messages
 * @param {"info" | "success" | "error" | "warning"} [type="info"] Message type
 */
export const notify = (msg, type = "info") => {
  if (Array.isArray(msg)) {
    msg.forEach(msgPiece => notify(msgPiece, type));
    return;
  }

  toast[type](msg);
};
