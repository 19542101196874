import React, { Component } from "react";
import PropTypes from "prop-types";
import formatDistance from "date-fns/formatDistance";

import "./autosave.less";

import { Consumer } from "./context";

const COUNTER = Symbol();
const UPDATER = Symbol();

export class Autosave extends Component {
  static propTypes = {
    formState: PropTypes.shape({
      isChanged: PropTypes.bool.isRequired,
      isSubmitting: PropTypes.bool.isRequired,
      lastSubmitted: PropTypes.instanceOf(Date),
      submit: PropTypes.func.isRequired
    }).isRequired,
    submitAfter: PropTypes.number.isRequired
  };

  static defaultProps = {
    submitAfter: 3000 // Time to wait from the last form change until the form is autosubmitted
  }

  componentDidUpdate() {
    const { formState } = this.props;
    const { isChanged, lastSubmitted } = formState;

    if (isChanged) {
      // Form is changed, (re)start the counter
      this.startCounter();
    } else {
      // Form is no longer changed, stop the counter
      this.stopCounter();
    }

    if (!isChanged && lastSubmitted) {
      this.startUpdater();
    } else {
      this.stopUpdater();
    }
  }

  startCounter = () => {
    const { submitAfter } = this.props;

    if (this[COUNTER]) {
      this.stopCounter();
    }

    this[COUNTER] = setTimeout(this.submit, submitAfter);
  };

  stopCounter = () => {
    clearTimeout(this[COUNTER]);
  };

  startUpdater = () => {
    if (this[UPDATER]) {
      this.stopUpdater();
    }

    this[UPDATER] = setTimeout(() => this.forceUpdate(), 30000);
  };

  stopUpdater = () => {
    clearTimeout(this[UPDATER]);
  };

  submit = () => {
    const { formState } = this.props;
    const { submit } = formState;

    submit();
  };

  render() {
    const { formState } = this.props;
    const { isChanged, isSubmitting, lastSubmitted } = formState;

    if (isSubmitting) {
      return (
        <div className="Autosave">
          Submitting…
        </div>
      );
    }

    if (isChanged) {
      return (
        <div className="Autosave">
          Changes will be saved automatically.
        </div>
      );
    }

    return (
      <div className="Autosave">
        Changes last saved • {lastSubmitted ? formatDistance(lastSubmitted, new Date(), { addSuffix: true }) : "Never"}
      </div>
    );
  }
}

export default props => (
  <Consumer>
    {formState => (
      <Autosave formState={formState} {...props} />
    )}
  </Consumer>
);
