import React from "react";
import memoize from "lodash.memoize";

const regExpCharactersToEscape = ["(", ")", "[", "]", "{", "}", "\\", "+", "?"];

const escapeRegExpCharacters = char => regExpCharactersToEscape.includes(char) ? `\\${char}` : char;

export const toFuzzyRegExp = memoize(str => (str && new RegExp(
  str.toLowerCase().split("")
    .map(escapeRegExpCharacters)
    .join(".{0,3}?"), "gi"
)));

/**
 * Highlights RegExp matches in the string.
 *
 * @param {String} text String to be highlighted.
 * @param {RegExp} pattern Pattern to match the words or phrases to highlight. Has to be global to highlight all matches.
 */
export const highlightPattern = (text, pattern) => {
  if (typeof text !== "string" || !pattern) {
    return text;
  }

  const splitText = text.split(pattern);

  if (splitText.length <= 1) {
    return text;
  }

  const matches = text.match(pattern);

  return splitText.reduce((arr, element, index) => (matches[index] ? [
    ...arr,
    element,
    <mark key={index}>
      {matches[index]}
    </mark>
  ] : [...arr, element]), []);
};
