import React from "react";
import PropTypes from "prop-types";

import "./product_header.less";

import Container from "../../../../stingray/frontend/components/ui/container";
import Placeholder from "../../placeholders/placeholder";
import ProviderLogo from "../ui/provider_logo";

import { isProduct } from "../../utility/prop-types";
import { getProductAliasOrName } from "../../utility/helpers";

export default function ProductHeader({
  isPlaceholder,
  product
}) {
  if (!product) {
    return null;
  }

  const headerContent = (
    <Container allowWrap>
      <div className="ProductHeader__controls">
        <h2>
          {getProductAliasOrName(product)}
        </h2>
      </div>
      <div className="ProductHeader__data_source">
        {product.data_source && (
          <span className="ProductHeader__data_source__name">
            {product.data_source.name}
          </span>
        )}
        {product && (
          <div className="ProductHeader__data_source__logo">
            <ProviderLogo product={product} />
          </div>
        )}
      </div>
    </Container>
  );

  if (isPlaceholder) {
    return (
      <div className="ProductHeader">
        <Placeholder theme="dark">
          {headerContent}
        </Placeholder>
      </div>
    );
  }

  return (
    <div className="ProductHeader">
      {headerContent}
    </div>
  );
}

ProductHeader.propTypes = {
  isPlaceholder: PropTypes.bool,
  product: isProduct
};
