import React, { useEffect } from "react";
import PropTypes from "prop-types";

import Head from "../head";
import Header from "../header/header";
import Footer from "../../containers/footer/footer";

import AttachedFiles from "../../containers/use_case/attached_files";
import StatusTag from "./status_tag";
import UseCaseSection from "./use_case_section";
import DataProducts from "../../containers/use_case/data_products";
import DataServiceJobs from "../../containers/use_case/data_service_jobs";
import TeamMembers from "../../containers/use_case/team_members";
import UseCaseTimeline from "../../containers/use_case/use_case_timeline";
import Section from "../cms/cms_section/cms_section";
import { isUseCase } from "../../utility/prop-types";
import { GAPageView } from "../../utility/google_analytics";

import "./use_case.less";

export default function UseCase({ fetchUseCase, useCase, useCaseId }) {
  useEffect(() => {
    if (useCaseId) {
      fetchUseCase(useCaseId);
    }
  }, [fetchUseCase, useCaseId]);

  useEffect(() => {
    useCase && GAPageView(useCase?.name);
  }, [useCase]);

  if (!useCase) {
    return null;
  }

  const {
    description,
    edaas_tags,
    name
  } = useCase;

  return (
    <div>
      <Header />
      <div className="UseCase">
        <Head title={name} />
        <Section className="UseCase__header">
          <div className="UseCase__header__content">
            <h1>{name}</h1>
            <div>
              {edaas_tags?.map(tag => (
                <StatusTag key={tag.name} title={tag.name} />
              ))}
            </div>
          </div>
        </Section>
        <div className="UseCase__body">
          <div className="UseCase__body__info">
            <div className="UseCase__body__info__content">
              <UseCaseSection title="Description">
                <div className="UseCase__description">{description}</div>
              </UseCaseSection>
              <DataServiceJobs />
              <AttachedFiles />
              <DataProducts />
              <TeamMembers />
            </div>
          </div>
          <UseCaseTimeline />
        </div>
      </div>
      <Footer />
    </div>
  );
}

UseCase.propTypes = {
  fetchUseCase: PropTypes.func.isRequired,
  useCase: isUseCase,
  useCaseId: PropTypes.number.isRequired
};
