import React from "react";

export default function CloseIcon({ color = "white" }) {
  return (
    <svg width="22" height="22" viewBox="0 0 22 22">
      <g stroke={color} strokeWidth="2">
        <line x1="3" y1="3" x2="19" y2="19" />
        <line x1="19" y1="3" x2="3" y2="19" />
      </g>
    </svg>
  );
}
