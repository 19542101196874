import React, { Component } from "react";
import PropTypes from "prop-types";
import Fit from "react-fit";
import classNames from "classnames";

import "./dropdown.less";

export default class Dropdown extends Component {
  static propTypes = {
    className: PropTypes.string,
    disableCloseOnInsideAction: PropTypes.bool,
    disabled: PropTypes.bool,
    fit: PropTypes.bool,
    footer: PropTypes.node,
    hideArrow: PropTypes.bool,
    invertAxis: PropTypes.bool,
    invertSecondaryAxis: PropTypes.bool,
    items: PropTypes.arrayOf(PropTypes.node),
    label: PropTypes.node,
    title: PropTypes.string
  };

  static defaultProps = {
    fit: true
  };

  state = {
    isOpen: null
  };

  componentWillUnmount() {
    document.removeEventListener("mousedown", this.onOutsideAction);
    document.removeEventListener("focusin", this.onOutsideAction);
  }

  onToggle = () => {
    const { isOpen } = this.state;

    if (isOpen) {
      document.addEventListener("mousedown", this.onOutsideAction);
      document.addEventListener("focusin", this.onOutsideAction);
    } else {
      document.removeEventListener("mousedown", this.onOutsideAction);
      document.removeEventListener("focusin", this.onOutsideAction);
    }
  }

  onOutsideAction = (event) => {
    if (this.wrapper && !this.wrapper.contains(event.target)) {
      this.closeDropdown();
    }
  }

  onInsideAction = (event) => {
    event.stopPropagation();

    const { disableCloseOnInsideAction } = this.props;

    if (disableCloseOnInsideAction) {
      return;
    }

    if (this.label && !this.label.contains(event.target) && this.label !== event.target) {
      setTimeout(this.closeDropdown, 0);
    }
  }

  closeDropdown = () => this.setState((prevState) => {
    if (!prevState.isOpen) {
      return null;
    }

    return { isOpen: false };
  }, this.onToggle);

  toggleDropdown = () => this.setState(prevState => ({ isOpen: !prevState.isOpen }), this.onToggle);

  renderContent() {
    const {
      fit,
      footer,
      invertAxis,
      invertSecondaryAxis,
      items
    } = this.props;
    const { isOpen } = this.state;

    if (!items || isOpen === null) {
      return null;
    }

    const content = (
      <div className="Dropdown__content">
        <ul className="Dropdown__items">
          {React.Children.map(items, (item, index) => (
            <li key={index}>{item}</li>
          ))}
        </ul>
        {footer && (
          <div className="Dropdown__footer">
            {footer}
          </div>
        )}
      </div>
    );

    if (!fit) {
      return content;
    }

    return (
      <Fit invertAxis={invertAxis} invertSecondaryAxis={invertSecondaryAxis}>
        {content}
      </Fit>
    );
  }

  render() {
    const {
      className, disabled, hideArrow, label, title
    } = this.props;
    const { isOpen } = this.state;

    return (
      /**
       * This onClick is okay, because we don't listen for clicking on a <div> (which is a wrapper),
       * but we catch bubbling onClick events coming from valid buttons and anchors.
       */
      // eslint-disable-next-line jsx-a11y/no-static-element-interactions
      <div
        className={classNames("Dropdown", isOpen ? "Dropdown--open" : "Dropdown--closed", className)}
        onClick={this.onInsideAction}
        ref={(ref) => {
          if (!ref) {
            return;
          }

          this.wrapper = ref;
        }}
        title={title}
      >
        <button
          type="button"
          className="Dropdown__label"
          disabled={disabled}
          onClick={this.toggleDropdown}
          ref={(ref) => {
            if (!ref) {
              return;
            }

            this.label = ref;
          }}
        >
          <span className="Dropdown__label__label">
            {label}
          </span>
          {!hideArrow && (
            <>
              <span>{" "}</span>
              <span className="Dropdown__label__arrow">
                {isOpen ? "▲" : "▼"}
              </span>
            </>
          )}
        </button>
        {this.renderContent()}
      </div>
    );
  }
}
