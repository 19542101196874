import makeSimpleReducer from "./_make_simple_reducer";

import {
  GET_DATA_CATEGORIES_REQUEST,
  GET_DATA_CATEGORIES_SUCCESS,
  GET_DATA_CATEGORIES_ERROR
} from "../actions/data_categories";

export default makeSimpleReducer({
  actions: [
    GET_DATA_CATEGORIES_REQUEST,
    GET_DATA_CATEGORIES_SUCCESS,
    GET_DATA_CATEGORIES_ERROR
  ]
});
