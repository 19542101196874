import React from "react";

// Components
import ProductCard from "../components/catalog/product_card";

// Helpers
import { generateProductPlaceholder } from "../utility/placeholders";

const product = generateProductPlaceholder();

export default () => (
  <ProductCard
    isPlaceholder
    product={product}
  />
);
