import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

// Actions
import { fetchPublicTypes } from "../../../actions/types";

// Selectors
import { getPublicTypes } from "../../../selectors/types";

// Components
import DataTypeFilter from "../../../components/attribute_catalog/filters/data_type_filter";

export default withRouter(connect(
  store => ({
    publicTypes: getPublicTypes(store)
  }),
  {
    fetchPublicTypes
  }
)(DataTypeFilter));
