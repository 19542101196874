// Helpers
export const generateRandomNumber = (min = 2, max = 10) => Math.floor(min + Math.random() * (1 + max - min));

export const generateWord = (length = generateRandomNumber()) => "█".repeat(length);

export const generateWords = (min = 5, max = 12) =>
  Array.from(new Array(generateRandomNumber(min, max))).map(() => generateWord());

export const generateSentence = (min = 5, max = 12) => generateWords(min, max).join(" ");

const svgToDataURI = svg => `data:image/svg+xml;charset=utf8,${encodeURIComponent(svg.trim()).replace(/\(/g, "%28").replace(/\)/g, "%29")}`;

export const generateProviderLogoPlaceholder = () => svgToDataURI(`
  <svg xmlns="http://www.w3.org/2000/svg" width="400" height="400" viewBox="0 0 400 400">
    <rect x="0" y="0" width="400" height="400" fill="#fff" />
  </svg>
`);

// Actual placeholders
export const generateProductPlaceholder = () => ({
  created_at: generateWord(8),
  data_category: {
    name: generateSentence(1, 3)
  },
  data_regions: [{
    name: generateWord()
  }],
  data_source: {
    name: generateWord()
  },
  description: generateSentence(),
  error_rate: generateWord(2),
  id: -1,
  logo: generateProviderLogoPlaceholder(),
  match_rate: generateWord(2),
  name: generateSentence(2, 3),
  tags: Array.from(new Set(generateWords(7, 12)))
});

export const generateAttributePlaceholder = () => ({
  description: generateSentence(1, 3),
  id: generateRandomNumber(),
  fcra: true,
  field_is_populated_rate: Math.random(),
  fill_rate: Math.random(),
  match_rate: Math.random(),
  name: generateWord(),
  num_distinct_values: generateRandomNumber(),
  table_provider: {
    data_source_name: generateSentence(2, 3),
    display_cost: "$$",
    logo: generateProviderLogoPlaceholder(),
    name: generateSentence(2, 3)
  },
  type: generateWord()
});

export const generateDataProductPlaceholder = () => ({
  id: generateRandomNumber(),
  name: generateSentence(1, 2),
  data_source_name: generateWord(6),
  logo: generateProviderLogoPlaceholder()
});

export const generateTeamMemberPlaceholder = () => ({
  name: generateSentence(2, 3)
});

export const generateTimelineEventPlaceholder = () => ({
  date: generateWord(10),
  time: generateWord(10),
  title: generateSentence(2, 3),
  subtitle: generateWord(6),
  children: generateSentence(3, 7)
});

export const generateAttachedFilePlaceholder = () => ({
  file: {
    id: generateRandomNumber(),
    name: generateSentence(1, 2),
    created_at: generateWord(10)
  },
  type: generateWord(6)
});

export const generateDataServiceJobPlaceholder = () => ({
  job: {
    id: generateWord(2),
    created_at: generateWord(10),
    name: generateWord(6)
  }
});
