import React from "react";
import PropTypes from "prop-types";
import Placeholder from "../../placeholders/placeholder";
import classNames from "merge-class-names";

import "./timeline_event.less";

export default function TimelineEvent({
  children,
  className,
  date,
  id,
  isPlaceholder,
  showAsChat,
  subtitle,
  time,
  title
}) {
  return (
    <Placeholder isEnabled={isPlaceholder} theme="dark">
      <li id={id} className={classNames("TimelineEvent", className)}>
        <div className="TimelineEvent__icon" />
        <div className="TimelineEvent__date">
          <div className="TimelineEvent__date__main">{date}</div>
          <div className="TimelineEvent__date__small">{time}</div>
        </div>
        <div className={classNames("TimelineEvent__body", showAsChat && "TimelineEvent__body--chat")}>
          {title && <h4 className="TimelineEvent__title">{title}</h4>}
          {subtitle && (
            <div className="TimelineEvent__subtitle">{subtitle}</div>
          )}
          <div className="TimelineEvent__content">{children}</div>
        </div>
      </li>
    </Placeholder>
  );
}

TimelineEvent.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  date: PropTypes.string.isRequired,
  id: PropTypes.string,
  isPlaceholder: PropTypes.bool,
  showAsChat: PropTypes.bool,
  subtitle: PropTypes.string,
  time: PropTypes.string.isRequired,
  title: PropTypes.string
};
