import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

// Actions

// Selectors

// Components
import AttributeCard from "../../components/attribute_catalog/attribute_card";

export default withRouter(connect(
  (store, ownProps) => {
    const { id: urlId } = ownProps.match.params;

    if (!urlId) {
      return {};
    }

    const id = urlId.split("-").pop();
    const activeAttributeId = parseInt(id, 10);

    return {
      activeAttributeId
    };
  }
)(AttributeCard));
