import React from "react";
import PropTypes from "prop-types";
import { parse } from "query-string";

import MultiselectDropdown from "../../../../../stingray/frontend/components/ui/multiselect_dropdown";

import { addToQuery } from "../../../../../stingray/frontend/utility/url";
import { isHistory, isLocation } from "../../../utility/prop-types";

const getLocation = (location, urlKey, value) => {
  const {
    [urlKey]: previousValues = []
  } = parse(location.search, { arrayFormat: "bracket" });

  const values = [...previousValues];
  if (previousValues.includes(value)) {
    values.splice(previousValues.indexOf(value), 1);
  } else {
    values.push(value);
  }

  const newQuery = addToQuery(location.search, urlKey, values);

  return {
    ...location,
    search: newQuery
  };
};

const replaceFilters = (history, location, urlKey, value) => {
  history.replace(getLocation(location, urlKey, value));
};

export default function MultiselectFilter({
  history,
  items,
  label,
  location,
  name,
  urlKey
}) {
  const {
    [urlKey]: values = []
  } = parse(location.search, { arrayFormat: "bracket" });

  return (
    <MultiselectDropdown
      disabled={!items}
      items={items && items.map(({ name: itemName, value: itemValue }) => {
        const itemVal = itemValue || itemName;
        return {
          checked: values.includes(itemVal),
          label: itemName,
          value: itemVal,
          onItemChecked: (event) => {
            const { value } = event.target;
            replaceFilters(history, location, urlKey, value.toString());
          }
        };
      })}
      label={label}
      name={name}
      value={values}
    />
  );
}

MultiselectFilter.propTypes = {
  history: isHistory.isRequired,
  items: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string
  })),
  label: PropTypes.string.isRequired,
  location: isLocation.isRequired,
  name: PropTypes.string.isRequired,
  urlKey: PropTypes.string.isRequired
};
