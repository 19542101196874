import ReactGA from "react-ga";

export const buttonClickGAAction = (action) => `button click - ${action}`;

export const GAPageView = (page_title, location_override) =>
  ReactGA.pageview(
    location_override !== undefined
      ? location_override
      : window.location.pathname + window.location.search,
    [], // weird formatting due to react-ga asking for an array in 2nd param.
    page_title
  );

export const GA_CATEGORY = {
  LEAD_FLOW: "lead flow",
  OUTBOUND: "outbound",
  ERROR: "error",
  EDAAS: "EDaaS In-Page",
};

export const GA_LABEL_TITLE = {
  REQUEST_DATA: "request data",
  SIGN_UP: "sign up",
  REQUEST_CONSULTATION: "request consultation",
};

export const eventConfig = ({
  action = "GA default action",
  category = GA_CATEGORY.LEAD_FLOW,
  labelInfo = "",
  labelTitle = "GA event",
}) => ({
  action,
  category,
  label: `${labelTitle} ${labelInfo}`.trim(),
});

export const GAEvent = (config) => ReactGA.event(eventConfig(config));

export default ReactGA;
